const initialstate = false;
const readyAppReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "app/READY":
            return true;

        default:
            return state
    }
}

export default readyAppReducer;