const initialstate = []



const activeTicketsReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "activeTickets/PUT":
            return [...state, action.payload];

        case "activeTickets/DELETE":
            const newState = [...state].filter(ticket => ticket.id !== action.payload.id )
           // console.log("action.payload activeTickets/DELETE ", action.payload)
            return newState;

        default:
            return state
    }
}

export default activeTicketsReducer;