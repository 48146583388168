import React, { Component } from 'react';
import { connect } from "react-redux"
import firebase from '../../../config/fbConfig';
import Spinner from '../../utils/loader/Spinner';
import BannerSavedMessage from './BannerSavedMessage';
import Input from '../../utils/inputs/Input';
const shortid = require('shortid');

const bannersRef = firebase.database().ref("banners");
class AddBanner extends Component {

    state = {
        banners: [],
        bannerImg: "",
        bannerHref: "",
        isLoading: true,
        areBannersSavedMessage: false
    }

    componentDidMount() {
        this.fetchBanners()
        document.getElementById("top-navbar").scrollIntoView();
      }

    fetchBanners = () => {
        this.setState({
            banners: this.props.banners,
            isLoading: false
        })
    }

    changeBannersInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    addBannerToArray = () => {
        const { bannerImg, bannerHref } = this.state;
        const banners = [...this.state.banners];
        const suid = shortid.generate();
        banners.push({
            key: suid,
            bannerImg,
            bannerHref
        });
        this.setState({
            banners,
            bannerImg: "",
            bannerHref: ""
        })
    }

    removeBanner = (key) => () => {
        let banners = [...this.state.banners];
        banners = banners.filter(item => item.key !== key);
        this.setState({ banners });
    }

    moveDown = ({ key, arrIndex }) => () => {
        let banners = [...this.state.banners];
        const TempElement = banners[arrIndex + 1];
        banners[arrIndex + 1] = banners[arrIndex];
        banners[arrIndex] = TempElement;
        this.setState({ banners })
    }
    moveUp = ({ key, arrIndex }) => () => {
        let banners = [...this.state.banners];
        const TempElement = banners[arrIndex - 1];
        banners[arrIndex - 1] = banners[arrIndex];
        banners[arrIndex] = TempElement;
        this.setState({ banners })
    }

    listBanners = () => {
        if (!this.state.banners.length) return <div> There are no banners </div>
        const lastElementIndex = this.state.banners.length - 1;
        return this.state.banners.map(
            (singleBanner, arrIndex) => {
                const { bannerHref, bannerImg, key } = singleBanner;

                return (
                    <div key={key} className="card mb-3 c-whitish"
                    >
                        <div className="row no-gutters">
                            <div className="col-sm-4">
                                <div> <img className="banner-img" src={bannerImg} alt="banner" /> </div>
                            </div>

                            <div className="col-sm-8">
                                <div className="card-body">
                                    <div
                                        className="card-text text-left download-url"
                                    > {bannerHref}
                                    </div>



                                    {
                                        // if it is not the last element and there are more than 2 elements
                                        (arrIndex !== lastElementIndex && lastElementIndex > 1)
                                            ? <button className="move-down-btn" onClick={this.moveDown({ key, arrIndex })}>{String.fromCharCode(8681)} </button>
                                            : null
                                    }
                                    <button className="clear-banner-btn" onClick={this.removeBanner(key)}>{String.fromCharCode(10008)}   </button>
                                    {
                                        (arrIndex !== 0 && lastElementIndex > 1)
                                            ? <button className="move-up-btn" onClick={this.moveUp({ key, arrIndex })}>{String.fromCharCode(8679)} </button>
                                            : null
                                    }



                                </div>
                            </div>

                        </div>
                    </div>
                )
            }

        );
    }
    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.addBannerToArray();
    }

    confirmBannersAreSaved = () => {
        this.setState({
            areBannersSavedMessage: false
        })
    }

    saveBannersToDB = () => {
        this.setState({ isLoading: true });
        bannersRef.set({
            banners: this.state.banners
        })
            .then(() => {
                this.setState({
                    isLoading: false,
                    areBannersSavedMessage: true
                });

            })
            .catch((err) => {
                this.setState({ isLoading: false })
            })
    }

    render() {

        const {
            bannerHref,
            bannerImg,
            isLoading,
            areBannersSavedMessage
        } = this.state;

        if (areBannersSavedMessage)
            return <BannerSavedMessage
                confirmBannersAreSaved={this.confirmBannersAreSaved}
            />;

        if (isLoading)
            return <Spinner />;

        return (
            <div id="add-banner">
                <h1 className="my-1">  Add banner </h1>
                <Input
                    type="text"
                    typeToggle={false}
                    placeholder="Enter image source link"
                    label="Enter image source link"
                    name="bannerImg"
                    value={bannerImg}
                    onChange={this.changeBannersInput}
                    onKeyPress={this.handleKeyPress}
                />


                <Input
                    type="text"
                    typeToggle={false}
                    placeholder="Enter image href link"
                    label="Enter image href link"
                    name="bannerHref"
                    value={bannerHref}
                    onChange={this.changeBannersInput}
                    onKeyPress={this.handleKeyPress}
                />



                <button
                    className="success-button"
                    onClick={this.addBannerToArray}
                > Add Banner
                </button>



                <button
                    className="success-button"
                    onClick={this.saveBannersToDB}
                > Save Banners
                </button>

                {this.listBanners()}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue,
        banners: state.banners
    }
}

export default connect(mapStateToProps, null
)(AddBanner);