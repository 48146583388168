import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import AddAnalysisNavbar from './AddAnalysisNavbar';
import AddAnalysisValues from './AddAnalysisValues';
import JoditApp from '../../utils/editor/JoditApp';
import { sports } from '../../../config/sports';
import { bettingHouses } from '../../../config/bettingHouses';
import {
    submitAnalysis,
    fetchAnalysisValues,
    fetchAnalysisWithContent
} from './UpdateAnalysisModules';
import { changeRouteValue } from '../../../store/actions/viewData';
import { toggleLoader } from '../../../store/actions/toggle';
import { changeLanguageAction } from '../../../store/actions/translation';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import {
    analysesRemove
} from '../../../store/actions/analyses';

import firebase from '../../../config/fbConfig';
import {
    markAnalysis
} from './MarkAnalysisModules';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
import Comments from '../../utils/comments/Comments';
import MappedSingleAnalyses from './MappedSingleAnalyses';
import PagesHelmet from '../../utils/helmet/PagesHelmet';
import { htmlArray } from '../../../config/metaConfig';

class UpdateAnalysis extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            content: '',
            canWrite: false,
            title: "",
            language: this.props.languageTranslation,
            imageUrl: "not",
            sport: sports[0],
            startDate: new Date(),
            hostTeam: "",
            guestTeam: "",
            tip: "",
            odds: "",
            stake: "",
            bettingHouse: bettingHouses[0],
            authorId: "",
            authorName: "",
            photoURL: "not",
            name: "", //authorName, but from diffrent place fetched
            authorsGeneralProfit: 0,
            authorsGeneralYield: 0,
            wdl: "",
            languages: [],
            analysisExists: false,
            isOpenConfirmSubmitModal: false,
            setAt: ""
        }

        this.submitAnalysis = submitAnalysis.bind(this);
        this.fetchAnalysisValues = fetchAnalysisValues.bind(this);
        this.fetchAnalysisWithContent = fetchAnalysisWithContent.bind(this);
        this.markAnalysis = markAnalysis.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        document.getElementById("top-navbar").scrollIntoView();
        this.fetchAnalysisValues()
            .then((authorId) => {
                firebase.database()
                    .ref('profiles/' + authorId).once("value",
                        (snapshot) => {
                            if (!snapshot.hasChildren()) {
                                this._isMounted && this.setState({
                                    photoURL: "empty"
                                });
                            } else {

                                const { n = "", p = "empty" } = snapshot.val();
                                this._isMounted && this.setState({
                                    name: n,
                                    photoURL: p,
                                });
                            }
                        }, (err) => {
                            this._isMounted && this.setState({
                                photoURL: "empty"
                            });
                        }
                    );
                firebase.database()
                    .ref('tipster-stats-all/' + authorId).once("value",
                        (snapshot) => {
                            const profit = (snapshot.val() && snapshot.val().p) || 0;
                            const stake = (snapshot.val() && snapshot.val().s) || 1;
                            const yieldValue = (Math.round(profit * 10000 / stake) / 100);
                            this._isMounted && this.setState({
                                authorsGeneralProfit: Math.round(profit * 100) / 100,
                                authorsGeneralYield: yieldValue
                            });
                        }
                    )
            });
    }

    componentWillUnmount() {

        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("prevProps",prevProps, "prevState", prevState.language, "lstate",this.state.language)
        if (prevState.language !== this.state.language && this.state.languages) {
            const langAvailable = this.state.languages.find((lang) => lang === this.state.language);

            if (langAvailable)
                this.fetchAnalysisWithContent(langAvailable, this.state.authorId);
        }

        if (prevProps.languageTranslation !== this.props.languageTranslation
            && this.props.languageTranslation !== this.state.language
        ) {
            this._isMounted && this.setState({
                language: this.props.languageTranslation
            })
        }
    }

    openNotes = () => {

        this._isMounted && this.setState({ isOpenConfirmSubmitModal: true });
    }
    closeNotes = () => {
        this._isMounted && this.setState({ isOpenConfirmSubmitModal: false });
    }

    updateContent = (value) => {
        this._isMounted && this.setState({ content: value })
    }

    handleDatePickerChange = startDate => {
        this._isMounted && this.setState({
            startDate
        });
    };

    handleMetaChange = (e) => {

        //validate stake
        if (e.target.name === "stake" &&
            (!e.target.value.match(/^([1-9]|10)$/)
                && e.target.value !== ""
            )
        ) return;

        // set state to all stat values
        this._isMounted && this.setState({ [e.target.name]: e.target.value });

        if (e.target.name === "language")
            this.props.changeLanguageAction(e.target.value);
    }

    handleStakeBtnChange = (opChange) => {
        if (opChange === "up") {

            if (this.state.stake === "") return this._isMounted && this.setState({ stake: 1 })
            if (this.state.stake < 10)
                this._isMounted && this.setState((prevState) => {
                    return {
                        stake: parseInt(prevState.stake, 10) + 1
                    }
                })
        } else {
            if (this.state.stake === 1 || this.state.stake === "1")
                return this.setState({ stake: "" });
            if (this.state.stake === "") return;
            this._isMounted && this.setState((prevState) => {
                return {
                    stake: parseInt(prevState.stake, 10) - 1
                }
            })
        }
    }

    handleClickCanWrite = () => {
        this._isMounted && this.setState(
            (prevState) => {
                return { canWrite: !prevState.canWrite }
            })
    }

    render() {
        const {
            content,
            canWrite,
            title,
            language,
            imageUrl,
            sport,
            startDate = "",
            hostTeam,
            guestTeam,
            tip,
            odds,
            stake,
            bettingHouse, // array to import from db 
            authorId,
            authorName,
            analysisExists
        } = this.state;
        const { tl = {} } = this.props;

        if (!analysisExists) return (<div></div>);
        const appMetaData = htmlArray.find(elem => elem.fileName === "analysis.html");
        return (
            <div id="update-analysis">
                <PagesHelmet
                    descriptionMeta={appMetaData.description}
                    clientURLMeta={appMetaData.urlMeta}
                    titleMeta={appMetaData.title}
                    imageMeta={appMetaData.image}
                />
                <ConfirmNoteOutput
                    notes={{
                        title: tl.update_analysis_q,
                        body: tl.sure_to_update_analysis_q,
                        closeNotesText: tl.cancel_t,
                        confirmNotesText: tl.yes_t
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.submitAnalysis}
                    isOpen={this.state.isOpenConfirmSubmitModal}
                />

                <MappedSingleAnalyses {...this.props} {...this.state} />

                {
                    (canWrite) ?
                        <Fragment>
                            <h1 className="my-5">{tl.update_analysis_t}</h1>
                            <AddAnalysisValues
                                title={title}
                                language={language}
                                imageUrl={imageUrl}
                                sport={sport}
                                startDate={startDate}
                                hostTeam={hostTeam}
                                guestTeam={guestTeam}
                                tip={tip}
                                odds={odds}
                                stake={stake}
                                bettingHouse={bettingHouse} // array to import from db 
                                authorId={authorId}
                                authorName={authorName}
                                handleMetaChange={this.handleMetaChange}
                                handleDatePickerChange={this.handleDatePickerChange}
                                handleStakeBtnChange={this.handleStakeBtnChange}
                            />
                            <JoditApp
                                canWrite={canWrite}
                                content={content}
                                updateContent={this.updateContent}
                            />
                        </Fragment>
                        : null
                }

                <AddAnalysisNavbar
                    markAnalysis={this.markAnalysis}
                    handleClickCanWrite={this.handleClickCanWrite}
                    submitAnalysis={this.submitAnalysis}
                    path={this.props.match.path}
                    canWrite={canWrite}
                    authorId={authorId}
                />
                <div className="m-3"></div>
                <Comments
                    displayName={this.props.displayName}
                    commentsRefId={this.props.match.params.id}
                    authorId={this.props.uid} // the one who comments
                    canWrite={canWrite}
                    feedAuthorId={authorId} // the one who wrote blog or analysis
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        displayName: state.user.displayName,
        loader: state.toggle.loader,
        languageTranslation: state.toggle.languageTranslation || "en",
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload,
    toggleLoader,
    changeLanguageAction,
    changeRouteValue,
    analysesRemove
})(UpdateAnalysis);