import React, { Component } from 'react';
import { connect } from "react-redux";
import { firestoreDB } from '../../../config/fbConfig';
import ListedSingleImage from './ListedSingleImage';
//const firestoreDB = firebase.firestore();

class ListGalleryImages extends Component {

    state = {
        images: [],
        skipLimit: 4,
        startAfter: '',
        isLoadingImages: false,
        emptyAjax: false,
    }

    loadImages = async () => {
        const { skipLimit, startAfter, isLoadingImages } = this.state;
        try {
            if (isLoadingImages) return;
            const { uid } = this.props;
            this.setState({
                isLoadingImages: true
            })
            const imagesDoc = await firestoreDB
                .collection('galeries')
                .doc(uid)
                .collection('images')
                .orderBy('setAt', 'desc')
                .startAfter(startAfter)
                .limit(skipLimit)
                .get();

            console.log("imagesDoc", imagesDoc)

            let lastFetchedTime = startAfter;
            const images = [...this.state.images]
            if (imagesDoc.size < skipLimit)
                this.setState({
                    emptyAjax: true
                })
            imagesDoc.forEach((doc) => {
                console.log("doc", doc, doc.data())
                lastFetchedTime = doc.data().setAt;
                images.push({ ...doc.data(), id: doc.id })
            });

            this.setState({ images, startAfter: lastFetchedTime });


            this.setState({
                isLoadingImages: false
            })

        } catch (error) {
            console.error("Error getting document: ", error, error.message);
        }

    }

    mapImages = () => {
        return this.state.images.map((singleImage) =>
            <ListedSingleImage
                key={singleImage.id}
                singleImage={singleImage}
                uid={this.props.uid}
            />
        )
    }

    render() {
        const { emptyAjax } = this.state;
        return (
            <div>

                {this.mapImages()}

                {
                    (emptyAjax)
                        ? <div className="c-whitish"> No more images </div>
                        : <button
                            className="success-button"
                            onClick={this.loadImages}
                        > Load More </button>
                }

            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
    }
}

export default connect(mapStateToProps, null)(ListGalleryImages);