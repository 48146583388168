import React, { Component } from 'react';
import { connect } from "react-redux"
import { categories } from '../../../config/categories';
import Input from '../../utils/inputs/Input';
import TextArea from '../../utils/inputs/TextArea';

class AddMetaBlogValues extends Component {

    state = {
        categories: categories
    }

    mapCateogries = () => {
        return this.state.categories.map(elem =>
            <option
                key={elem}
                value={elem}
                defaultValue={elem === categories[0]}
            >{elem}</option>
        )
    }

    render() {
        const { title = "",
            description = "",
            imageUrl = "",
            language = "en",
            category = categories[0],
            tl = {}
        } = this.props;

        return (
            <div id="add-meta-blogs-values">

                <div className="form-group mt-4">
                    <Input
                        type="text"
                        typeToggle={false}
                        placeholder={tl.enter_title}
                        id="inputBlogTitle"
                        label={tl.enter_title}
                        name="title"
                        value={title}
                        onChange={this.props.handleMetaChange}
                        required={true}
                        className="custom-input-sm"
                    />
                </div>


                <div className="form-group">
                    <TextArea
                        type="text"
                        typeToggle={false}
                        placeholder={tl.enter_description_t}
                        id="inputBlogDescription"
                        label={tl.enter_description_t}
                        name="description"
                        value={description}
                        onChange={this.props.handleMetaChange}
                        required={true}
                        className="custom-input-sm"
                    />
                </div>
                <div className="form-group">
                    <Input
                        type="text"
                        typeToggle={false}
                        placeholder={tl.enter_img_url}
                        id="inputAddress2"
                        label={tl.enter_img_url}
                        name="imageUrl"
                        value={imageUrl}
                        onChange={this.props.handleMetaChange}
                        required={true}
                        className="custom-input-sm"
                    />
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <label
                            className="c-label-dropdown"
                            htmlFor="inputLanguage"
                        >{tl.language_t}</label>
                        <select
                            id="inputLanguage"
                            className="select-custom-form"
                            name="language"
                            value={language}
                            onChange={this.props.handleMetaChange}
                        >
                            <option defaultValue value={"en"} >English</option>
                            <option value={"sr"}>Srpski</option>
                            <option value={"fr"}>France</option>
                        </select>
                    </div>
                    <div className="form-group col-sm-6">
                        <label
                            className="c-label-dropdown"
                            htmlFor="inputCategory"
                        >{tl.category_t}</label>
                        <select
                            id="inputCategory"
                            className="select-custom-form"
                            name="category"
                            value={category}
                            onChange={this.props.handleMetaChange}
                        >
                            {this.mapCateogries()}
                        </select>
                    </div>
                </div>


            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, null)(AddMetaBlogValues);