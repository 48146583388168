export const toggleLeftSideBar = () => {
    return { type: "toggle/TOGGLE_RIGHT_SIDEBAR" }
}

export const closeLeftSideBar = () => {
    return { type: "toggle/CLOSE_RIGHT_SIDEBAR" }
}

export const openLeftSideBar = () => {
    return { type: "toggle/OPEN_RIGHT_SIDEBAR" }
}

export const toggleLoader = (payload) => {
    return {
        type: "toggle/LOADER",
        payload
    }
}

export const toggleActiveAnalysisAreFetched = (payload) => {
    return {
        type: "toggle/ACTIVE_ANALYSYS_FETCHED",
        payload
    }
}


export const toggleIsActiveAnalysisTab = (payload) => {
    return {
        type: "toggle/IS_ACTIVE_ANALYSYS_TAB",
        payload
    }
}

