import React, { Component } from 'react';

import ListAdminComments from './ListAdminComments';

class AdminNotifications extends Component {

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    render() {
        return (

            <div className="admin-comments" >
                <ListAdminComments
                />
            </div>

        )
    }

}

export default AdminNotifications;
