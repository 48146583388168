import React, { Component } from 'react';
import { connect } from "react-redux";
import AddAnalysisNavbar from './AddAnalysisNavbar';
import AddAnalysisValues from './AddAnalysisValues';
import JoditApp from '../../utils/editor/JoditApp';
import { firebaseAuth } from "../../../config/fbConfig";
import { sports } from '../../../config/sports';
import { bettingHouses } from '../../../config/bettingHouses';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { changeRouteValue } from '../../../store/actions/viewData';
import axios from 'axios';
import moment from 'moment';
import { isNumeric } from '../../utils/validations/validateNumbers';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';

class AddAnalysis extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            canWrite: true,
            title: "",
            language: "en",
            imageUrl: "",
            sport: sports[0],
            startDate: new Date(),
            hostTeam: "",
            guestTeam: "",
            tip: "",
            odds: "",
            stake: "",
            bettingHouse: bettingHouses[0], // array to import from db 
            isOpenSubmit: false, // open & close modal
        }
    }

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    updateContent = (value) => {
        this.setState({ content: value })
    }

    handleMetaChange = (e) => {
        //validate stake
        if (e.target.name === "stake" &&
            (!e.target.value.match(/^([1-9]|10)$/)
                && e.target.value !== ""
            )
        ) return;

        // set state to all stat values
        this.setState({ [e.target.name]: e.target.value })
    }

    handleStakeBtnChange = (opChange) => {
        if (opChange === "up") {

            if (this.state.stake === "") return this.setState({ stake: 1 })
            if (this.state.stake < 10)
                this.setState((prevState) => {
                    return {
                        stake: parseInt(prevState.stake, 10) + 1
                    }
                })
        } else {
            if (this.state.stake === 1 || this.state.stake === "1")
                return this.setState({ stake: "" });
            if (this.state.stake === "") return;
            this.setState((prevState) => {
                return {
                    stake: parseInt(prevState.stake, 10) - 1
                }
            })
        }
    }

    handleDatePickerChange = startDate => {
        this.setState({
            startDate
        });
    };

    handleClickCanWrite = () => {
        this.setState(
            (prevState) => {
                return { canWrite: !prevState.canWrite }
            })
    }


    openNotes = () => {
        this.setState({ isOpenSubmit: true });
    }
    closeNotes = () => {
        this.setState({ isOpenSubmit: false });
    }

    validateBeforeSubmit = () => {

        const { tl = {} } = this.props;
        const {
            content,
            title,
            imageUrl,
            language,
            sport,
            startDate,
            hostTeam,
            guestTeam,
            tip,
            odds,
            stake,
            bettingHouse // array to import from db 
        } = this.state;

        if (
            !content
            || !title
            || !imageUrl
            || !language
            || !sport
            || !hostTeam
            || !guestTeam
            || !startDate
            || !tip
            || !odds
            || !stake
            || !bettingHouse
        ) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.you_must_fill_to_submit_analysis
            })
            this.props.toggleLoader(false)
            return false;
        }


        if (!isNumeric(odds)) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.odds_number_that_contains_digit_dot
            })
            this.props.toggleLoader(false)
            return false;
        }


        if (content.length < 200 || content.length > 2000) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.content_between_min_max_chars
            })
            this.props.toggleLoader(false)
            return false;
        }


        if (imageUrl.length > 250) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.img_url_must_be_min_max_chars
            })
            this.props.toggleLoader(false)
            return false;
        }

        //
        const timeDBstamp = Date.now();
        const startDateStamp = moment(startDate).valueOf();
        // console.log("startDate vs momeny startdate ", startDate, moment(startDate).valueOf())

        if (startDateStamp - timeDBstamp < 1200000 || startDateStamp - timeDBstamp > 1000 * 3600 * 24 * 60) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved + " " + tl.invalid_time_in_match,
                body: tl.not_able_save_analysis_days_later_or_minutes
            })
            this.props.toggleLoader(false)
            return false;
        }
        this.openNotes()

    }

    submitAnalysis = async () => {
        const { tl = {} } = this.props;
        try {
            this.props.toggleLoader(true);
            const { displayName } = this.props;
            const {
                content,
                title,
                imageUrl,
                language,
                sport,
                startDate,
                hostTeam,
                guestTeam,
                tip,
                odds,
                stake,
                bettingHouse // array to import from db 
            } = this.state;

            const idToken = await firebaseAuth.currentUser.getIdToken(true)

            const res = await axios.post('analysis/create-analysis', {
                idToken,
                content,
                title,
                imageUrl,
                language,
                sport,
                hostTeam,
                guestTeam,
                startDate,
                tip,
                odds,
                stake,
                bettingHouse,
                displayName
            })
            const { analysisRefId } = res.data;

            this.props.toggleLoader(false)
            this.props.history.push("/analysis/" + analysisRefId);
            this.props.changeRouteValue("/analysis/" + analysisRefId);
            this.props.addSuccessNotesWithPayload({
                title: tl.success_t,
                body: tl.analysis_saved
            })
        } catch (error) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.ops_something_went_wrong + tl.analysis_not_saved + "."
            })
            this.props.toggleLoader(false)
        }
    }

    render() {
        const {
            content,
            canWrite,
            title,
            language,
            imageUrl,
            sport,
            startDate,
            hostTeam,
            guestTeam,
            tip,
            odds,
            stake,
            bettingHouse, // array to import from db 
            authorId
        } = this.state;

        const { tl = {} } = this.props;
        return (
            <div id="add-analysis">
                <h1> {tl.add_new_analysis} </h1>
                <ConfirmNoteOutput
                    notes={{
                        title: tl.save_analysis_q,
                        body: tl.sure_save_publish_analysis_q,
                        closeNotesText: tl.cancel_t,
                        confirmNotesText: tl.yes_t
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.submitAnalysis}
                    isOpen={this.state.isOpenSubmit}
                />
                <AddAnalysisValues
                    title={title}
                    language={language}
                    imageUrl={imageUrl}
                    sport={sport}
                    startDate={startDate}
                    hostTeam={hostTeam}
                    guestTeam={guestTeam}
                    tip={tip}
                    odds={odds}
                    stake={stake}
                    bettingHouse={bettingHouse} // array to import from db 
                    handleMetaChange={this.handleMetaChange}
                    handleDatePickerChange={this.handleDatePickerChange}
                    handleStakeBtnChange={this.handleStakeBtnChange}
                />
                <JoditApp
                    canWrite={canWrite}
                    content={content}
                    updateContent={this.updateContent}
                />
                <AddAnalysisNavbar
                    handleClickCanWrite={this.handleClickCanWrite}
                    submitAnalysis={this.validateBeforeSubmit}
                    path={this.props.match.path}
                    canWrite={this.state.canWrite}
                    authorId={authorId}
                />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        displayName: state.user.displayName,
        loader: state.toggle.loader,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, {
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload,
    changeRouteValue
})(AddAnalysis);