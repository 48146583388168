import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
import { closeLeftSideBar } from '../../../store/actions/toggle';

class LeftSidebarRegularItems extends Component {

    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload);
        this.props.closeLeftSideBar();
    }


    render() {
        const { tl = {} } = this.props;
        return (
            <Fragment>
                <br />
                <div
                    className="btn c-whitish"
                    onClick={this.addNewRoute('/')}
                >{tl.home_t}</div>

                <br />
                <div
                    className="btn c-whitish"
                    onClick={this.addNewRoute('/tickets')}
                >{tl.tickets_t}</div>
                <br />

                <div
                    className="btn c-whitish"
                    onClick={this.addNewRoute('/analyses')}
                >{tl.analysis_t}</div>

                <br />
                <div
                    className="btn c-whitish"
                    onClick={this.addNewRoute('/about')}
                >{tl.about_t}</div>

                <br />
                <div
                    className="btn c-whitish"
                    onClick={this.addNewRoute('/blogs')}
                >{tl.blogs_t}</div>

                <br />
                <div
                    className="btn c-whitish"
                    onClick={this.addNewRoute('/contact')}
                >{tl.contact_t}</div>


            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

const LeftSidebarRIWithRouter = withRouter(LeftSidebarRegularItems);

export default connect(mapStateToProps, {
    changeRouteValue,
    closeLeftSideBar
})(LeftSidebarRIWithRouter);
