import React, { Component } from 'react';
import { connect } from "react-redux"
import Img from 'react-image';
import userAvatar from '../../../assets/user-avatar.png'
class ProfileInfo extends Component {

    state = {
        errorMessage: ''
    };

    render() {
        const {
            email,
            displayName,
            tipsterLevel = false,
            adminLevel = false,
            emailVerified,
            photoURL = "note"
        } = this.props.user;
        return (
            <div className="p-1" >

                <div className="text-center d-flex justify-content-start">
                    {(photoURL === "not")
                        ? <div className="profile-avatar"> </div>
                        : <Img
                            className="profile-avatar"
                            src={[photoURL, userAvatar]}
                            decode={false}
                        />
                    }

                    <div className="analise-data-wrapper c-whitish text-center">
                        <span>
                            {email}
                        </span>
                        <span>
                            {displayName}
                        </span>
                    </div>
                </div>



   

                {(emailVerified)
                    ? <p style={{ color: "#36af47" }}>User is verified </p>
                    : <p style={{ color: "#ed1c24" }}>User is not verified</p>
                }
                {(tipsterLevel) ? <p style={{ color: "#fafafa" }}> Status: Tipster</p> : null}
                {(adminLevel) ? <p style={{ color: "#fafafa" }}>Status: Admin</p> : null}
            </div>

        )
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}


export default connect(mapStateToProps, null)(ProfileInfo);

