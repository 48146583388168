import React, { Component } from 'react';
import { changeLanguageAction } from '../../../store/actions/translation';
import { connect } from "react-redux"
import onClickOutside from "react-onclickoutside";
import { countyFlags } from '../../../config/languages';
import Img from 'react-image';
class TopNavbarSelectLanguage extends Component {

    state = {
        isExpanded: false
    }

    handleClickOutside = evt => {
        this.setState({ isExpanded: false })
    };

    handleLangugageChange = (e) => {
        localStorage.setItem("language", e);
        this.props.changeLanguageAction(e);
        this.setState({ isExpanded: false })
    }
    openDropDown = () => {
        this.setState((prevState) => {
            return {
                isExpanded: !prevState.isExpanded
            }
        })
    }

    render() {
        const isExpanded = (this.state.isExpanded) ? "NavbarDropdown" : "NavbarDropdownHidden";
        const { languageTranslation } = this.props;
        let langInitials = languageTranslation.toUpperCase();

        let newFlag = countyFlags[languageTranslation];

        return (
            <div className="dropdownWrapper">

                <div
                    className="link-button dropdownButton"
                    onClick={this.openDropDown} >
                    <Img className="NavbarDropdownItemLangImg"
                        src={newFlag}
                        alt={languageTranslation}
                        decode={false}
                    />
                    <span className="nav-text">{langInitials}</span>

                </div>

                <nav className={isExpanded}>
                    <div className="NavbarDropdownItems">
                        <div className="NavbarDropdownItem"
                            onClick={() => this.handleLangugageChange("en")}
                        >

                            <Img
                                className="NavbarDropdownItemLangImg"
                                src={countyFlags.en}
                                alt="en"
                                decode={false}
                            />
                            <span className="drop-nav-text">EN</span>
                        </div>

                        <div className="NavbarDropdownItem"
                            onClick={() => this.handleLangugageChange("sr")}
                        >

                            <Img
                                className="NavbarDropdownItemLangImg"
                                src={countyFlags.sr}
                                alt="sr"
                                decode={false}
                            />
                            <span className="drop-nav-text">SR</span>
                        </div>

                        <div className="NavbarDropdownItem"
                            onClick={() => this.handleLangugageChange("fr")}
                        >

                            <Img
                                className="NavbarDropdownItemLangImg"
                                src={countyFlags.fr}
                                alt="fr"
                                decode={false}
                            />
                            <span className="drop-nav-text">FR</span>
                        </div>
                    </div>
                </nav>


            </div >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        languageTranslation: state.toggle.languageTranslation || "en"
    }
}

const TNSLanguageOnClickOutside = onClickOutside(TopNavbarSelectLanguage)


export default connect(mapStateToProps, { changeLanguageAction })(TNSLanguageOnClickOutside);


