import franceFlag from '../assets/flags/france.svg';
import serbiaFlag from '../assets/flags/serbia.svg';
import ukFlag from '../assets/flags/united-kingdom.svg';

export const languagesConfig = [
    "en",
    "sr",
    "fr"
]

export const countyFlags = {
    "en": ukFlag,
    "sr": serbiaFlag,
    "fr": franceFlag,
}