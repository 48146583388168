import React, { Component } from 'react';
import Ticket from '../../utils/ticket/Ticket';
import { connect } from "react-redux"


class ListArchivedTickets extends Component {

    mapTickets = () => {
     //   console.log("props u ListArchivedTickets", this.props.archivedTickets)
        const { archivedTickets = {} } = this.props;
        const ticketData =
            archivedTickets
            && archivedTickets.ticketData
        if (ticketData && ticketData.length)
            return ticketData.map(ticket =>
                <div key={ticket.id}>
                    <Ticket {...ticket} 
                    isArchived = {true}
                    />
                </div>
            )
    }

    render() {

        return (
            <div className="c-whitish">
                {this.mapTickets()}
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        archivedTickets: state.archivedTickets
    }
}

export default connect(mapStateToProps, null)(ListArchivedTickets);