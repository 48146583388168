import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Label, Input as CheckInput } from 'reactstrap';
import { connect } from 'react-redux';
import { userSignUp } from "../../../store/actions/auth";
import { toggleLoader } from '../../../store/actions/toggle';
import { changeRouteValue } from '../../../store/actions/viewData';
import {
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import Input from '../../utils/inputs/Input';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            name: '',
            errorMessage: '',
            blockUnmounting: false,
            isOver18: false,
            isLicenceCheck: false
        };
    }

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }

    checkAge = () => {
        this.setState((prevState) => {
            return {
                isOver18: !prevState.isOver18
            }
        })
    }

    checkLicence = () => {
        this.setState((prevState) => {
            return {
                isLicenceCheck: !prevState.isLicenceCheck
            }
        })
    }

    signUp = () => {
        const { tl = {} } = this.props;
        //  this.setState({ blockUnmounting: true });
        if (!this.state.isOver18) return;
        if (!this.state.isLicenceCheck) return;
        const { email, password, name } = this.state;
        if (name.length < 2 || name.length > 20) {
            this.props.addErrorNotesWithPayload({
                title: tl.name_length_invalid,
                body: tl.name_must_min_max_chars_long
            });
            return;
        }
        this.props.userSignUp({ email, password, name });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.signUp();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }

    componentWillUnmount() {
        this.props.toggleLoader(false)
    }

    render() {

        const { isOver18, isLicenceCheck } = this.state;
        const { tl = {} } = this.props;
        return (
            <div>
                <div className="form-boxshadow p-3 rounded">
                    <h1 className="text-center"> {tl.sign_u_t} </h1>

                    <Input
                        type="email"
                        label={tl.enter_email_t}
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        required={true}
                    />


                    <Input
                        type="text"
                        label={tl.enter_name_t}
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        required={true}
                    />



                    <Input
                        type="text"
                        typeToggle={true}
                        label={tl.enter_pass_t}
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        required={true}
                    />


                    <div className="custom-input-box">

                        <Label check={isOver18} className="mx-3-5">
                            <CheckInput
                                type="checkbox"
                                className="cursor-pointer"
                                onClick={this.checkAge}
                            />{' '}
                            <span className="c-whitish">{tl.i_am_years_old} </span>
                        </Label>
                        <br />
                        <Label check={isLicenceCheck} className="mx-3-5">
                            <CheckInput
                                type="checkbox"
                                className="cursor-pointer"
                                onClick={this.checkLicence}
                            />{' '}
                            <span className="link-button link-success-button"
                                onClick={this.addNewRoute('/licence-and-terms')}
                            > {tl.licence_terms}  </span>
                        </Label>
                        <br />
                        <button
                            className={(isOver18 && isLicenceCheck) ? "success-button" : "inactive-button"}
                            onClick={this.signUp}
                        >
                            {tl.sign_u_t}
                        </button>
                        <br />


                        <div>
                            {(!this.state.blockUnmounting) ?
                                <Link className="text-light font-flower" style={{ textDecoration: 'none' }} to={'./signin'}>
                                    {tl.already_user_q}
                                </Link> : null
                            }
                        </div>
                        <div>

                            {(!this.state.blockUnmounting) ?
                                <Link className="text-light font-flower" style={{ textDecoration: 'none' }} to={'./reset-password'}>
                                    {tl.forgot_password_q}
                                </Link> : null
                            }

                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.toggle.loader,
        tl: state.tl[state.tl.default]
    }
}


export default connect(mapStateToProps, {
    userSignUp,
    toggleLoader,
    changeRouteValue,
    addErrorNotesWithPayload
})(SignUp);