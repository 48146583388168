import { firebaseAuth, firestoreDB } from "../../../config/fbConfig";
//const firestoreDB = firebase.firestore();
import axios from 'axios';

export const submitBlog = async function () {
    const { tl = {} } = this.props;
    try {

        this.props.toggleLoader(true);

        const {
            content,
            title,
            description,
            imageUrl,
            language,
            category
        } = this.state;

        let { languages } = this.state;
        if (
            !content
            || !title
            || !description
            || !imageUrl
            || !language
            || !category
            || !languages
        ) {
            this.props.addErrorNotesWithPayload({
                title: tl.blog_not_saved,
                body: tl.fill_fields_to_submit_blog
            })
            this.props.toggleLoader(false)
            return;
        }

        if (imageUrl.length > 250) {
            this.props.addErrorNotesWithPayload({
                title: tl.blog_not_saved,
                body: tl.img_url_must_be_min_max_chars
            })
            this.props.toggleLoader(false)
            return false;
        }


        const blogId = this.props.match.params.id;
        const idToken = await firebaseAuth.currentUser.getIdToken(true)
        await axios.patch("blogs", {
            idToken,
            blogId,
            title,
            description,
            languages,
            language,
            imageUrl,
            category,
            content
        })

        this.setState({ languages });
        this.props.toggleLoader(false)
        this.props.addSuccessNotesWithPayload({
            title: tl.success_t,
            body: tl.blog_saved
        })

    } catch (error) {
        this.props.addErrorNotesWithPayload({
            title: tl.blog_not_saved,
            body: tl.ops_something_went_wrong + tl.blog_not_saved + "."
        })
        this.props.toggleLoader(false)
    }
}


export const fetchBlogMeta = async function () {
    const { tl = {} } = this.props;
    try {

        this.props.toggleLoader(true);
        const { language } = this.state;
        const blogsId = this.props.match.params.id;

        const blogDoc = await firestoreDB
            .collection('blogs')
            .doc(blogsId)
            .get();

        this.props.toggleLoader(false);

        if (blogDoc.exists) {
            const blogData = blogDoc.data();

            const langAvailable = blogData.languages.find((lang) => lang === language);

            if (langAvailable) {
                this.fetchBlogWithContent(langAvailable, blogData.authorId);
            } else this.setState({
                content: ""
            })

            this.setState({
                authorId: blogData.authorId,
                authorName: blogData.authorName,
                description: blogData.description,
                imageUrl: blogData.imageUrl,
                languages: blogData.languages,
                setAt: blogData.setAt,
                title: blogData.title,
                category: blogData.category
            })

        } else {

            this.props.addErrorNotesWithPayload({
                title: tl.page_not_found,
                body: tl.page_you_are_looking_for
            })
        }

    } catch (error) {
        this.props.toggleLoader(false);
        this.props.addErrorNotesWithPayload({
            title: tl.blog_not_fetched,
            body: tl.ops_something_went_wrong + tl.blog_not_fetched + "."
        })
    }
}

export const fetchBlogWithContent = async function (langAvailable, authorId) {
    const { tl = {} } = this.props;
    try {
        const blogsId = this.props.match.params.id;

        // Realtime DB
        /*   const contentDbRef = firebase.database().ref('blogs-content/' + blogsId + '/' + authorId + '/' + langAvailable);
           contentDbRef.once("value",
               (snapshot) => {
                   const RDBcontent = snapshot.val();
                   if (RDBcontent && RDBcontent.content)
                       this.setState({ content: RDBcontent.content })
               }
           )*/

        // Firestore DB
        let contentFirestore = await firestoreDB
            .collection('blogs-content-' + langAvailable)
            .doc(blogsId).get();
        if (contentFirestore.exists) {
            const FirestoreDataContent = contentFirestore.data()
            this.setState({ content: FirestoreDataContent.content })
        }


    } catch (error) {
        this.props.addErrorNotesWithPayload({
            title: tl.page_not_found,
            body: tl.page_you_are_looking_for
        })
    }

}