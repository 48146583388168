import { combineReducers } from 'redux'
import userReducer from './userReducer'
import toggleReducer from './toggleReducer';
import viewDataReducer from './viewDataReducer';
import bannersReducer from './bannersReducer';
import notesReducer from './notesReducer';
import analysesReducer from './analysesReducer';
import activeAnalysesReducer from './activeAnalysesReducer';
import activeTicketsReducer from './activeTicketsReducer';
import archivedTicketsReducer from './archivedTicketReducer';
import statisticsReducer from './statisticsReducer';
import monthlyStatisticsReducer from './monthlyStatistics';
import homeTicketReducer from './homeTicketReducer'
import translationReducer from './translationReducer';
import readyAppReducer from './readyAppReducer';
import inboxNotesReducer from './inboxNotesReducer';
import profilesReducer from './profilesReducer';

const rootReducer = combineReducers({
  user: userReducer,
  activeAnalyses: activeAnalysesReducer,
  activeTickets: activeTicketsReducer,
  archivedTickets: archivedTicketsReducer,
  toggle: toggleReducer,
  profiles: profilesReducer,
  viewData: viewDataReducer,
  banners: bannersReducer,
  notes: notesReducer,
  analyses: analysesReducer,
  inboxNotes: inboxNotesReducer,
  statistics: statisticsReducer,
  monthlyStatistics: monthlyStatisticsReducer,
  homeTicket: homeTicketReducer,
  tl: translationReducer,
  isReadyApp: readyAppReducer
});

export default rootReducer;