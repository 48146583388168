import React, { Component } from 'react';
import { connect } from "react-redux"
import Input from '../../utils/inputs/Input';
import { updateEmail } from '../../../store/actions/auth';

class UpdateEmail extends Component {

    state = {
        email: '',
        errorMessage: ''
    };

    updateEmail = () => {
        this.props.updateEmail(this.state.email);
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.updateEmail();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }

    render() {
        return (


            <div className="p-1">
               
                <Input
                    type="email"
                    label="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    required={true}
                />

                <div className="container d-flex justify-content-center">
                    <button
                        className="success-button"
                        onClick={this.updateEmail}
                    > Update an Email</button>
                </div>
            </div>


        )
    }

}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps, { updateEmail })(UpdateEmail);

