import React, { Component } from 'react';
import firebase, { firebaseAuth } from "../../../config/fbConfig";
import { connect } from "react-redux"
import axios from "axios";
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { Collapse } from 'reactstrap';
import Img from 'react-image';
import noPhoto from '../../../assets/no-photos.png';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
const storage = firebase.storage();
const storageRef = storage.ref();

class SingleUser extends Component {

    state = {
        isVisible: true,
        isOpen: false,
        isOpenTogle: false
    }

    toggle = () => {
        this.setState((prevState) => {
            return {
                isOpenTogle: !prevState.isOpenTogle
            }
        })
    }

    openNotes = () => {
        this.setState({ isOpen: true });
    }
    closeNotes = () => {
        this.setState({ isOpen: false });
    }


    makeAdmin = (level) => async () => {
        try {

            this.props.toggleLoader(true);
            const { userData } = this.props;
            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            await axios.patch('claims/change-admin-level', { idToken, adminLevel: level, userID: userData.id })
            this.props.addSuccessNotesWithPayload({
                title: "Success",
                body: "Success, user's admin level is updated."
            })
            this.props.toggleLoader(false)
        } catch (error) {
            this.props.addErrorNotesWithPayload({
                title: "User admin claims are not added",
                body: "Oops, something went wrong. User admin claims are not added."
            })
            this.props.toggleLoader(false)
        }
    }

    makeTipster = (level) => async () => {
        try {

            this.props.toggleLoader(true);
            const { userData } = this.props;

            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            await axios.patch('claims/change-tipster-level', { idToken, tipsterLevel: level, userID: userData.id })
            this.props.addSuccessNotesWithPayload({
                title: "Success",
                body: "Success, user's tipster level is updated."
            });
            this.props.toggleLoader(false)
        } catch (error) {
            this.props.addErrorNotesWithPayload({
                title: "User tipster claims are not added",
                body: "Oops, something went wrong. User tipster claims are not added."
            });
            this.props.toggleLoader(false)
        }
    }

    deleteUser = async () => {

        try {


            this.props.toggleLoader(true);
            const { userData } = this.props;
            if (userData.adminLevel && userData.adminLevel === 20) {
                this.props.addErrorNotesWithPayload({
                    title: "User is not deleted",
                    body: "You can not delete that kind of admin."
                });
                this.props.toggleLoader(false);
                this.closeNotes();
                return;
            }

            const listResponse = await storageRef.child(`${userData.id}/avatar`).listAll();
            listResponse.items.forEach(function (itemRef) {
                storageRef.child(`${userData.id}/avatar/${itemRef.name}`).delete();
            });

            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            await axios.post('auth/delete-user', { idToken, usersId: userData.id })
            this.props.addSuccessNotesWithPayload({
                title: "Success",
                body: "Success, user is deleted."
            });
            this.setState({
                isVisible: false
            })
            this.props.toggleLoader(false)
            this.closeNotes();
        } catch (error) {
            this.props.addErrorNotesWithPayload({
                title: "User is not deleted",
                body: "Oops, something went wrong. User is not deleted."
            });
            this.props.toggleLoader(false)
            this.closeNotes();
        }

    }


    checkUserRecords = async () => {
        //first delete storage from client side
        try {

            this.props.toggleLoader(true);
            const { userData } = this.props;

            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            const res = await axios.post('profile/check-records', { idToken, usersId: userData.id })
            const userVerifiedText = (res.data && res.data.message)
                ? "User is verified"
                : "User is not verified";

            this.props.addSuccessNotesWithPayload({
                title: "Profile is checked",
                body: userVerifiedText
            });
            this.props.toggleLoader(false)
        } catch (error) {
            //  console.log("errr", error)
            this.props.addErrorNotesWithPayload({
                title: "Profile is not fetched",
                body: "Oops, something went wrong. Profile is not fetched."
            });
            this.props.toggleLoader(false)
        }
    }



    verifyUserByEmail = async () => {
        //first delete storage from client side
        try {

            this.props.toggleLoader(true);
            const { userData } = this.props;

            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            await axios.post('profile/verify-user-by-admin', { idToken, usersId: userData.id })

            this.props.addSuccessNotesWithPayload({
                title: "Success",
                body: "User is verified"
            });
            this.props.toggleLoader(false)
        } catch (error) {
            //  console.log("errr", error)
            this.props.addErrorNotesWithPayload({
                title: "Profile is not fetched",
                body: "Oops, something went wrong. User is not fetched."
            });
            this.props.toggleLoader(false)
        }
    }

    render() {
        const { userData } = this.props;
        const createdAtDate = userData.createdAt.toDate().toLocaleString() || "";
        const displayProp = (this.state.isVisible) ? "block" : "none";
        return (
            <div style={{
                display: displayProp,
            }}>
                <ConfirmNoteOutput
                    notes={{
                        title: "Delete User",
                        body: "Are you sure that you want to delete this user?",
                        closeNotesText: "Cancel",
                        confirmNotesText: "Delete"
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.deleteUser}
                    isOpen={this.state.isOpen}
                />

                <div className="card mb-3" style={{
                    display: "flex",
                    backgroundColor: "#273142"
                }}
                >
                    <div className="row no-gutters">

                        <div className="col-md-4  col-sm-12"

                        >
                            <div className="text-center d-flex justify-content-start">

                                <Img
                                    className="an-card-avatar"
                                    src={[userData.photoURL, noPhoto]}
                                    decode={false}
                                />

                                <div className="analise-data-wrapper c-whitish text-center">
                                    <span className="an-card-author  c-whitish">
                                        Email: {userData.email || ""}
                                    </span>
                                    <span className="an-card-yieldvalue  c-whitish">
                                        Name: {userData.name || ""}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8  col-sm-12">
                            <div className="card-body  c-whitish">

                                <div> Admin Level: {userData.adminLevel || 0}</div>
                                <div> Tipster Level: {userData.tipsterLevel || 0}</div>
                                <button onClick={this.toggle}
                                    className="analyse-toggler success-button"
                                >
                                    Toggle
                                </button>
                                <Collapse isOpen={this.state.isOpenTogle}>
                                    <div className="card-text text-left">
                                        <button
                                            onClick={this.checkUserRecords}
                                            className="btn btn-danger  btn-sm"
                                        > Check if User is Verified </button>
                                    </div>

                                    <button
                                        className="success-button btn-sm"
                                        onClick={this.verifyUserByEmail}
                                    > Verify users email </button>

                                    <p className="card-text text-left"><small className="text-muted">Created at : {createdAtDate}</small></p>

                                    <button
                                        className="btn btn-dark btn-sm"
                                        onClick={this.makeAdmin(0)}
                                    > Remove Admin Claims </button>
                                    <button
                                        className="btn btn-dark btn-sm"
                                        onClick={this.makeTipster(3)}
                                    >  Tipster
                                 </button>

                                    <button
                                        className="btn btn-dark btn-sm"
                                        onClick={this.makeTipster(0)}
                                    > Remove Tipster Claims
                                 </button>

                                    <button
                                        className="btn btn-dark btn-sm"
                                        onClick={this.makeAdmin(4)}
                                    > Moderator </button>
                                    <button
                                        className="btn btn-dark btn-sm"
                                        onClick={this.makeAdmin(8)}
                                    > Admin </button>
                                    <button
                                        className="btn btn-dark btn-sm"
                                        onClick={this.makeAdmin(10)}
                                    > Master-admin </button>

                                    <button
                                        onClick={this.openNotes}
                                        className="btn btn-danger  btn-sm"
                                    > Delete User </button>



                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue,
        loader: state.toggle.loader,
    }
}

export default connect(mapStateToProps, {
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
})(SingleUser);