import React, { Component } from 'react';
import { connect } from "react-redux"


class AddBlogNavbar extends Component {

    render() {
        const { tl = {} } = this.props;
        return (
            <div id="add-blog-navbar">
                <button
                    className="cancel-button mx-1"
                    onClick={this.props.handleClickCanWrite}
                >
                    {tl.preview_t}
                </button>
                <button
                    className="success-button"
                    onClick={this.props.submitBlog}
                >
                    {tl.save_t}
                </button>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, null)(AddBlogNavbar);