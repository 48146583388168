import React, { Component } from 'react';
import { connect } from "react-redux";
import AddBlogNavbar from './AddBlogNavbar';
import AddMetaBlogValues from './AddMetaBlogValues';
import JoditApp from '../../utils/editor/JoditApp';
import { categories } from '../../../config/categories';
import {
    submitBlog,
    fetchBlogMeta,
    fetchBlogWithContent
} from './UpdateBlogModules';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';


class UpdateBlog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            canWrite: true,
            title: "",
            language: "en",
            description: "",
            imageUrl: "",
            category: categories[0],
            authorId: '',
            authorName: '',
            languages: [],
            setAt: {}
        }

        this.submitBlog = submitBlog.bind(this);
        this.fetchBlogMeta = fetchBlogMeta.bind(this);
        this.fetchBlogWithContent = fetchBlogWithContent.bind(this)
    }


    componentDidMount() {
        this.fetchBlogMeta();
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevState.language !== this.state.language && this.state.languages) {
            const langAvailable = this.state.languages.find((lang) => lang === this.state.language);
            if (langAvailable)
                this.fetchBlogWithContent(langAvailable, this.state.authorId);

        }

    }


    updateContent = (value) => {
        this.setState({ content: value })
    }

    handleMetaChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleClickCanWrite = () => {
        this.setState(
            (prevState) => {
                return { canWrite: !prevState.canWrite }
            })
    }


    render() {
        const {
            content,
            canWrite,
            title,
            language,
            description,
            imageUrl,
            category
        } = this.state;

        return (
            <div id="update-blog">
                <AddBlogNavbar
                    handleClickCanWrite={this.handleClickCanWrite}
                    submitBlog={this.submitBlog}
                />
                <AddMetaBlogValues
                    title={title}
                    language={language}
                    description={description}
                    imageUrl={imageUrl}
                    handleMetaChange={this.handleMetaChange}
                    category={category}
                />
                <JoditApp
                    canWrite={canWrite}
                    content={content}
                    updateContent={this.updateContent}
                    isBlog={true}
                />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        displayName: state.user.displayName,
        loader: state.toggle.loader,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload,
    toggleLoader
})(UpdateBlog);