import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { changeRouteValue } from '../../../store/actions/viewData';
import { firebaseAuth } from '../../../config/fbConfig';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
import axios from 'axios';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';

class UpdateOrDeleteBlogRoutes extends Component {

    state = {
        isOpenDelete: false
    }

    addNewRoute = (payload) => () => {

        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }

    deleteBlog = async () => {

        try {
            this.closeNotes()
            this.props.toggleLoader(true)
            const blogId = this.props.match.params.id;
            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            await axios.delete("blogs", {
                data: {
                    idToken,
                    blogId
                }
            })

            this.props.addSuccessNotesWithPayload({
                title: "Success",
                body: "Blog is deleted."
            })

            this.props.toggleLoader(false)
            this.addNewRoute('/')();

        } catch (error) {
            this.closeNotes()
            this.props.addErrorNotesWithPayload({
                title: "Blog is not deleted",
                body: "Oops, something went wrong. Blog is not deleted."
            })
            this.props.toggleLoader(false)
        }
    }


    openNotes = () => {
        this.setState({ isOpenDelete: true });
    }
    closeNotes = () => {
        this.setState({ isOpenDelete: false });
    }



    render() {
        const blogId = this.props.match.params.id;
        const { emailVerified, adminLevel, authorId = "", uid } = this.props;
        const isBlogEditable = (
            emailVerified    //user is verified
            && (
                // admin4  can edit only it's own blogs
                (adminLevel >= 4 && authorId === uid && authorId)

                // admin8 can edit others active tips
                || adminLevel >= 8
            )
        );
        return (
            <div>
                <ConfirmNoteOutput
                    notes={{
                        title: "Delete Blog",
                        body: "Are you sure that you want to delete this blog?",
                        closeNotesText: "Cancel",
                        confirmNotesText: "Yes"
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.deleteBlog}
                    isOpen={this.state.isOpenDelete}
                />

                {(isBlogEditable)
                    ? <button
                        className="success-button mr-2"
                        style={{ display: "inline" }}
                        onClick={this.addNewRoute(`/update-blog/${blogId}`)}
                    > Update Blog </button>
                    : null

                }
                
                {
                    (emailVerified && adminLevel >= 8)
                        ? <button
                            onClick={this.openNotes}
                            className="error-button"> Delete Blog
                          </button>
                        : null
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        displayName: state.user.displayName,
        adminLevel: state.user.adminLevel || 0,
        emailVerified: state.user.emailVerified || false,
        //   languageTranslation: state.toggle.languageTranslation || "en"
    }
}

const UpdateOrDeleteBlogRoutesWithRouter = withRouter(UpdateOrDeleteBlogRoutes);

export default connect(
    mapStateToProps, {
    changeRouteValue,
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
})(UpdateOrDeleteBlogRoutesWithRouter);