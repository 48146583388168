
// analyses categories
export const categories = [
    "Betting Academy",
    "Ninca90 notification",
    "NBA",
    "Football elite",
    "Interesting facts",
    "Other",
    "Fantasy sports",
    "Bookies"
]


//ticket title categories
export const ticketCategories = [
    "Smooth",
    "Reasonable",
    "Bravely",
    "Crazy"
]
/*
export const ticketCategories = [
    "Zicer",
    "Realno",  //na srpskom
    "Hrabro",
    "Ludo"
]*/