import React, { Component } from 'react';
import Ticket from '../../utils/ticket/Ticket';
import { connect } from "react-redux"


class ListTickets extends Component {

    mapTickets = () => {
        const activeTickets = this.props.activeTickets.sort((a, b) => { return a.f - b.f });
        return activeTickets.map(ticket =>
            <div key={ticket.id}>
                <Ticket {...ticket}
                    isArchived={false}
                />
            </div>
        )
    }

    render() {

        return (
            <div className="c-whitish">
                {this.mapTickets()}
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        activeTickets: state.activeTickets
    }
}

export default connect(mapStateToProps, null)(ListTickets);