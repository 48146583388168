import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDJ2oQH7HZMnBRK_VIwP6ZZomyRmp5AmV4",
  authDomain: "ninca90-c16ee.firebaseapp.com",
  databaseURL: "ninca90-c16ee.firebaseio.com",// obriao sam https:// zbog regex
  projectId: "ninca90-c16ee",
  storageBucket: "ninca90-c16ee.appspot.com",
  messagingSenderId: "198230260628",
  appId: "1:198230260628:web:96ef0fd444164d582fad81",
  measurementId: "G-PQ1JD7HZX3"
};

firebase.initializeApp(firebaseConfig);
// firebase.firestore();
export const firebaseAuth = firebase.auth();
export const firestoreDB = firebase.firestore();

export default firebase;