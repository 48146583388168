const initialstate = {
    isAnalysesActive: true,
    sortByDay: true,
    startDate: new Date(),
    analysesData: [],
    isFirstView: true,
    endAt: 0
}



const analysesReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "analyses/ADD":
            return { ...action.payload };

        case "analyses/REMOVE":
            return { ...state, endAt: 0, analysesData: [] };

        default:
            return state
    }
}

export default analysesReducer;