import React, { Component } from 'react';
import firebaseApp from '../../../config/fbConfig'
import { connect } from "react-redux"
import ListedSingleBlog from './ListedSingleBlog'
import { toggleLoader } from '../../../store/actions/toggle';
class ListBlogs extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            lastFetchedTime: "",
            blogsArray: [],
            isLoadingFirstLoad: true,
            isLoading: false,
            skipLimit: 4,
            emptyAjax: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
    //    window.addEventListener('scroll', this.fetchUsers, true);
        this.fetchUsers({ isFirstFetch: true });
    }


    fetchUsers = ({ isFirstFetch }) => {
        if ((
            !this.state.lastFetchedTime && !isFirstFetch
        ) || this.state.isLoading
        ) return;
        const { skipLimit } = this.state;
        this._isMounted && isFirstFetch && this.setState({ isLoadingFirstLoad: true });
        this._isMounted && this.setState({ isLoading: true });
        const startAfter = (isFirstFetch)
            ? ""
            : this.state.lastFetchedTime;

        const firestoreDB = firebaseApp.firestore();
        let lastFetchedTime = "";
        const queryBlogsRef = (this.props.selectedCategory)
            ? firestoreDB
                .collection('blogs')
                .where('category', '==', this.props.selectedCategory)
            : firestoreDB
                .collection('blogs')



        this._isMounted && queryBlogsRef
            .orderBy('setAt', 'desc')
            .startAfter(startAfter)
            .limit(skipLimit)
            .get()
            .then((querySnapshot) => {
                const blogsArray = [...this.state.blogsArray]
                if (querySnapshot.size < skipLimit)
                    this._isMounted && this.setState({
                        emptyAjax: true
                    })
                querySnapshot.forEach((doc) => {
                    lastFetchedTime = doc.data().setAt;
                    blogsArray.push({ ...doc.data(), id: doc.id })
                });
                this._isMounted && this.setState({
                    lastFetchedTime,
                    blogsArray: blogsArray,
                    isLoadingFirstLoad: false
                });
                if (this.props.loader !== "app")
                    this.props.toggleLoader(false)
            })
            .catch((err) => {
                //   console.log("IMPORTANT to create custom indexes err", err)
                if (this.props.loader !== "app")
                    this.props.toggleLoader(false)
                this._isMounted && isFirstFetch && this.setState({
                    isLoadingFirstLoad: false
                })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.blogsArray !== this.state.blogsArray &&
            this.state.isLoading
        ) {
            this._isMounted && this.setState({
                isLoading: false
            });
        }
    }


  /*  handleScroll = (e) => {
        if (!this.state.isLoading && this.state.blogsArray[0]) {
            const lastDiv =
                document.getElementById(
                    this.state.blogsArray[this.state.blogsArray.length - 1]._id
                );
            const lastDivOffset = lastDiv.offsetTop + lastDiv.clientHeight;
            const pageOffset = window.pageYOffset + window.innerHeight;
            const bottomOffset = 20;
            if (
                pageOffset > lastDivOffset - bottomOffset
            ) {
                this._isMounted && this.fetchUsers({ isFirstFetch: false });
            }
        }
    }*/


    componentWillUnmount() {
        this._isMounted = false;
    //    window.removeEventListener('scroll', this.handleScroll);
    }


    listBlogs = () => {
        const { isLoadingFirstLoad, blogsArray } = this.state;
        if (isLoadingFirstLoad) return <div> Loading </div>
        if (!blogsArray.length)
            return <div> No blogs </div>
        const listedUsers =
            blogsArray.map((blogData) =>
                <ListedSingleBlog blogData={blogData} key={blogData.id} />
            );
        return listedUsers;
    }

    render() {
        return (
            <div>

                {this.listBlogs()}
                {
                    (this.state.emptyAjax)
                        ? <div className="c-whitish"> No more blogs </div>
                        : <button
                        className="success-button"
                        onClick={this.fetchUsers}
                    >   Load More </button>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loader: state.toggle.loader
    }
}

export default connect(mapStateToProps, { toggleLoader })(ListBlogs);