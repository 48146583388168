import React, { Component } from 'react';
import { connect } from "react-redux"
import onClickOutside from "react-onclickoutside";
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
import Img from 'react-image'
import SignOutButton from './SignOutButton';
import userAvatarImg from '../../../assets/user-avatar.png';
class TopNavbarLoginDropdown extends Component {

    state = {
        isExpanded: false
    }

    handleClickOutside = evt => {
        this.setState({ isExpanded: false })
    };


    openDropDown = () => {
        this.setState((prevState) => {
            return {
                isExpanded: !prevState.isExpanded
            }
        })
    }

    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }

    render() {
        const isExpanded = (this.state.isExpanded) ? "NavbarDropdown" : "NavbarDropdownHidden";
        const { photoURL, tl = {} } = this.props;
        return (
            <div className="dropdownWrapper" id="dropdownProfileTopNavbar">

                <div
                    className="link-button dropdownButton"
                    onClick={this.openDropDown} >
                    <Img
                        className="NavbarDropdownItemAvatarImg"
                        src={[photoURL, userAvatarImg]}
                        alt="avatar"
                        decode={false}
                    />

                </div>

                <nav className={isExpanded}>
                    <div className="NavbarDropdownItems">
                        <div className="NavbarDropdownItem"
                            onClick={this.addNewRoute("/profile-settings")}
                        >
                            <span className="drop-nav-text">
                                {tl.profile_settings_t}
                            </span>
                        </div>

                        <div className="NavbarDropdownItem">
                            <SignOutButton />
                        </div>
                    </div>
                </nav>


            </div >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        photoURL: state.user.photoURL || "",
        tl: state.tl[state.tl.default]
    }
}

const TNLDOnClickOutside = onClickOutside(TopNavbarLoginDropdown)
const TNLDOnClickOutsideWithRouter = withRouter(TNLDOnClickOutside);

export default connect(mapStateToProps, { changeRouteValue })(TNLDOnClickOutsideWithRouter);