import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Img from 'react-image'
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
import ticketImg from '../../../assets/fakePlaceholders/tickets.png'
import analysisImg from '../../../assets/fakePlaceholders/analysis.png'
import blogsImg from '../../../assets/fakePlaceholders/blogs.png'
class FakeTipsPlaceholders extends Component {


    addNewRoute = (payload) => (e) => {
        e.preventDefault();
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }


    render() {
        const { tl ={} } = this.props;
        return (
            <Fragment>

                <div className="row fake-placeholders">
                    <div
                        className="col-lg-4 col-md-12"
                        onClick={this.addNewRoute("/analyses")}
                    >
                        <Img
                            src={[analysisImg]}
                            alt="analysis"
                        />
                        <h3> {tl.analysis_t} </h3>
                    </div>

                    <div
                        className="col-lg-4 col-md-12"
                        onClick={this.addNewRoute("/tickets")}
                    >
                        <Img
                            src={[ticketImg]}
                            alt="tickets"

                        />
                        <h3> {tl.tickets_t} </h3>
                    </div>

                    <div
                        className="col-lg-4 col-md-12"
                        onClick={this.addNewRoute("/blogs")}
                    >
                        <Img
                            src={[blogsImg]}
                            alt="blogs"

                        />
                        <h3> {tl.blogs_t} </h3>
                    </div>
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

const FakeTipsPlaceholdersWithRouter = withRouter(FakeTipsPlaceholders);
export default connect(mapStateToProps, { changeRouteValue })(FakeTipsPlaceholdersWithRouter);