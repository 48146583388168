import React, { useState, useEffect } from 'react';
//import firebaseApp from '../../../config/fbConfig'
import SingleInboxNote from './SingleInboxNote';
import { connect } from "react-redux"

const InboxNotes = (props) => {

    const [notesState, setNotesState] = useState([]);

    useEffect(() => {
        setNotesState([...props.inboxNotes])
    }, [props.inboxNotes]) //componentDidUpdate and Mount

    if (notesState.length)
        return notesState.reverse().map(elem => <SingleInboxNote key={elem.id} {...elem} />)
    else
        return (
            <div className="c-whitish"> No notifications </div>
        );
}


const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue,
        inboxNotes: state.inboxNotes,
    }
}

export default connect(mapStateToProps, null)(InboxNotes);