import React, { Component, Fragment } from 'react'
import { resetPassword } from "../../../store/actions/auth"
import { connect } from 'react-redux';
import Input from '../../utils/inputs/Input';

class ResetPassword extends Component {

    state = {
        email: "",
        errorMessage: "",
    }

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    handleResetPassword = () => {
        const { email } = this.state;
        console.log("click handleResetPassword")
        this.props.resetPassword(email);
        // this.props.resetPassword(email) treba mi email ovde
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.handleResetPassword();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }

    render() {


        return (
            <Fragment>

                <h1 className="text-center my-3">  Reset  Password</h1>

                <Input
                    type="email"
                    label="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    required={true}
                />
              
                <div className="container d-flex justify-content-center">
                    <button

                        className="success-button"
                        type="button"
                        onClick={this.handleResetPassword}
                    >
                        Reset Password
                    </button>
                </div>
                <br />

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid
    }
}


export default connect(mapStateToProps, { resetPassword })(ResetPassword);