import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import JoditApp from '../../utils/editor/JoditApp';
import Comments from '../../utils/comments/Comments';
import /*firebase,*/ { firestoreDB } from "../../../config/fbConfig";
import { withRouter } from "react-router";
import SingleBlogHeader from './singleBlogHeader';
import UpdateOrDeleteBlogRoutes from './UpdateOrDeleteBlogRoutes';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { closeNotes } from '../../../store/actions/notes';
import PagesHelmet from '../../utils/helmet/PagesHelmet';


class SingleBlog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            errorMessage: '',
            authorId: '',
            authorName: '',
            description: '',
            imageUrl: '',
            languages: [],
            setAt: {},
            title: '',
            componentView: 'loading',
            blogExists: false
        }
    }

    fetchBlogMeta = async () => {
        try {
            const { languageTranslation } = this.props;
            const blogsId = this.props.match.params.id;

            const blogDoc = await firestoreDB
                .collection('blogs')
                .doc(blogsId)
                .get();

            this.props.toggleLoader(false)
            if (blogDoc.exists) {
                this.setState({
                    blogExists: true
                })
                const blogData = blogDoc.data();

                const langAvailable = blogData.languages.find((lang) => lang === languageTranslation);

                if (langAvailable) {
                    this.fetchBlogWithContent(langAvailable, blogData.authorId);
                }

                else this.setState({
                    componentView: "notAvailableLanguage"
                })

                this.setState({
                    authorId: blogData.authorId,
                    authorName: blogData.authorName,
                    description: blogData.description,
                    imageUrl: blogData.imageUrl,
                    languages: blogData.languages,
                    setAt: blogData.setAt,
                    title: blogData.title
                })


            } else {
                this.setState({
                    componentView: "notAvailableLanguage",
                    blogExists: false
                })
                this.props.toggleLoader(false)

                this.props.addErrorNotesWithPayload({
                    title: "404 - PAGE NOT FOUND",
                    body: "The page you are looking for might have been removed, had it's name changed or is temporarily unavailable."
                })
            }

        } catch (error) {
            this.setState({
                componentView: "notAvailableLanguage",
                blogExists: false
            })
            this.props.toggleLoader(false);
            this.props.addErrorNotesWithPayload({
                title: "Blog is not fetched",
                body: "Oops, something went wrong. Blog is not fetched."
            })
        }
    }

    fetchBlogWithContent = async (langAvailable, authorId) => {
        try {

            const blogsId = this.props.match.params.id;

            // Realtime DB
            /* const contentDbRef = firebase.database().ref('blogs-content/' + blogsId + '/' + authorId + '/' + langAvailable);
             contentDbRef.once("value",
                 (snapshot) => {
                     const RDBcontent = snapshot.val();
                     if (RDBcontent && RDBcontent.content)
                         this.setState({ content: RDBcontent.content, componentView: "joditApp" })
 
                     else this.setState({
                         componentView: "notAvailableLanguage"
                     })
                 }
             )*/

            // Firestore DB
            let contentFirestore = await firestoreDB
                .collection('blogs-content-' + langAvailable)
                .doc(blogsId).get();
            if (contentFirestore.exists) {
                const FirestoreDataContent = contentFirestore.data()
                this.setState({ content: FirestoreDataContent.content, componentView: "joditApp" })
            }
            else this.setState({
                componentView: "notAvailableLanguage"
            })

        } catch (error) {
            this.setState({
                componentView: "notAvailableLanguage"
            })
        }

    }

    componentDidMount() {
        this.fetchBlogMeta();
        document.getElementById("top-navbar").scrollIntoView();

    }


    componentDidUpdate(prevProps, prevState) {

        if (prevProps.languageTranslation !== this.props.languageTranslation && this.state.languages) {
            const langAvailable = this.state.languages.find((lang) => lang === this.props.languageTranslation);
            if (langAvailable)
                this.fetchBlogWithContent(langAvailable, this.state.authorId);
            else {

                this.setState({
                    componentView: "notAvailableLanguage"
                })
            }
        }

    }


    switchComponentView = () => {
        const { componentView, content } = this.state;
        switch (componentView) {

            case "notAvailableLanguage":
                this.props.toggleLoader(false)
                return <div className="c-whitish my-3"> The page you are looking for might have been removed, had it's name changed or is temporarily unavailable, or language that you choose is not available. Please select another language.</div>;

            case "loading":
                this.props.toggleLoader(true)
                return null;

            case "joditApp":
                this.props.toggleLoader(false);
                this.props.closeNotes();
                return <Fragment>
                    <JoditApp
                        canWrite={false}
                        content={content}
                        updateContent={this.updateContent}
                    />

                </Fragment>
                    ;

            default:
                this.props.toggleLoader(false);

                this.props.addErrorNotesWithPayload({
                    title: "404 - PAGE NOT FOUND",
                    body: "The page you are looking for might have been removed, had it's name changed or is temporarily unavailable, or language that you choose is not available. Please select another language."
                })
                return null;
        }
    }

    render() {
        const { state } = this;
        const descriptionMeta = this.state.description;
        const blogsId = this.props.match.params.id;
        const titleMeta = this.state.title;
        const { imageUrl } = this.state;
        return (
            <div id="single-blog">
                <PagesHelmet
                    descriptionMeta={descriptionMeta}
                    clientURLMeta={"https://ninca90.com/blog/" + blogsId}
                    titleMeta={titleMeta}
                    imageMeta={imageUrl}
                />
                {
                    (state.title)
                        ? <SingleBlogHeader
                            title={state.title}
                            setAt={state.setAt}
                            authorName={this.state.authorName}
                            languages={state.languages}
                            componentView={state.componentView}
                            setLanguageToThisView={this.setLanguageToThisView}
                        />
                        : null
                }
                <UpdateOrDeleteBlogRoutes
                    authorId={state.authorId}
                />
                {this.switchComponentView()}
                {
                    (this.state.blogExists)
                        ? <Comments
                            displayName={this.props.displayName}
                            commentsRefId={this.props.match.params.id}
                            authorId={this.props.uid} // the one who comments
                            feedAuthorId={state.authorId} // the one who wrote blog or analysis
                        />
                        : null
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        displayName: state.user.displayName,
        languageTranslation: state.toggle.languageTranslation || "en"
    }
}

const SingleBlogWithRouter = withRouter(SingleBlog);

export default connect(mapStateToProps, {
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload,
    closeNotes
})(SingleBlogWithRouter);