export const inboxNotesAdd = (payload) => {
    return {
        type: "inboxNotes/ADD",
        payload
    }
}

export const inboxNotesUpdate = (payload) => {
    return {
        type: "inboxNotes/UPDATE",
        payload
    }
}
