import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
//import AddAnalysisToHomeButton from '../../utils/analysis/AddAnalysisToHomeButton';
import moment from 'moment';

class AddAnalysisNavbar extends Component {

    state = {
        isOpenSubmitMark: false,
        wdl: ""
    }

    markAnalysis = (wdl, isOpenSubmitMark) => () => {
        this.props.markAnalysis(wdl, isOpenSubmitMark);
        this.setState({ wdl, isOpenSubmitMark: !isOpenSubmitMark });
    }

    openNotes = () => {
        this.setState({ isOpenSubmitMark: true });
    }
    closeNotes = () => {
        this.setState({ isOpenSubmitMark: false, wdl: "" });
    }


    bodyText = () => {
        const { tl = {} } = this.props;
        switch (this.state.wdl) {
            case "w":
                return tl.mark_an_as_w_q;
            case "d":
                return tl.mark_an_as_d_q
            case "l":
                return tl.mark_an_as_l_q
            default:
                return tl.mark_an_as_d_q
        }
    }

    render() {
        const { canWrite = false,
            adminLevel,
            tipsterLevel,
            emailVerified,
            authorId,
            uid,
            startDate = new Date(),
            tl = {}
        } = this.props;


        const timeDBstamp = Date.now();
        const startDateStamp = moment(startDate).valueOf();

        //tipster can edit only 20 min before and not more than 60 days beofre the game
        const isTipsterTimeValid =
            startDateStamp - timeDBstamp >= 1200000
            && startDateStamp - timeDBstamp <= 1000 * 3600 * 24 * 60;
        const isActiveAnEditable = (
            emailVerified    //user is verified
            && (
                // tipster can edit only it's own tips
                (tipsterLevel >= 3 && authorId === uid && authorId && isTipsterTimeValid)

                // admin8 can edit others active tips
                || adminLevel >= 8
            )
        );

        const isActiveAndMarkable = (
            emailVerified    //user is verified
            && (
                // tipster can edit only it's own tips
                (tipsterLevel >= 3 && authorId === uid && authorId)

                // admin8 can edit others active tips
                || adminLevel >= 8
            )
        );
        const isArchivedAnEditable = (emailVerified && adminLevel >= 8);

        const isUpdateActiveRoute =
            this.props.path === "/analysis/:id([A-Za-z0-9_\\-]{7,14})" && isActiveAnEditable;

        const isMarkActiveRoute =
            this.props.path === "/analysis/:id([A-Za-z0-9_\\-]{7,14})" && isActiveAndMarkable;

        const isUpdateArchiveRoute =
            this.props.path === "/analysis-archive/:id([A-Za-z0-9_\\-]{7,14})" && isArchivedAnEditable;

        const isAddRoute =
            this.props.path === "/add-analysis";


        return (
            <div id="add-analysis-preview-save">
                <ConfirmNoteOutput
                    notes={{
                        title: this.bodyText(),
                        body: tl.sure_to_mark_analysis_q,
                        closeNotesText: tl.cancel_t,
                        confirmNotesText: tl.yes_t
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.markAnalysis(this.state.wdl, true)}
                    isOpen={this.state.isOpenSubmitMark}
                />


                { //OVDE SAM STAO, SREDI sta se vidi zavisno od claims i situacije
                    (isMarkActiveRoute || isUpdateArchiveRoute) ? <Fragment>
                        <h3 className="mt-3">{tl.edit_t}: </h3>
                        <button
                            className="success-button"
                            onClick={this.props.handleClickCanWrite}
                        >
                            {
                                (canWrite)
                                    ? "Preview"
                                    : "Edit"
                            }
                        </button>
                    </Fragment>
                        : null
                }

                {(canWrite && (isUpdateArchiveRoute || isUpdateActiveRoute || isAddRoute)) ?
                    <button
                        className="success-button ml-1"
                        onClick={this.props.submitAnalysis}
                    >
                        {tl.save_t}
                </button>
                    : null
                }


                {
                    ((isUpdateArchiveRoute || isMarkActiveRoute) && canWrite) //treba da check ako je adminlevel veci od ... pa ne znam ni ja kog broja jos u vek
                        //renderovati dugmice samo kod admina

                        ? <div>
                            <h3 className="mt-3">{tl.mark_t}: </h3>

                            <button
                                className="success-button"
                                onClick={this.markAnalysis("w", false)}
                            >
                                {tl.win_t}
                            </button>

                            <button
                                className="success-button mx-1"
                                onClick={this.markAnalysis("d", false)}
                            >
                                {tl.draw_t}
                            </button>

                            <button
                                className="success-button"
                                onClick={this.markAnalysis("l", false)}
                            >
                                {tl.lose_t} </button>

                        </div>
                        : null
                }
                {/*

                USKORO ZA ANALIZU NA PRVOJ STRANI
                    (adminLevel >= 20)
                        ? <AddAnalysisToHomeButton />

                        : null
                */    }

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        adminLevel: state.user.adminLevel || 0,
        tipsterLevel: state.user.tipsterLevel || 0,
        emailVerified: state.user.emailVerified || false,
        uid: state.user.uid,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, null)(AddAnalysisNavbar);