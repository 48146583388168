import React, { Component } from 'react';
import { connect } from "react-redux";
//import MappedSingleAnalyses from '../addAnalysis/MappedSingleAnalyses';
import { changeRouteValue } from "../../../store/actions/viewData";
import { balls } from '../../../config/sports';
import togglerIcon from '../../../assets/toggler.png';
import Img from 'react-image';
import userAvatar from '../../../assets/user-avatar.png';
import moment from 'moment';
import { countyFlags } from '../../../config/languages';

class MapActiveAnalyses extends Component {


    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }

    languagesInAnalysis = (languages) => {
        const languagesArr = [];
        for (let [key] of Object.entries(languages))
            languagesArr.push(key)
        if (!languages || !languagesArr.length) return <div></div>
        return languagesArr.map((elem) =>
            <Img
                key={elem}
                src={[countyFlags[elem], countyFlags["en"]]}
                className="ml-1"
                style={{
                    width: "2rem",
                    height: "auto"
                }}
            />
        )
    }


    getTipsterData = (a) => {
        const tipsterDataDefault = {
            yieldValue: 0,
            profileURL: "",
            p: 0, //profit
            name: "",
            photoURL: "empty"
        }

        const tipsterData = (
            this.props.statistics
            && this.props.statistics.find(
                tipster => tipster.key === a
            ))
            || tipsterDataDefault;

        return tipsterData;

    }

    listAnalyses = () => {

        const { tl = {} } = this.props;

        if (!this.props.analysesActiveArray.length) return <div className="c-whitish"> {tl.no_analyses_t} </div>
        const analysesComponents =
            this.props.analysesActiveArray.sort((a, b) => { return a.x - b.x });
        return analysesComponents.map((elem) => {
            const tipsterData = this.getTipsterData(elem.a);
            //   console.log("tipsterData", tipsterData)
            /*   const {
                   guestTeam,
                   hostTeam,
                   authorName,
                   sport,
                   startDate,
                   wdl = "active"
               } = elem;*/
            const guestTeam = elem.g;
            const hostTeam = elem.h;
            const authorName = elem.n;
            const sport = elem.s;
            const startDate = elem.x;
            const tip = elem.y;
            const odds = elem.o;
            //this.props.statistics
            /*
            const t: title,
             s: sport,
             x: startDateToUnix,
             d: startDateToUTC,
             v: year + month + day,
             w: year + month + day + hours,
             h: hostTeam,
             g: guestTeam,
             y: tip,
             o: odds,
             u :stake, //u as "ulog"
             k: bettingHouse, //k as " kladionica"
             l: [language],
             i: imageUrl,
             a: uid, //a as authorId
             n: displayName,
             f: FirestoreServerTimeStamp
 */

            let setAtDate = new Date(startDate * 1000)
            setAtDate = moment(setAtDate).format('DD/MM/YYYY, HH:mm');

            return <div
                key={elem.id || ""}
                id={"maped-analysis-" + elem.id}
                className="analyse-card-wrap"
                onClick={this.addNewRoute("/analysis/" + elem.id)}
            >


                <div className="an-card-header">
                    <div className="text-center d-flex justify-content-start">
                        {(tipsterData.profileURL === "not")
                            ? <div className="an-card-avatar"> </div>
                            : <Img
                                className="an-card-avatar"
                                src={[tipsterData.profileURL, userAvatar]}
                            />
                        }

                        <div className="analise-data-wrapper c-whitish text-center">
                            <span className="an-card-author">
                                {authorName}
                            </span>
                            <span className="an-card-yieldvalue">
                                {tl.yield_t}:   {tipsterData.yieldValue}%
                            </span>

                            <span className="an-card-profit">
                                {tl.profit_t}:   {tipsterData.p}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="an-card-body">
                    <div className="an-card-time"> {setAtDate || ""} </div>
                    <div className="card-match-teams">  {hostTeam} - {guestTeam}</div>
                    <div className="an-card-footer">

                        <div className="text-center d-flex justify-content-start">
                            {(/*photoURL === "not"*/ false)
                                ? <div className="analyse-card-sport"> </div>
                                : <Img
                                    src={balls[sport]}
                                    alt={sport}
                                    className="analyse-card-sport"
                                    decode={false}
                                />
                            }

                            <div className="analise-data-wrapper text-center">
                                <span className="an-card-tipods">
                                    {tl.tip_t}: {tip}
                                </span>
                                <span className="an-card-tipods">
                                    {tl.odds_t}: {odds}
                                </span>
                            </div>
                        </div>

                        <div className="an-card-languages">
                            {this.languagesInAnalysis(elem.l)}
                        </div>
                    </div>
                </div>
                <Img
                    src={[togglerIcon]}
                    alt={sport}
                    className="analyse-toggler"


                />
            </div>


        })
    }

    render() {
        return (
            <div className="mapped-active-analyses">
                {this.listAnalyses()}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        statistics: state.statistics,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, { changeRouteValue })(MapActiveAnalyses);