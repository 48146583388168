import React, { Component } from 'react';
import firebaseApp from '../../../config/fbConfig'
import { connect } from "react-redux"
import Input from '../../utils/inputs/Input';
import SingleUser from './SingleUser'
class ListUsersByEmail extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            lastFetchedTime: "",
            usersArray: [],
            isLoadingFirstLoad: true,
            isLoading: false,
            skipLimit: 2,
            emptyAjax: false,
            email: '',
            errorMessage: '',
            isFirstView: true,
        }
    }


    componentDidMount() {
        this._isMounted = true;
    }

    searchByEmail = () => {
        if (this.state.email === "") return;
        this.setState((prevState) => {
            return {
                isFirstView: !prevState.isFirstView,
                emptyAjax: false,
                usersArray: [],
                isLoadingFirstLoad: true,
                isLoading: false,
                lastFetchedTime: "",
                email: ""
            }
        })
        this.fetchUsers({ isFirstFetch: true });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.searchByEmail();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }


    fetchUsers = ({ isFirstFetch = false }) => {
        if ((
            !this.state.lastFetchedTime && !isFirstFetch
        ) || this.state.isLoading
        ) return;
        const { skipLimit } = this.state;
        this._isMounted && isFirstFetch && this.setState({ isLoadingFirstLoad: true });
        this._isMounted && this.setState({ isLoading: true });
        const startAfter = (isFirstFetch)
            ? ""
            : this.state.lastFetchedTime;

        const firestoreDB = firebaseApp.firestore();
        let lastFetchedTime = "";
        this._isMounted && firestoreDB
            .collection('users')
            .where('email', '==', this.state.email)
            .orderBy('createdAt', 'desc')
            .startAfter(startAfter)
            .limit(skipLimit)
            .get()
            .then((querySnapshot) => {
                const usersArray = [...this.state.usersArray]
                if (querySnapshot.size < skipLimit)
                    this._isMounted && this.setState({
                        emptyAjax: true
                    })
                querySnapshot.forEach((doc) => {
                    lastFetchedTime = doc.data().createdAt;
                    usersArray.push({ ...doc.data(), id: doc.id })
                });
                this._isMounted && this.setState({
                    lastFetchedTime,
                    usersArray,
                    isLoadingFirstLoad: false,
                    email: ""
                });
            })
            .catch((err) => {
                console.log("obavezna greska!", err)
                this._isMounted && isFirstFetch && this.setState({
                    isLoadingFirstLoad: false
                })
            })
    }



    componentDidUpdate(prevProps, prevState) {

        if (
            prevState.usersArray !== this.state.usersArray &&
            this.state.isLoading
        ) {
            this._isMounted && this.setState({
                isLoading: false
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        //      window.removeEventListener('scroll', this.handleScroll);
    }

    listUsers = () => {
        const { isLoadingFirstLoad, usersArray } = this.state;
        if (isLoadingFirstLoad) return <div> Loading </div>
        if (!usersArray.length)
            return <div> No users </div>
        const listedUsers =
            usersArray.map((userData) =>
                <SingleUser userData={userData} key={userData.id} />
            );
        return listedUsers;
    }


    render() {

        return (
            <div>
                <div className="p-1 mt-3">
                    <Input
                        type="email"
                        label="Enter email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        required={true}
                    />

                    <div className="container d-flex justify-content-center">
                        <button
                            className={(this.state.email === "")
                                ? "inactive-button"
                                : "success-button"
                            }
                            onClick={this.searchByEmail}
                        > Search</button>
                    </div>
                </div>

                {
                    (this.state.isFirstView)
                        ? this.listUsers()
                        : null
                }

                {
                    (!this.state.isFirstView)
                        ? this.listUsers()
                        : null
                }

                {
                    (this.state.emptyAjax || this.state.isLoadingFirstLoad)
                        ? <div className="c-whitish"> No more users </div>
                        : <button
                            className="success-button"
                            onClick={this.fetchUsers}
                        >   Load More </button>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        //  routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(ListUsersByEmail);