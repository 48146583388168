import firebase from "../../config/fbConfig";


export const archivedTicketAdd = (payload) => {
    return {
        type: "archivedTicket/ADD",
        payload
    }
}

export const archivedTicketRemove = () => {
    return {
        type: "archivedTicket/REMOVE"
    }
}




export const activeTicketsPut = (payload) => {
    return {
        type: "activeTickets/PUT",
        payload
    }
}

export const activeTicketsDelete = (payload) => {
    return {
        type: "activeTickets/DELETE",
        payload
    }
}
export const homeTicketPut =
    () => dispatch => {

        const homeTicketDbRef = firebase.database().ref('home-ticket');
       
        homeTicketDbRef.on('value',
            (snapshot) => {
                    if (snapshot.hasChildren()) {
                        dispatch({
                            type: 'homeTicket/PUT',
                            payload: { ...snapshot.val() }
                        })
                    } else {
                        dispatch({
                            type: 'homeTicket/REMOVE'
                        })
                    }
                });
    }
