import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import LinesEllipsis from 'react-lines-ellipsis';
 
const LinesEllipsisStyle = {
 
    letterSpacing: "normal",
    whiteSpace: "pre-wrap", //works the best
    overflowWrap: "break-word"  //works the best
}

class ListedSingleBlogDescription extends Component {

    state = {
        isFirstView: true
    }



    onResize = () => {
        this.setState((prevState) => {
            return { isFirstView: !prevState.isFirstView }
        });
    }


    render() {
        const { isFirstView } = this.state;
        const { description = "" } = this.props;
        return (
            <div className="card-text text-left">
                <ReactResizeDetector handleWidth onResize={this.onResize} />
                {
                    (isFirstView)
                        ? <LinesEllipsis
                            style={LinesEllipsisStyle}
                            text={description}
                            maxLine='2'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                        />
                        : null
                }
                {
                    (!isFirstView)
                        ? <LinesEllipsis
                            style={LinesEllipsisStyle}
                            text={description}
                            maxLine='2'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                        />
                        : null
                }

        

            </div>

        );
    }
}



export default ListedSingleBlogDescription;



