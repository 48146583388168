import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { firebaseAuth } from '../../../config/fbConfig';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
import axios from 'axios';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { archivedTicketRemove } from '../../../store/actions/tickets';
import moment from 'moment';

class DeleteTicket extends Component {

    state = {
        isOpenDelete: false
    }

    deleteBlog = async () => {
        const { tl = {} } = this.props;
        try {
            const { isArchived = true } = this.props;
            this.closeNotes()
            this.props.toggleLoader(true)
            const { ticketId } = this.props;
            const idToken = await firebaseAuth.currentUser.getIdToken(true);

            const axiosEndpoint = (isArchived) ? "ticket/remove-archive" : "ticket/delete-ticket";
            await axios.delete(
                axiosEndpoint, {
                data: {
                    idToken,
                    ticketId
                }
            })

            this.props.addSuccessNotesWithPayload({
                title: tl.success_t,
                body: tl.ticket_is_deleted
            })

            this.props.toggleLoader(false)

            if (isArchived) {
                this.props.archivedTicketRemove();
            }


        } catch (error) {
            this.closeNotes()
            this.props.addErrorNotesWithPayload({
                title: tl.ticket_is_not_deleted,
                body: tl.ops_something_went_wrong + tl.ticket_is_not_deleted + "."
            })
            this.props.toggleLoader(false)
        }
    }


    openNotes = () => {
        this.setState({ isOpenDelete: true });
    }
    closeNotes = () => {
        this.setState({ isOpenDelete: false });
    }

    tisperDeleteDeadLine = () => {
        const game = this.props.game;
        if (!game) return false;
        let firstStartDate = game
            .reduce((a, b) => { return a.x < b.x ? a : b; });
        let lastStartDate = game
            .reduce((a, b) => { return a.x > b.x ? a : b; });
        firstStartDate = moment(firstStartDate.x).valueOf();
        lastStartDate = moment(lastStartDate.x).valueOf();
        const timeDBstamp = Date.now();
        return (firstStartDate - timeDBstamp > 1200000) && (lastStartDate - timeDBstamp < 1000 * 3600 * 24 * 7);
    }

    render() {
        const {
            emailVerified,
            adminLevel,
            tipsterLevel,
            uid,
            authorId,
            isArchived = true,
            tl = {}
        } = this.props;

        const isAdmin = adminLevel >= 8;
        const isTipsterDeletable =
            this.tisperDeleteDeadLine()
            && (uid && uid === authorId)
            && !isArchived;
        const isTipster = (tipsterLevel && tipsterLevel >= 3 && isTipsterDeletable);
        const isTicketPageRoute = this.props.match && this.props.match.path === "/tickets";
        const { game } = this.props;
        // tipster Level i ako je njegov tiket
        return (
            <div>
                <ConfirmNoteOutput
                    notes={{
                        title: tl.delete_ticket_q,
                        body: tl.sure_to_delete_ticket_q,
                        closeNotesText: tl.cancel_t,
                        confirmNotesText: tl.yes_t
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.deleteBlog}
                    isOpen={this.state.isOpenDelete}
                />



                {
                    (isTicketPageRoute && game && emailVerified && (isTipster || isAdmin))
                        ? <button
                            onClick={this.openNotes}
                            className="success-button"> {tl.delete_t}
                          </button>
                        : null
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        displayName: state.user.displayName,
        adminLevel: state.user.adminLevel || 0,
        tipsterLevel: state.user.tipsterLevel || 0,
        emailVerified: state.user.emailVerified || false,
        tl: state.tl[state.tl.default]
    }
}

const DeleteTicketWithRouter = withRouter(DeleteTicket);

export default connect(
    mapStateToProps, {
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload,
    archivedTicketRemove
})(DeleteTicketWithRouter);