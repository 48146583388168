import React, { Component } from 'react';
import { categories } from '../../../config/categories';
import { connect } from "react-redux";

class BlogsCategory extends Component {


    state = {
        categories: categories
    }


    mapCategoryButtons = () => {
        return this.state.categories.map((elem) =>
            <button
                key={elem}
                onClick={this.props.selectCategory(elem)}
                className="tag-labels mr-1"
            > {elem}  </button >
        )
    }

    render() {

        return (
            <div>
                <h1 className="my-1"> Blogs </h1>
                <button
                    key="Clear"
                    onClick={this.props.selectCategory("")}
                    className="tag-labels mr-1"
                   
                > Clear All  </button>
                {this.mapCategoryButtons()}
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(BlogsCategory);