import React, { Component, Fragment } from 'react'
import CurrentTicket from './CurrentTicket';
//import CurrentAnalysis from './CurrentAnalysis';
import TipsterStatsAll from './TipsterStatsAll';
import TipsterStatsMonthly from './TipsterStatsMonthly';
import FakeTipsPlaceholders from './FakeTipsPlaceholders';
import { connect } from 'react-redux';


class Home extends Component {

  componentDidMount() {
    document.getElementById("top-navbar").scrollIntoView();
  }

  render() {
    return (
      <Fragment>
        <FakeTipsPlaceholders />
        <CurrentTicket />
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <TipsterStatsAll />
          </div>
          <div className="col-lg-6 col-md-12">
            <TipsterStatsMonthly />
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.user.uid
  }
}


export default connect(mapStateToProps, null)(Home);