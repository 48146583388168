import React, { Component, Fragment } from 'react'
import Statistics from './Statistics';
import LeftHomeBanner from './LeftHomeBanner';
import { connect } from 'react-redux';
import bImage from '../../../assets/heroNincha90.jpg';

class HomeBanner extends Component {

    render() {
        return (
            <Fragment>
                <div className="bg-home-image"
                style ={{
                    backgroundImage: `url(require(${bImage}))`
                }}
                >

                    <div className="row">
                        <div className="col-xl-6 col-lg-12 ">
                            <LeftHomeBanner />
                        </div>
                        <div className="col-xl-6 col-lg-12">
                            <Statistics />
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }
}
/*
const mapStateToProps = (state) => {
    return {
        uid: state.user.uid
    }
}
*/

export default connect(null, null)(HomeBanner);