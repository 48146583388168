const initialstate = {};

const homeTicketsReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "homeTicket/PUT":
            return { ...action.payload };

        case "homeTicket/REMOVE":
            return initialstate;

        default:
            return state
    }
}

export default homeTicketsReducer;