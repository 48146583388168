const initialstate = {
    routeValue: "/"
};

const viewDataReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "view/ROUTE_VALUE":
            return { ...state, routeValue: action.payload };

        default:
            return state
    }
}

export default viewDataReducer;