
// if someone messes with local storage
//maybe try to add unkown language and brakes the app
const languagesArray = ["en", "sr", "fr"]
const knownLanguage = localStorage.getItem("language");
const foundedLanguage = languagesArray.find(el => el === knownLanguage);
const languageFromLocal = foundedLanguage || "en";


const initialstate = {
    isRightSidebarOpen: false,
    languageTranslation: languageFromLocal,
    loader: false,
    isActiveAnalyisisFetched: false,
    isActiveAnalysisTab: true
};

const toggleReducer = (state = initialstate, action) => {
    switch (action.type) {

        // ***** Right sidebar *******
        case "toggle/TOGGLE_RIGHT_SIDEBAR":
            return { ...state, isRightSidebarOpen: !state.isRightSidebarOpen };

        case "toggle/CLOSE_RIGHT_SIDEBAR":
            return { ...state, isRightSidebarOpen: false };

        case "toggle/OPEN_RIGHT_SIDEBAR":
            return { ...state, isRightSidebarOpen: true };

        // ***** Language *******
        case "toggle/CHANGE_LANGUAGE":
            return { ...state, languageTranslation: action.payload };


        // ***** Loader ******
        case "toggle/LOADER":
            return { ...state, loader: action.payload };

        // ***** Active analysis are fetched from server ******
        case "toggle/ACTIVE_ANALYSYS_FETCHED":
            return { ...state, isActiveAnalyisisFetched: action.payload };

        // ******** Active analyses tab    **********
        case "toggle/IS_ACTIVE_ANALYSYS_TAB":
            return { ...state, isActiveAnalysisTab: action.payload };
        

        // ***** remove all when user signs out ******
        case "user/SIGNOUT_SUCCESS":
            return initialstate;

        default:
            return state
    }
}

export default toggleReducer;