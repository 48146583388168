import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import TextArea from '../inputs/TextArea';
import firebase from '../../../config/fbConfig';
import { withRouter } from "react-router";
import {
    //  addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { connect } from "react-redux";
class ReplyComment extends Component {

    constructor(props) {
        super(props);
        this.isClickable = true;
        this.state = {
            comment: '',
            isOpenTogle: false
        };
    }

    addComment = async () => {
        try {

            const { commentsRefId, authorId, feedAuthorId, commentsAuthorId = "" } = this.props;

            // blokirati da reply udaras samom sebi  authorId === commentsAuthorId 
            if (authorId === commentsAuthorId) return;

            let { comment } = this.state;
            if (!this.isClickable || !comment.trim().length) return;
            this.isClickable = false;

            const notesObject = {
                u: this.props.match.url,
                n: true,   //new comment
                c: comment,
                a: authorId,
                r: commentsAuthorId,  //id of the writter of the comment that will recive reply
                p: /* postedAt:*/ firebase.database.ServerValue.TIMESTAMP //Date.now()
            }


            const commentsDbRef = firebase.database().ref('blogs-comments/' + commentsRefId);

            const feedCommentsDbRef = firebase.database().ref('feed-comments/' + feedAuthorId)
            const commentsAuthorDbRef = firebase.database().ref('feed-comments/' + commentsAuthorId);
            const adminCommentsDbRef = firebase.database().ref('admin-comments');

            const commentsDbRefPromise = commentsDbRef.push({
                c: comment,
                a: authorId,
                r: commentsAuthorId,  //id of the writter of the comment that will recive reply
                p: /* postedAt:*/ firebase.database.ServerValue.TIMESTAMP //Date.now()
            });

            // ako reply udarim na svojoj analizi, nije mi potrebna notifikacija   authorId === feedAuthorId,
            // ako udarim reply nekome ko je i pisao analizu, njemu saljemo samo jednu notifikaciju feedAuthorId === commentsAuthorId 
            const commentsAuthorDbRefPromise = (/*(authorId !== feedAuthorId) ||*/ (feedAuthorId !== commentsAuthorId))
                ? commentsAuthorDbRef.push(notesObject)
                : new Promise((resolve, reject) => { resolve(true) });

            // ako reply udarim na svojoj analizi, nije mi potrebna notifikacija   authorId === feedAuthorId,
            const feedCommentsDbRefPromise =
                (authorId !== feedAuthorId)
                    ? feedCommentsDbRef.push(notesObject)
                    : new Promise((resolve, reject) => { resolve(true) });


            //adminima idu obavezno svi komentari
            const adminCommentsDbRefPromise = adminCommentsDbRef.push(notesObject)

            await Promise.all([
                commentsDbRefPromise,
                adminCommentsDbRefPromise,
                feedCommentsDbRefPromise,
                commentsAuthorDbRefPromise
            ]);

            this.setState({ comment: "", isOpenTogle: false });
            this.isClickable = true;
        } catch (error) {
            const { tl = {} } = this.props
            this.isClickable = true;
            this.props.addErrorNotesWithPayload({
                title: tl.comment_not_posted,
                body: tl.ops_something_went_wrong + tl.comment_not_saved
            })
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.addComment();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }

    toggleReplayCollapse = () => {
        this.setState((prevState) => {
            return {
                isOpenTogle: !prevState.isOpenTogle
            }
        })
    }


    isToggleButtonVisible = () => {
        return (this.props.authorId === this.props.commentsAuthorId)
            ? null
            : <div className="pb-5">
                <button
                    className="cancel-button float-right"
                    onClick={this.toggleReplayCollapse}
                >
                    {
                        (this.state.isOpenTogle)
                            ? "close"
                            : "reply"
                    }
                </button>
            </div>
    }

    render() {
        const { tl = {} } = this.props;

        return (

            <div className="c-whitish" style={{ position: "relative" }}>

                {this.isToggleButtonVisible()}

                <Collapse isOpen={this.state.isOpenTogle}>


                    <div className="add-comment-wrapper mt-5">
                        <TextArea
                            type="text"
                            label={tl.enter_comment_label}
                            name="comment"
                            value={this.state.comment}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            dataLength={500}
                            className="add-comment-textarea"
                        />
                        <button
                            className={
                                (this.state.comment.length)
                                    ? "success-button"
                                    : "inactive-button"
                            }
                            onClick={this.addComment}
                        >
                            {tl.send_t}
                        </button>
                    </div>

                </Collapse>

            </div>

        )
    }

}


const mapStateToProps = (state) => {
    return {
        uid: state.user.uid || "",
        emailVerified: state.user.emailVerified || false,
        tl: state.tl[state.tl.default]
    }
}

const ReplyCommentWithRouter = withRouter(ReplyComment);

export default connect(mapStateToProps, { addErrorNotesWithPayload })(ReplyCommentWithRouter);



