import React, { Component, Fragment } from 'react';
import { closeNotes } from '../../../store/actions/notes';
import { connect } from "react-redux";

class InfoNoteOutput extends Component {

    closeNotes = () => {
        this.props.closeNotes();
    }

    render() {
        const { notes,tl = {}} = this.props;
        const notesStatus = (notes.isSuccess) ? "main-notes-sucess" : "main-notes-failed";
        const buttonStatus = (notes.isSuccess) ? "success-button" : "error-button";
        return (
            <Fragment>
                {
                    (notes.body)
                        ? <div className={`notes-output`}>
                            <div className="main-notes-card">
                                <button
                                    className="close-notes-x"
                                    onClick={this.closeNotes}
                                >  {String.fromCharCode(215)}
                                </button>
                                <div className="main-notes-container">
                                    <div className={`main-notes-common ${notesStatus}`}> {notes.title || ""}   </div>
                                    <div className="main-notes-form-text">
                                        {notes.body || ""}
                                    </div>
                                    <div className="container d-flex justify-content-center">
                                        <button className={buttonStatus+ " px-5"} onClick={this.closeNotes}> {tl.ok_t} </button>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="transparent-notes-bg"></div>
                        </div>
                        : null
                }

            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notes: state.notes,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, { closeNotes })(InfoNoteOutput);
