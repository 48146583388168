import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { firebaseAuth } from '../../../config/fbConfig';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
import axios from 'axios';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';

class AddToArchiveTicket extends Component {

    state = {
        isOpen: false
    }

    addToArchive = async () => {
        const { tl ={} } = this.props;
        try {
            this.closeNotes()
            this.props.toggleLoader(true)
            const { ticketId } = this.props;
            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            await axios.post("ticket/add-archive", {
                idToken,
                ticketId
            })

            this.props.addSuccessNotesWithPayload({
                title: tl.success_t,
                body: tl.ticket_is_moved_in_archive
            })

            this.props.toggleLoader(false)


        } catch (error) {
            this.closeNotes()
            this.props.addErrorNotesWithPayload({
                title: tl.ticket_is_not_moved,
                body: tl.ops_something_went_wrong + tl.ticket_is_not_moved_in_archive
            })
            this.props.toggleLoader(false)
        }
    }

    openNotes = () => {
        this.setState({ isOpen: true });
    }
    closeNotes = () => {
        this.setState({ isOpen: false });
    }

    render() {
        const { emailVerified, adminLevel, tl } = this.props;
        const isTicketPageRoute = this.props.match.path === "/tickets";
        const isAdmin = adminLevel >= 20;
        return (
            <div>
                <ConfirmNoteOutput
                    notes={{
                        title: tl.move_ticket_to_archive_q,
                        body: tl.want_to_move_ticket_in_archive_q,
                        closeNotesText: tl.cancel_t,
                        confirmNotesText: tl.yes_t
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.addToArchive}
                    isOpen={this.state.isOpen}
                />

                {
                    (isTicketPageRoute && emailVerified && isAdmin)
                        ? <button
                            onClick={this.openNotes}
                            className="success-button"> {tl.move_to_archive_t}
                          </button>
                        : null
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        adminLevel: state.user.adminLevel || 0,
        emailVerified: state.user.emailVerified || false,
        tl: state.tl[state.tl.default]
    }
}

const AddToArchiveTicketWithRouter = withRouter(AddToArchiveTicket);

export default connect(
    mapStateToProps, {
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
})(AddToArchiveTicketWithRouter);