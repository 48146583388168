import React, { Component } from 'react';
import { connect } from "react-redux"
//import TipsterTable from '../../utils/tipsterTable/TipsterTable';
import Img from 'react-image';
import userAvatar from '../../../assets/user-avatar.png';

class TipsterStatsMonthly extends Component {

    mapTipsters = () => {

        if (this.props.statistics.length) {
            //   console.log("this.props.statistics", this.props.statistics)
            // yieldValue
            //   const statistics = this.props.statistics.sort((b, a) => a.p - b.p);
            const statistics = this.props.statistics.sort((b, a) => a.yieldValue - b.yieldValue);
            return statistics.map((tipster, i) => {
                const w = tipster.w || 0;
                const d = tipster.d || 0;
                const l = tipster.l || 0;
                const yieldValue = tipster.yieldValue || 0;
                const n = tipster.n || 0;

                const tipsterStatsAll = this.props.statsAll
                    .find(tipsterData => tipsterData.key === tipster.key) || false;

                const profileURL = (tipsterStatsAll && tipsterStatsAll.profileURL) || "";
                if (i > 4) return null;
                else
                    return <tr
                        key={tipster.key}
                        className="ticket-row-border"
                    >
                        <td className="chart-ranking-text text-center mt-2-5">
                            {i + 1}
                        </td>

                        <td className="table-tipster-avatar-name">
                            <Img
                                src={[profileURL, userAvatar]}
                                alt={n}
                                className="tipster-avatar"
                                decode={false}
                            />
                            <span> {n} </span>
                        </td>

                        <td className="head-t-text text-center">
                            {w}-{d}-{l}
                        </td>

                        <td className="head-t-text text-center">
                            {yieldValue}
                        </td>
                    </tr>

            })

        }

    }


    render() {
        //     const { statistics } = this.props;
        // console.log("statistics props", this.props.statistics)
        const { tl = {} } = this.props;
        return (
            <div>
                <div className="table-tipster-title">
                    {tl.best_of_month_by_yield}
                </div>
                <table style={{ borderRadius: "0.25rem" }}>

                    <thead>
                        <tr>
                            <th scope="col" className="text-center chart-ranking-text"></th>
                            <th scope="col" className="tipster-table-h-name-media" >Tipster</th>
                            <th scope="col" className="text-center">WDL</th>
                            <th scope="col" className="text-center">Yield</th>
                        </tr>
                    </thead>

                    <tbody>

                        {this.mapTipsters()}


                    </tbody>
                </table>
            </div>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue,
        statistics: state.monthlyStatistics,
        statsAll: state.statistics,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, null
)(TipsterStatsMonthly);