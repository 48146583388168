import React, { Component } from 'react';
import eyeSlash from '../../../assets/eye-slash-regular.svg';
import eye from '../../../assets/eye-regular.svg';
import Img from 'react-image'
class TextArea extends Component {

    state = {
        typeViewIsVisible: false,
        value: "",
        visibleLabel: true
    }


    toggleTypeViewLocalFunction = () => {
        this.setState((prevState) => {
            return {
                typeViewIsVisible: !prevState.typeViewIsVisible
            }
        })
    }

    render() {
        const { typeViewIsVisible } = this.state;
        const {
            typeToggle = false,
            label = "",
            name = "",
            onChange = () => { },
            onKeyPress = () => { },
            className = "",
            required = false,
            value = "",
            dataLength = -1

        } = this.props;

        let {
            type = "text"
        } = this.props;


        if (typeToggle) {
            type = (typeViewIsVisible || this.state.value) ? "text" : "password"
        }

        let labelColor = null;
        if (dataLength !== -1 && value.length > dataLength) {
            labelColor = "#e93b70";
        }

        return (

            <div className={`custom-input-box ${className}`}>
                <div className="inputBox">
                    <textarea

                        style={{
                            opacity: (this.state.value) ? "0" : "1",
                            minHeight: "3rem",
                            position: "relative",
                            top: "2px"
                        }}

                        type={type}
                        name={name}
                        rows="1"
                        value={value + this.state.value}
                        required={required}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        autoComplete="new-password"
                    />

                    {(typeToggle) ?
                        <Img
                            onClick={this.toggleTypeViewLocalFunction}
                            className="eye-regular"
                            src={(typeViewIsVisible) ? eye : eyeSlash}
                            alt="eye"
                            decode={false}
                        />
                        : null

                    }

                    <label
                        className={(value || this.state.value)
                            ? "custom-label-up"
                            : ""
                        }

                        style={{
                            opacity: (this.state.visibleLabel) ? "1" : "0",
                            color: labelColor,

                        }}

                    >
                        {label}
                    </label>


                    {(dataLength !== -1)
                        ? <span className={(value.length <= dataLength) ? "c-whitish" : "c-pink"}>
                            {value.length}/{dataLength}
                        </span>
                        : null
                    }
                </div>
            </div>
        )
    }

}

export default TextArea;


