import React, { Component, Fragment } from 'react'
import Ticket from '../../utils/ticket/Ticket';
import { connect } from 'react-redux';
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
class CurrentTicket extends Component {

    addNewRoute = (payload) => () => {
      //  console.log("this.props.history", this.props.history)
        if (!this.props.uid) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload);
    }


    render() {
        const { homeTicket } = this.props;
        return (
            <Fragment>
                {
                    (homeTicket && homeTicket.r)
                        ? <Fragment><Ticket
                            {...this.props.homeTicket}
                            isArchived={false}
                        />

                            {/* <div className="row">
                                <div className="col-xl-12 text-right padding-button-tickets">
                                    <button className="button-tickets"
                                        onClick={this.addNewRoute('/tickets')}
                                    >
                                        Pogledajte ostale tikete Resi problem za nelogovane
                                        </button>
                                </div>
                </div>*/}

                        </Fragment>
                        : null
                }

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        homeTicket: state.homeTicket
    }
}

const CurrentTicketWithRouter = withRouter(CurrentTicket);
export default connect(mapStateToProps, {
    changeRouteValue
})(CurrentTicketWithRouter);