import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers/index.js';


const store = (process.env.NODE_ENV === 'production')

    ? createStore(
        rootReducer,
        {},
        compose(applyMiddleware(thunk)))

    : createStore(
        rootReducer,
        {},
        compose(
            applyMiddleware(thunk),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
        ))

export default store;