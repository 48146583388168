import React, { Component } from 'react';
import { connect } from "react-redux"
import { sports } from '../../../config/sports';
import { bettingHouses } from '../../../config/bettingHouses';
import DateAndTime from '../../utils/dateAndTime/DateAndTime';
import Input from '../../utils/inputs/Input';
import upArrow from '../../../assets/arrows/up.svg';
import Img from 'react-image';

class AddAnalysisValues extends Component {

    state = {
        sports: sports,
        bettingHouses: bettingHouses
    }

    mapSports = () => {
        return this.state.sports.map(elem =>
            <option
                key={elem}
                value={elem}
                defaultValue={elem === sports[0]}
            >{elem}</option>
        )
    }

    mapBettingHouses = () => {
        return this.state.bettingHouses.map(elem =>
            <option
                key={elem}
                value={elem}
                defaultValue={elem === bettingHouses[0]}
            >{elem}</option>
        )
    }

    render() {
        const {
            title = "",
            imageUrl = "",
            language = "en",
            sport = sports[0],
            startDate = "",
            hostTeam = "",
            guestTeam = "",
            tip = "",
            odds = "",
            stake = "",
            bettingHouse = bettingHouses[0],
            tl = {},
            //   authorId = "",
            //     authorName = ""
        } = this.props;

        return (
            <div id="add-analysis-values">
                {/*
                    (authorId && authorName)
                        ? <div className=" c-whitish">
                            vidi samo admin itd...
                            authorid za link {authorId} autor analize {authorName}
                        </div>
                        : null

                */}

                <div className="form-row mt-1">
                    <div className="form-group col-sm-6">
                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_host_team}
                            id="inputHostTeam"
                            label={tl.enter_host_team}
                            name="hostTeam"
                            value={hostTeam}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                            dataLength={17}
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_guest_team}
                            id="inputGuestTeam"
                            label={tl.enter_guest_team}
                            name="guestTeam"
                            value={guestTeam}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                            dataLength={17}
                        />
                    </div>
                </div>
                <div className="form-row">


                    <div className="form-group col-sm-3">
                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_tip}
                            id="inputTip"
                            label={tl.enter_tip}
                            name="tip"
                            value={tip}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                            dataLength={20}
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_title}
                            id="inputAnalysisTitle"
                            label={tl.enter_title}
                            name="title"
                            value={title}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                            dataLength={60}
                        />
                    </div>


                    <div className="form-group col-sm-3">
                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_img_url}
                            id="inputAddress2"
                            label={tl.enter_img_url}
                            name="imageUrl"
                            value={imageUrl}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                        />
                    </div>

                </div>



                <div className="form-row">
                    <div className="form-group col-sm-4">

                        <DateAndTime
                            startDate={startDate}
                            handleDatePickerChange={this.props.handleDatePickerChange}
                        />

                    </div>



                    <div className="form-group col-sm-3 pr-1">

                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_odds}
                            id="inputOdds"
                            label={tl.enter_odds}
                            name="odds"
                            value={odds}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                        />

                    </div>

                    <div className="form-group col-sm-5 px-5">


                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_stakes}
                            id="inputStake"
                            label={tl.enter_stakes}
                            name="stake"
                            value={stake}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                        />

                        <Img
                            className="upInputStake"
                            src={upArrow} alt="up"
                            onClick={() => this.props.handleStakeBtnChange("up")}
                            decode={false}
                        />
                        <Img
                            className="downInputStake"
                            src={upArrow} alt="down"
                            onClick={() => this.props.handleStakeBtnChange("down")}
                            decode={false}
                        />

                    </div>
                </div>


                <div className="form-row">

                    <div className="form-group col-sm-4">

                        <label
                            htmlFor="inputLanguage"
                            className="c-label-dropdown"
                        >{tl.language_t}</label>
                        <select
                            id="inputLanguage"
                            className="select-custom-form"
                            name="language"
                            value={language}
                            onChange={this.props.handleMetaChange}
                        >
                            <option defaultValue value={"en"} >English</option>
                            <option value={"sr"}>Srpski</option>
                            <option value={"fr"}>France</option>
                        </select>


                    </div>


                    <div className="form-group col-sm-4">
                        <label htmlFor="inputSport"
                            className="c-label-dropdown"
                        >
                            {tl.sport_t}
                            </label>
                        <select
                            id="inputSport"
                            className="select-custom-form"
                            name="sport"
                            value={sport}
                            onChange={this.props.handleMetaChange}
                        >
                            {this.mapSports()}
                        </select>
                    </div>


                    <div className="form-group col-sm-4">
                        <label htmlFor="inputBettingHouse"
                            className="c-label-dropdown"
                        >
                            {tl.betting_house_t}
                        </label>
                        <select
                            id="inputBettingHouse"
                            className="select-custom-form"
                            name="bettingHouse"
                            value={bettingHouse}
                            onChange={this.props.handleMetaChange}
                        >
                            {this.mapBettingHouses()}
                        </select>
                    </div>

                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, null)(AddAnalysisValues);