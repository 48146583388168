import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import 'bootswatch/dist/slate/bootstrap.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import store from './store/store';
import { Provider } from 'react-redux'

import authChangeState from './components/utils/auth/authChangeState';

authChangeState();
// state is ready here
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'));




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
