import React, { Component } from 'react';
import firebaseApp from '../../../config/fbConfig'
import { connect } from "react-redux"
import SingleUser from './SingleUser'

class ListUsers extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            lastFetchedTime: "",
            usersArray: [],
            isLoadingFirstLoad: true,
            isLoading: false,
            skipLimit: 8,
            emptyAjax: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        // window.addEventListener('scroll', this.handleScroll, true);
        this.fetchUsers({ isFirstFetch: true });
    }

    fetchUsers = ({ isFirstFetch = false }) => {
        if ((
            !this.state.lastFetchedTime && !isFirstFetch
        ) || this.state.isLoading
        ) return;
        const { skipLimit } = this.state;
        this._isMounted && isFirstFetch && this.setState({ isLoadingFirstLoad: true });
        this._isMounted && this.setState({ isLoading: true });
        const startAfter = (isFirstFetch)
            ? ""
            : this.state.lastFetchedTime;

        const firestoreDB = firebaseApp.firestore();
        let lastFetchedTime = "";
        this._isMounted && firestoreDB
            .collection('users')
            .orderBy('createdAt', 'desc')
            .startAfter(startAfter)
            .limit(skipLimit)
            .get()
            .then((querySnapshot) => {
                const usersArray = [...this.state.usersArray]
                if (querySnapshot.size < skipLimit)
                    this._isMounted && this.setState({
                        emptyAjax: true
                    })
                querySnapshot.forEach((doc) => {
                    lastFetchedTime = doc.data().createdAt;
                    usersArray.push({ ...doc.data(), id: doc.id })
                });
                this._isMounted && this.setState({
                    lastFetchedTime,
                    usersArray,
                    isLoadingFirstLoad: false
                });
            })
            .catch(() => this._isMounted && isFirstFetch && this.setState({
                isLoadingFirstLoad: false
            }))
    }

    componentDidUpdate(prevProps, prevState) {

        if (
            prevState.usersArray !== this.state.usersArray &&
            this.state.isLoading
        ) {
            this._isMounted && this.setState({
                isLoading: false
            });
        }
    }


    /* handleScroll = (e) => {
        
         if (!this.state.isLoading && this.state.usersArray[0]) {
             const lastDiv =
                 document.getElementById(
                     this.state.usersArray[this.state.usersArray.length - 1]._id
                 );
             const lastDivOffset = lastDiv.offsetTop + lastDiv.clientHeight;
             const pageOffset = window.pageYOffset + window.innerHeight;
             const bottomOffset = 20;
             console.log("lastDiv", lastDiv)
             if (
                 pageOffset > lastDivOffset - bottomOffset
             ) {
                 this.fetchUsers({ isFirstFetch: false });
             }
         }
     }*/


    componentWillUnmount() {
        this._isMounted = false;
        //      window.removeEventListener('scroll', this.handleScroll);
    }


    listUsers = () => {
        const { isLoadingFirstLoad, usersArray } = this.state;
        if (isLoadingFirstLoad) return <div> Loading </div>
        if (!usersArray.length)
            return <div> No users </div>
        const listedUsers =
            usersArray.map((userData) =>
                <SingleUser userData={userData} key={userData.id} />
            );
        return listedUsers;
    }

    render() {
        return (
            <div>
                <h3> Users </h3>

                {this.listUsers()}
                {
                    (this.state.emptyAjax)
                        ? <div className="c-whitish"> No more users </div>
                        : <button
                            className="success-button"
                            onClick={this.fetchUsers}
                        >   Load More </button>
                }
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(ListUsers);