import React, { Component } from 'react';

class BannerSavedMessage extends Component {

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    render() {
        return (

            <div className="main-notes-top-card">

                <div className="main-notes-container">
                    <div className="main-notes-common main-notes-sucess"> Success  </div>
                    <div className="main-notes-form-text"> Banners are saved. </div>
                    <button
                        className="success-button"
                        onClick={this.props.confirmBannersAreSaved}
                    > Ok </button>
                </div>
            </div>
        )
    }

}

export default BannerSavedMessage;
