export const analysesAdd = (payload) => {
    return {
        type: "analyses/ADD",
        payload
    }
}

export const analysesRemove = () => {
    return {
        type: "analyses/REMOVE"
    }
}

export const activeAnalysesPut = (payload) => {
    return {
        type: "activeAnalyses/PUT",
        payload
    }
}

export const activeAnalysesReplace = (payload) => {
    return {
        type: "activeAnalyses/REPLACE",
        payload
    }
}

export const activeAnalysesRemove = (payload) => {
    return {
        type: "activeAnalyses/REMOVE",
        payload
    }
}
