import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import JoditApp from '../../utils/editor/JoditApp';
import userAvatar from '../../../assets/user-avatar.png';
import noPhotos from '../../../assets/no-photos.png';
import Img from 'react-image';
import { balls } from '../../../config/sports';
import { changeRouteValue } from '../../../store/actions/viewData';
import { bhousesLogos, bhousesLinks } from '../../../config/bettingHouses';
import { countyFlags } from '../../../config/languages';
import analiseRight from '../../../assets/analise-right.png'
import moment from 'moment';
class MappedSingleAnalyses extends Component {

    state = {
        photoURL: ""
    }

    languagesInAnalysis = (languages) => {
        const isActive = this.props.match.path.substring(0, 13) === "/analysis/:id";

        let languagesArr = [];
        if (isActive) {
            for (let [key] of Object.entries(languages))
                languagesArr.push(key)
        } else {
            languagesArr = [...languages]
        }

        if (!languages || !languagesArr.length) return <div></div>
        return languagesArr.map((elem) =>
            <Img
                key={elem}
                src={[countyFlags[elem], countyFlags["en"]]}
                className="analyse-avatar"
                style={{
                    margin: "0 0.15rem 0 0.15rem",
                    paddingTop: 0,
                    width: "1rem",
                    height: "auto"
                }}
            />
        )
    }



    changeRouteValue = () => {
        this.props.changeRouteValue("/analyses");
        this.props.history.push("/analyses");
    }



    imgAnalysis = (imageUrl, noPhotos) => {
        return <Fragment>  {(imageUrl === "not")
            ? <div className="image-fit"> </div>
            : <Img
                src={[imageUrl, noPhotos]}
                alt="match"
                className="image-fit analise-image"
                decode={false}

            />
        }   </Fragment>
    }


    render() {

        const {
            imageUrl = "not",
            title,
            tip,
            sport,
            odds,
            hostTeam,
            guestTeam,
            stake,
            bettingHouse,
            name,
            startDate,
            photoURL = "not",
            authorsGeneralProfit,
            wdl,
            languages,
            setAt = "",
            tl = {}
        } = this.props;

        let { content } = this.props;
        if (content === "")
            content = "The analysis is not available in this language. Change Language in order to see the content of this analysis";
        let { authorsGeneralYield } = this.props;
        if (authorsGeneralYield > 0) authorsGeneralYield = "+" + authorsGeneralYield;
        const setAtDate = moment(startDate).format('DD/MM/YYYY, HH:mm');
        const wdlBorder = (wdl === "active") ? "" : "an-" + wdl;

        return (
            <div className={"analyse-card-border " + wdlBorder}>
                <button
                    className="close-analises-x"
                    onClick={this.changeRouteValue}
                >  {String.fromCharCode(215)}
                </button>

                <div className="container-fluid">
                    <div className="row">

                        <div className="col-xl-3 col-lg-5 col-md-5 col-sm-4 col-12">
                            <div className="analise-image-desktop-visible" >
                                {this.imgAnalysis(imageUrl, noPhotos)}
                            </div>


                            <div className="text-center d-flex justify-content-start">
                                {(photoURL === "not")
                                    ? <div className="analyse-avatar"> </div>
                                    : <Img
                                        className="analyse-avatar mb-1 mr-0"
                                        src={[photoURL, userAvatar]}
                                        decode={false}
                                    />
                                }

                                <div className="analysis-profile-stats c-whitish text-center">
                                    <span className="analysis-profile-name">
                                        {name}
                                    </span>
                                    <span className="analysis-profile-yield-profit">
                                        {tl.yield_t}:   {authorsGeneralYield}% | {tl.profit_t}:   {authorsGeneralProfit}
                                    </span>
                                </div>
                            </div>
                            <div className="analise-image-desktop-mobile" >
                                {this.imgAnalysis(imageUrl, noPhotos)}
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-7 col-md-7 col-sm-8 col-12">
                            <div
                                className="analysis-match-info"
                            >
                                <div className="text-center d-flex justify-content-start"
                                    style={{ position: "relative", width: "100%" }}
                                >
                                    <Img
                                        className="analyse-avatar"
                                        src={[balls[sport]]}
                                        alt={sport}
                                        decode={false}
                                    />
                                    <div className="analise-data-wrapper c-whitish text-center"
                                        style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        <span className="analysis-match-date">
                                            {setAtDate}
                                        </span>
                                        <div className="analysis-match-teams"
                                        >
                                            {hostTeam} - {guestTeam}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="d-flex justify-content-between">
                                <h2 className="c-whitish ml-2 mt-2 font-weight-bold"> {title} </h2>
                                <div className="analysis-wrapper-flag">  {this.languagesInAnalysis(languages)} </div>
                            </div>

                            <JoditApp
                                canWrite={false}
                                content={content}
                                updateContent={this.props.updateContent}
                            />

                            <div className="float-right">
                                <div className="analysis-wrap-tip-stake-odds ">
                                    <span> {tl.tip_t}: {tip} </span>
                                    <span> {tl.odds_t}: {odds} </span>
                                    <span> {tl.stake_t}: {stake} </span>
                                </div>
                            </div>

                            <div className="mt-4 justify-content-between">
                                <div className="ml-2">
                                    <a href={bhousesLinks[bettingHouse]} rel="noopener noreferrer" target="_blank">
                                        <Img
                                            className="analysis-bhouse-image mr-2"
                                            src={bhousesLogos[bettingHouse]}
                                            alt={bettingHouse}
                                            decode={false}
                                        />
                                    </a>
                                </div>


                                <div className="analysis-disclamer">
                                    <span> {tl.published_at_t}: {setAt} </span> <br />
                                    <span> {tl.tips_your_own_risk} </span> <br />
                                    <span> {tl.tips_not_safe} </span>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                            <Img
                                src={[analiseRight]}
                                alt="an-right"
                                className="analyse-img-right"
                                decode={false}
                            />
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}


export default connect(mapStateToProps, { changeRouteValue })(MappedSingleAnalyses);

