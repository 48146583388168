import React, { Component } from 'react';
import UpdateEmail from './UpdateEmail';
import UpdateName from './UpdateName';
import VerifyEmail from './VerifyEmail';
import UpdatePassword from './UpdatePassword';
import ProfileInfo from './ProfileInfo';
import AvatarUploadClass from './UploadAvatar';
import { connect } from 'react-redux';
class ProfileSettings extends Component {


    state = {
        tabValue: 0
    }

    componentDidMount() {

        const newTabVal = (this.props.emailVerified)
            ? 4
            : 2;

        document.getElementById("top-navbar").scrollIntoView();
        this.setState({ tabValue: newTabVal })
    }

    toggleView = (elem) => () => {

        this.setState({ tabValue: elem })
    }

    outputView = () => {
        switch (this.state.tabValue) {
            case 1:
                return <UpdateEmail />
            case 2:
                return <VerifyEmail />
            case 3:
                return <UpdateName />
            case 4:
                return <UpdatePassword />
            case 5:
                return <AvatarUploadClass />
            default:
                return <div></div>
        }
    }

    render() {
        const { tabValue } = this.state;
        return (
            <div>
                <ProfileInfo />
                <div className="my-4">



                    {
                        /* 
                        
                        
                        <button
                                            onClick={this.toggleView(1)}
                                            className={` 
                                                ${(tabValue === 1)
                                                    ? "inactive-button"
                                                    : "cancel-button"}`
                                            }
                                        >Update Email</button>
                        
                        */
                    }



                    {
                        (this.props.emailVerified)
                            ? null
                            : <button
                                className={` 
                            ${(tabValue === 2)
                                        ? "inactive-button"
                                        : "cancel-button"}`
                                }
                                onClick={this.toggleView(2)}
                            >Verify Email</button>
                    }

                    {/*
                        (this.props.emailVerified)
                            ? <button
                                className={` 
                            ${(tabValue === 3)
                                        ? "inactive-button"
                                        : "cancel-button"}`
                                }
                                onClick={this.toggleView(3)}
                            >Update Name</button>
                            : null
                            */}

                    {
                        (this.props.emailVerified)
                            ? <button
                                className={` 
                            ${(tabValue === 4)
                                        ? "inactive-button"
                                        : "cancel-button"}`
                                }
                                onClick={this.toggleView(4)}
                            >Update Password</button>
                            : null
                    }




                    {
                        (this.props.emailVerified)
                            ? <button
                                className={` 
                            ${(tabValue === 5)
                                        ? "inactive-button"
                                        : "cancel-button"}`
                                }
                                onClick={this.toggleView(5)}
                            >Upload Avatar</button>
                            : null
                    }

                </div>
                {this.outputView()}
            </div>

        )
    }

}

const mapStateToProps = (state) => {
    return {
        emailVerified: state.user.emailVerified
    }
}

export default connect(mapStateToProps, null)(ProfileSettings);
