import React, { Component } from 'react';
import { connect } from "react-redux";
//import MappedSingleAnalyses from '../addAnalysis/MappedSingleAnalyses';
import { changeRouteValue } from "../../../store/actions/viewData";
import { balls } from '../../../config/sports';

import togglerIcon from '../../../assets/toggler.png';
import { countyFlags } from '../../../config/languages';
import userAvatar from '../../../assets/user-avatar.png';
import Img from 'react-image';
import moment from 'moment'

class MapAnalyses extends Component {


    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }


    languagesInAnalysis = (languages) => {
        if (!languages.length) return <div></div>
        return languages.map((elem) =>
            <Img
                key={elem}
                src={[countyFlags[elem], countyFlags["en"]]}
                className="ml-1"
                style={{
                    width: "2rem",
                    height: "auto"
                }}
            />
        )
    }

    getTipsterData = (a) => {
        const tipsterDataDefault = {
            yieldValue: 0,
            profileURL: "",
            p: 0, //profit
            name: "",
            photoURL: "empty"
        }

        const tipsterData = (
            this.props.statistics
            && this.props.statistics.find(
                tipster => tipster.key === a
            ))
            || tipsterDataDefault;

        return tipsterData;

    }

    listAnalyses = () => {
        const { tl = {} } = this.props;
        if (!this.props.analysesData.length) return <div className="c-whitish"> {tl.no_analyses_t}  </div>
        const isAnalysesActive = (this.props.isAnalysesActive)
            ? '/analysis/'
            : '/analysis-archive/';

        //analysesActiveArray
        const analysesArray = (this.props.isAnalysesActive)
            ? this.props.analysesActiveArray
            : this.props.analysesData;

        return analysesArray.map((elem) => {
            const tipsterData = this.getTipsterData(elem.authorId);
            const {
                guestTeam,
                hostTeam,
                sport,
                startDateToUnix,
                odds,
                tip
            } = elem;

            let { authorName } = elem;
            if (tipsterData.n)
                authorName = tipsterData.n;
            const setAtDate = moment(startDateToUnix * 1000).format('DD/MM/YYYY, HH:mm');
            return <div
                key={elem.id}
                className={"analyse-card-wrap an-" + elem.wdl}
                id={"maped-analysis-" + elem.id}
                onClick={this.addNewRoute(isAnalysesActive + elem.id)}
            >

                <div className="an-card-header">
                    <div className="text-center d-flex justify-content-start">
                        {(tipsterData.profileURL === "not")
                            ? <div className="an-card-avatar"> </div>
                            : <Img
                                className="an-card-avatar"
                                src={[tipsterData.profileURL, userAvatar]}
                            />
                        }

                        <div className="analise-data-wrapper c-whitish text-center">
                            <span className="an-card-author">
                                {authorName}
                            </span>
                            <span className="an-card-yieldvalue">
                                {tl.yield_t}:   {tipsterData.yieldValue}%
                            </span>

                            <span className="an-card-profit">
                                {tl.profit_t}:   {tipsterData.p}
                            </span>

                        </div>
                    </div>
                </div>
                <div className="an-card-body">
                    <div className="an-card-time"> {setAtDate || ""} </div>
                    <div className="card-match-teams">  {hostTeam} - {guestTeam}</div>
                    <div className="an-card-footer">

                        <div className="text-center d-flex justify-content-start">
                            {(/*photoURL === "not"*/ false)
                                ? <div className="analyse-card-sport"> </div>
                                : <Img
                                    src={[balls[sport]]}
                                    alt={sport}
                                    className="analyse-card-sport"
                                    decode={false}
                                />
                            }

                            <div className="analise-data-wrapper text-center">
                                <span className="an-card-tipods">
                                    {tl.tip_t}: {tip}
                                </span>
                                <span className="an-card-tipods">
                                    {tl.odds_t}: {odds}
                                </span>
                            </div>
                        </div>

                        <div className="an-card-languages">
                            {this.languagesInAnalysis(elem.languages)}
                        </div>
                    </div>
                </div>
                <Img
                    src={[togglerIcon]}
                    alt={sport}
                    className="analyse-toggler"
                />
            </div>
        })
    }

    render() {
        return (
            <div className="mapped-active-analyses">
                {this.listAnalyses()}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        statistics: state.statistics,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, {
    changeRouteValue
})(MapAnalyses);