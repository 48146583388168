import React, { Component } from 'react';
import { firestoreDB } from "../../config/fbConfig";
import { connect } from "react-redux";
import { changeRouteValue } from '../../store/actions/viewData';
class RouteActiveAnalysis extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;

    }

    componentDidMount() {
        this._isMounted = true;
        const analysisId = this.props.match.params.id;
        setTimeout(
            async () => {

                try {
                    if (!this._isMounted) return;
                    const analysisDoc = await firestoreDB
                        .collection('analysis-archive')
                        .doc(analysisId)
                        .get();
                    if (analysisDoc.exists) {
                        this.props.changeRouteValue("/analysis-archive/" + analysisId);
                        this.props.history.replace("/analysis-archive/" + analysisId)
                    }
                }
                catch (error) {

                }

            },
            3000
        );

    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return (
            <div className="c-whitish">
                <h2>   Checking verification...  </h2>
                If you are not being redirected to analysis for a longer period,
                it means that you are not logged in or verified user.
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isActiveAnalyisisFetched: state.toggle.isActiveAnalyisisFetched,
        uid: state.user.uid,
        activeAnalyses: state.activeAnalyses,
        emailVerified: state.user.emailVerified || false
    }
}

export default connect(mapStateToProps, { changeRouteValue })(RouteActiveAnalysis);
