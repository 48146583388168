import React, { Component } from 'react';
import LeftSideBarAuthItems from './LeftSideBarAuthItems';
import LeftSidebarRegularItems from './LeftSideBarRegularItems';
import ModeratorSideBarItems from './ModeratorSideBarItems';

class LeftSideBar extends Component {

    render() {
        return (
            <div className="left-sidebar-wrapper">
                
                <LeftSidebarRegularItems />
                <LeftSideBarAuthItems />
                <ModeratorSideBarItems />
            </div>
        )
    }

}

export default LeftSideBar;
