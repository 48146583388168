import React, { Component } from 'react';
import BlogsCategory from './BlogsCategory';
import { connect } from "react-redux"
import ListBlogs from './ListBlogs';
import { toggleLoader } from '../../../store/actions/toggle';

class Blogs extends Component {

    state = {
        selectedCategory: "",
        isFirstRender: true
    }


    componentDidMount() {
        this.props.toggleLoader(true)
        document.getElementById("top-navbar").scrollIntoView();
    }

    selectCategory = (category) => () => {
        if (category === this.state.selectedCategory) return;
        this.setState(
            (prevState) => {
                return {
                    selectedCategory: category,
                    isFirstRender: !prevState.isFirstRender
                }
            });
        this.setState({ selectedCategory: category })
    }

    render() {
        return (
            <div>
                <BlogsCategory selectCategory={this.selectCategory} />
                {
                    (this.state.isFirstRender)
                        ? <ListBlogs selectedCategory={this.state.selectedCategory} />
                        : null
                }

                {
                    (!this.state.isFirstRender)
                        ? <ListBlogs selectedCategory={this.state.selectedCategory} />
                        : null
                }
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        loader: state.toggle.loader
    }
}

export default connect(mapStateToProps, { toggleLoader })(Blogs);