import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

class ConfirmNoteOutput extends Component {


    render() {
        const { notes = {} } = this.props;
        const { closeNotesText = "", confirmNotesText = "" } = notes;

        return (
            <Fragment>{
                (this.props.isOpen)
                    ? <div className="confirm-note-output">
                        <div className="main-notes-card">
                            <button
                                className="close-notes-x"
                                onClick={this.props.closeNotes}
                            >  {String.fromCharCode(215)}
                            </button>
                            <div className="main-notes-container">
                                <div className={`main-notes-common main-notes-sucess c-whitish`}> {notes.title || ""}   </div>
                                <div className="main-notes-form-text">
                                    {notes.body || ""}
                                </div>
                                <div className="container d-flex justify-content-center">
                                <button
                                    className="success-button mx-1"
                                    onClick={this.props.confirmNotesBody}
                                    style={{ display: "inline" }}
                                > {confirmNotesText}</button>
                                <button
                                    className="cancel-button mx-1"
                                    onClick={this.props.closeNotes}
                                    style={{ display: "inline" }}
                                > {closeNotesText} </button>
                                </div>
                            </div>
                        </div>
                        <div className="transparent-notes-bg"></div>
                    </div>
                    : null
            }
            </Fragment>
        );
    }
}




ConfirmNoteOutput.propTypes = {

    notes: PropTypes.shape({
        // title example: "Delete User"
        title: PropTypes.string,
        // body example: "Are you sure?"
        body: PropTypes.string,
        // closeNotesText is button text example: "Cancel"
        closeNotesText: PropTypes.string,
        // closeNotesText is button text example: "Yes, delete."
        confirmNotesText: PropTypes.string,
    }),
    //closeNotes example: function to cancel Delete user
    closeNotes: PropTypes.func,
    //confirmNotesBody example: function to Delete user
    confirmNotesBody: PropTypes.func,

    isOpen: PropTypes.bool
}





export default connect(null, null)(ConfirmNoteOutput);


