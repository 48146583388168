import React, { Component } from 'react';
import Hamburger from '../../utils/buttons/Hamburger'
import TopNavbarRegularItems from './TopNavbarRegularItems';
import TopNavbarAuthItems from './TopNavbarAuthItems';
import TopNavbarSelectLanguage from './TopNavbarSelectLanguage';
import { connect } from "react-redux"

class TopNavbar extends Component {

  

    render() {
        return (
            <div id="top-navbar" style={{ height: "3rem" }}>
                <nav className="top-nav shadow">

                    <TopNavbarRegularItems />
                    <TopNavbarAuthItems />
                    <TopNavbarSelectLanguage />
                    <Hamburger />

                </nav>
                <div ></div>
            </div>
        );
    }
}



export default connect(null, null)(TopNavbar);



