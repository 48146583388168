
const initialstate = {
    isSuccess: true,
    title: "",
    body: ""
};


const notesReducer = (state = initialstate, action) => {
    switch (action.type) {

        // ******************  errors *******************

        case "error/SIGNIN":
            return {
                isSuccess: false,
                title: action.payload.title,
                body: action.payload.body
            };

        case "error/SIGNUP":
            return {
                isSuccess: false,
                title: action.payload.title,
                body: action.payload.body
            };


        case "error/UPDATE_EMAIL":
            return {
                isSuccess: false,
                title: action.payload.title,
                body: action.payload.body
            };

        case "error/UPDATE_NAME":
            return {
                isSuccess: false,
                title: action.payload.title,
                body: action.payload.body
            };

        case "error/VERIFY_EMAIL_SENT":
            return {
                isSuccess: false,
                title: action.payload.title,
                body: action.payload.body
            };


        case "error/PASSWORD_UPDATED_VERIFY_EMAIL_SENT":
            return {
                isSuccess: false,
                title: action.payload.title,
                body: action.payload.body
            };

        case "error/SEND_PASSWORD_RESET_EMAIL":
            return {
                isSuccess: false,
                title: action.payload.title,
                body: action.payload.body
            };


        case "error/ADD_NOTES_WITH_PAYLOAD":
            return {
                isSuccess: false,
                title: action.payload.title,
                body: action.payload.body
            };


        // *************  success ************************

        case "user/UPDATE_EMAIL":
            return {
                isSuccess: true,
                title: action.payload.title,
                body: action.payload.body
            };

        case "user/UPDATE_NAME":
            return {
                isSuccess: true,
                title: action.payload.title,
                body: action.payload.body
            };

        case "success/VERIFY_EMAIL_SENT":
            return {
                isSuccess: true,
                title: action.payload.title,
                body: action.payload.body
            };


        case "success/PASSWORD_UPDATED_VERIFY_EMAIL_SENT":
            return {
                isSuccess: true,
                title: action.payload.title,
                body: action.payload.body
            };


        case "success/SEND_PASSWORD_RESET_EMAIL":
            return {
                isSuccess: true,
                title: action.payload.title,
                body: action.payload.body
            };


        case "success/ADD_NOTES_WITH_PAYLOAD":
            return {
                isSuccess: true,
                title: action.payload.title,
                body: action.payload.body
            };


        // ****** close notes *************

        case "closeNotes":
            return initialstate;

        default:
            return state
    }
}

export default notesReducer;