import React, { useState, useEffect, useRef } from 'react';
import firebase from '../../../config/fbConfig'
import { connect } from "react-redux"
import Img from 'react-image';
import moment from 'moment';
import { withRouter } from "react-router";
import userAvatar from '../../../assets/user-avatar.png';
import { profilesAdd } from '../../../store/actions/profiles';
import { changeRouteValue } from '../../../store/actions/viewData';
import {
    inboxNotesUpdate
} from '../../../store/actions/inboxNotes';

const SingleInboxNote = (props) => {
    const isMounted = useRef();
    isMounted.current = false;

    const [name, setName] = useState("");
    const [photoURL, setphotoURL] = useState("not");

    useEffect(() => {
        async function didMount() {

            try {
                isMounted.current = true; //koristi pored setState
                const authorId = props.a;
                const profileIsFounded = await props.profiles.find(elem => elem.authorId === authorId);
                if (!profileIsFounded)
                    firebase.database()
                        .ref('profiles/' + authorId).once("value", (snapshot) => {

                            const name = snapshot.val().n || "";
                            const photoURL = snapshot.val().p || "";
                            setName(name);
                            setphotoURL(photoURL);
                            props.profilesAdd({
                                name,
                                photoURL,
                                authorId
                            })
                        })
                else {
                    setName(profileIsFounded.name);
                    setphotoURL(profileIsFounded.photoURL);
                }
                const feedCommentsDbRef =
                    firebase.database().ref('feed-comments/' + props.uid + "/" + props.id);
                await props.n && feedCommentsDbRef.update({
                    n: false,
                    p: /* postedAt:*/ firebase.database.ServerValue.TIMESTAMP //Date.now()
                })
             //   console.log("samo jednom",props.id, props.n)
                // sada ih manuelno promeni u reduxu
                const inboxNoteFounded = await props.inboxNotes.find(elem => elem.id === props.id)
                if (inboxNoteFounded && inboxNoteFounded.n)
                    props.inboxNotesUpdate({ id: props.id })

            } catch (err) {
            //    console.log("greska", err)
            }
        }
        didMount(); // eslint-disable-next-line
    }, [])


    useEffect(() => {
        //componentWillUnmount
        return () => {
            isMounted.current = false
        }
    }, [])

    const addNewRoute = (payload) => (e) => {
        e.preventDefault();
        props.changeRouteValue(payload);
        props.history.push(payload)
    }

    const comment = props.c;
    const postedAtTime = moment(props.p || 0).format('DD/MM/YYYY, HH:mm');

    return (
        <div
            id={"notes-comment-" + props.id}
            className="comments-wrapper bc-ternary"
        >
            <div className="comments-header">
                {
                    (photoURL === "not") ? null
                        : <Img
                            src={[photoURL, userAvatar]}
                            alt="user-avatar"
                            className="comments-avatar"
                        />
                }
                <div>
                    <div className="comments-name">  {name} </div>
                    <small className="comments-time"> {postedAtTime} </small>
                </div>
            </div>
            <div
                className="comments-body cursor-pointer"
                onClick={addNewRoute(props.u)}
            > {comment}
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue,
        profiles: state.profiles,
        uid: state.user && state.user.uid,
        tl: state.tl[state.tl.default],
        inboxNotes: state.inboxNotes
    }
}

const SingleInboxNoteWithRouter = withRouter(SingleInboxNote);

export default connect(mapStateToProps, {
    changeRouteValue,
    profilesAdd,
    inboxNotesUpdate
})(SingleInboxNoteWithRouter);