import React, { Component, Fragment } from 'react';
import SignedUserItems from './SignedUserItems';
//import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { withRouter } from "react-router";
import { changeRouteValue } from '../../../store/actions/viewData';
import { closeLeftSideBar } from '../../../store/actions/toggle';

class LeftSideBarAuthItems extends Component {

    addNewRoute = (payload) => () => {
        if (this.props.uid) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload);
        this.props.closeLeftSideBar();
    }

    render() {
        const { tl = {} } = this.props;
        return (
            <Fragment>
                <div className="sidebar-dash"></div>
                {(this.props.uid)
                    ? <SignedUserItems />
                    : <Fragment>
                        <div
                            className="btn c-whitish"
                            onClick={this.addNewRoute('/signin')}
                        >
                            {tl.sign_in_t}
                            </div>

                        <br />
                        <div
                            className="btn c-whitish"
                            onClick={this.addNewRoute('/signup')}
                        >
                            {tl.register_t}
                            </div>
                        <div className="sidebar-dash"></div>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        tl: state.tl[state.tl.default]
    }
}

const LeftSideBarAuthItemsWithRouter = withRouter(LeftSideBarAuthItems);

export default connect(mapStateToProps, {
    changeRouteValue,
    closeLeftSideBar
})(LeftSideBarAuthItemsWithRouter);