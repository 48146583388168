export const htmlArray = [
    {
        title: "About",
        description: "Get to know two times World tipster champion Ninca90 and his betting life story. His journey, methods, advices and evidence of performance he achieved at bettingexpert one of the largest betting communities in the world...",
        urlMeta: "https://ninca90.com/about",
        image: "https://ninca90.com/img/welcome.png",
        fileName: "about.html",
    },
    {
        title: "Tickets",
        description: "Check the best accumulator tips for free. Football, basketball, tennis, NFL, ice hockey etc. mixed in tickets with a high mathematical security of winning. View our experts predictions and their betting styles. Low, medium and high odds, different sports, tipsters, markets... Register and join for FREE ninca90.com",
        urlMeta: "https://ninca90.com/tickets",
        image: "https://ninca90.com/img/welcome.png",
        fileName: "tickets.html",
    },
    {
        title: "Ninca90",
        description: "High quality betting tips, analysis and tickets by two times world’s best tipster Ninca90 and team of betting experts around him. Football tips, basketball tips, tennis tips, special bets, over/under, handicap, best odds, best predictions etc. Register and join for FREE ninca90.com",
        urlMeta: "https://ninca90.com",
        image: "https://ninca90.com/img/welcome.png",
        fileName: "index.html",
    },
    {
        title: "Contact",
        description: "Contact us via our customer support. Tell us all your comments, wishes, remarks... User experience is something that is very important to us. Do not hestitate to contact us using this email adress: info@ninca90.com",
        urlMeta: "https://ninca90.com/contact",
        image: "https://ninca90.com/img/welcome.png",
        fileName: "contact.html",
    },
    {
        title: "Analysis",
        description: "Betting tips and analysis for football, basketball, tennis, ice hockey, handball and many more sports by Ninca90 two times world’s best tipster and his team of experts. Wiew our predictions. 1x2, Over/under, handicap, BTTS. Register and join for FREE ninca90.com",
        urlMeta: "https://ninca90.com/analyses",
        image: "https://ninca90.com/img/welcome.png",
        fileName: "analysis.html"
    }
];