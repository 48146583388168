export const serbian = {
    "key_t": "sr",
    "ok_t": "Ok",
    "analysis_t": "Analize",
    "tickets_t": "Tiketi",
    "blogs_t": "Blog",
    "high_quality_sports_betting_tips": "Visoko kvalitetni tipovi, analize i tiketi za sportsko klađenje od strane dvostrukog najboljeg tipstera na svetu Ninca90 i drugih tipstera - eksperata.",
    "sports_betting_brings_excitement": "Sportsko klađenje donosi uzbuđenje, adrenalin, pruža razonodu i postoji  mogućnost zarade",
    "if_you_are_sports_fan_join_us_now": "Ukoliko si sportski fan, pridruži nam se!",
    "best_tips_uppercase": "POGLEDAJ TIPOVE",
    "betting_tips_title": "Tipovi za klađenje",
    "that_will_help_you_win_more": "koji će vam doneti prednost",
    "statistics_ninca_t": "Statistika Ninca90",
    "number_of_tips": "Broj tipova",
    "stake_t": "Ulog",
    "average_odds_t": "Prosečna kvota",
    "wdl_t": "W D L",
    "profit_t": "Profit",
    "yield_t": "Uspešnost",
    "best_of_month_by_profit": "NAJBOLJI U MESECU PO PROFITU",
    "best_of_month_by_yield": "NAJBOLJI U MESECU PO USPEŠNOSTI",
    "category_t": "Kategorija",
    "number_of_matches": "Broj mečeva: ",
    "date_and_time_t": "Datum & Vreme",
    "sport_t": "Sport",
    "match_t": "Par",
    "tip_t": "Tip",
    "odds_t": "Kvota",
    "terms_of_use_t": "Uslovi korišćenja",
    "marketing_t": "Marketing",
    "developed_by_t" : "Programirao",
    "sign_in_t": "Uloguj se",
    "register_t": "Registruj se",
    "home_t": "Naslovna",
    "about_t": "Opis",
    "contact_t": "Kontakt",
    "add_new_analysis_t": "Dodaj novu analizu",
    "add_new_ticket": "Dodaj novi tiket",
    "add_new_blog": "Dodaj novi blog",
    "add_banners": "Dodaj baner",
    "gallery_t": "Galerija",
    "users_t": "Korisnici",
    "profile_settings_t": "Podešavanja profila",
    "sign_out_t": "Izloguj se",
    "sign_in_error": "Greška u prijavi",
    "ops_something_went_wrong": "Uups, nešto nije u redu. ",
    "register_error_r": "Greška u registraciji",
    "email_valied_name_required_pass_at_least_char": "Email mora biti važeći, ime je obavezno i lozinka mora imati najmanje 6 karaktera.",
    "update_email_failed": "Promena email-a nije uspela",
    "your_update_email_has_failed": "Vaša promena email-a nije uspela.",
    "update_name_failed": "Promena imena nije uspela",
    "your_name_update_has_failed": "Vaša promena imena nije uspela.",
    "sending_verification_email_failed": "Slanje verifikacionog email-a nije uspelo",
    "verification_email_not_sent": "Verifikacioni email nije poslat.",
    "update_password_failed": "Promena šifre nije uspela",
    "pass_not_updated": "Šifra nije promenjena.",
    "pass_reset_email_failed": "Resetovanje šifre putem email-a nije uspelo",
    "email_for_reseting_password_not_sent": "Email za resetovanje šifre nije poslat.",
    "success_t": "Uspešno",
    "your_email_updated" : "Vaš email je ažuriran.",
    "your_name_updated." : "Vaše ime je ažurirano.",
    "verification_email_sent_check_your_email": "Verifikacioni email je poslat. Molim Vas, proverite svoj email.",
    "password_updated_verification_email_sent_check_your_email": "Šifra je ažurirana i verifikacioni email je poslat. Molim Vas, proverite svoj email.",
    "new_email_sent_check_your_email": "Novi email je poslat. Molim Vas, proverite svoj email.",
    "add_to_home_page" : "Dodaj na Naslovnu stranu",
    "please_verify_your_email": "Molim Vas, verifikujte email",
    "you_must_verify_email_then_signin_to_view_content": " Morate verifikovati svoj email i potom se prijaviti da bi ste videli sav sadržaj.",
    "comment_not_posted": "Komentar nije objavljen.",
    "comment_not_saved": "Komentar nije sačuvan.",
    "enter_comment_label": "Unesite komentar",
    "send_t": "Pošalji",
    "comments_t": "Komentari",
    "no_more_comments": " Nema više komentara.",
    "load_more": "Učitaj više",
    "comments_not_added": "Komentari nisu dodati",
    "delete_comment_q": "Obrisati komentar?",
    "are_you_sure_that_you_want_to_remove_message_q" : "Da li ste sigurni da želite ukloniti ovu poruku?",
    "cancel_t": "Otkaži",
    "remove_t": "Ukloni",
    "date_t": "Datum",
    "today_t": "Danas",
    "show_until": "Prikaži do:",
    "ticket_is_moved_in_archive": "Tiket je pomeren u arhivu.",
    "ticket_is_not_moved_in_archive": "Tiket nije pomeren u arhivu.",
    "ticket_is_not_moved": "Tiket nije pomeren",
    "ticket_is_not_added_on_home_page": "Tiket nije dodat na naslovnu stranu.",
    "move_ticket_to_archive_q": "Pomeri tiket u arhivu?",
    "want_to_move_ticket_in_archive_q": "Da li ste sigurni da želite pomeriti ovaj tiket u arhivu?",
    "yes_t": "Da",
    "move_to_archive_t": "Pomeri u arhivu",
    "ticket_is_added_on_home_page" :"Tiket je dodat na naslovnu stranu.",
    "ticket_is_not_added": "Tiket nije dodat",
    "add_to_home_page_ticket": "Dodaj na naslovnu stranu tiket?",
    "add_ticket_on_homepage_q": "Da li ste sigurni da želite ovaj tiket dodati na naslovnu stranu?",
    "added_to_home_page": "Dodat na naslovnu stranu",
    "ticket_is_deleted": "Tiket je obrisan.",
    "ticket_is_not_deleted": "Tiket nije obrisan",
    "ticket_removed_from_home_p": "Tiket je uklonjen sa naslovne strane.",
    "ticket_not_removed_from_home_p": "Tiket nije uklonjen sa naslovne strane.",
    "delete_ticket_q": "Obrisati tiket?",
    "sure_to_delete_ticket_q": "Da li ste sigurni da želite obrisati tiket?",
    "delete_t": "Obriši",
    "remove_ticket_t": "Ukloni tiket",
    "sure_to_remove_ticket_from_home_p_q": "Da li ste sigurni da želite ukloniti tiket sa naslovne strane?",
    "name_t": "Ime",
    "total_odds_t": "Ukupna kvota",
    "win_t": "Dobitak",
    "tips_your_own_risk": "Tipove uzimate na sopstvenu odgovornost. ",
    "tips_not_safe": "Utakmice nisu 100% sigurne.",
    "analysis_not_saved": "Analiza nije snimljena",
    "you_must_fill_to_submit_analysis": "Morate popuniti sva polja da bi ste poslali analizu.",
    "odds_number_that_contains_digit_dot": "Kvote moraju biti broj koji sadrži brojeve i po potrebi tačku",
    "content_between_min_max_chars" : "Sadržaj mora biti između 200 i 2.000 karaktera.",
    "img_url_must_be_min_max_chars": "Link slike mora imati između 1 i 250 karaktera",
    "invalid_time_in_match": "Nevažeće vreme u meču.",
    "not_able_save_analysis_days_later_or_minutes": "Niste u mogućnosti da snimite analizu ukoliko je termin odigravanja utakmice duži od 60 dana ili počinje za 20 minuta.",
    "analysis_saved": "Analiza je snimljena",
    "add_new_analysis": "Dodaj novu analizu",
    "save_analysis_q": "Snimi analizu?",
    "sure_save_publish_analysis_q": "Da li ste sigurni da želite snimiti i objaviti ovu analizu?",
    "mark_an_as_w_q": "Obeleži analizu kao DOBITAK?",
    "mark_an_as_d_q": "Obeleži analizu kao NEREŠENO?",
    "mark_an_as_l_q": "Obeleži analizu kao GUBITAK",
    "sure_to_mark_analysis_q": "Da li ste sigurni da želite obeležiti ovu analizu?",
    "edit_t": "Izmeni",
    "save_t": "Snimi",
    "mark_t": "Obeleži",
    "draw_t": "Nerešeno",
    "lose_t": "Gubitak",
    "enter_host_team": "Unesi domaći tim",
    "enter_guest_team": "Unesi gostujući tim",
    "enter_tip": "Unesi tip",
    "enter_title": "Unesi naslov",
    "enter_img_url": "Unesi link slike",
    "enter_odds": "Unesi kvote",
    "enter_stakes": "Unesi ulog",
    "language_t": "Jezik",
    "betting_house_t": "Kladionica",
    "add_match_t": "Dodaj utakmicu",
    "ticket_categories": "Kategorija tiketa",
    "published_at_t": "Objavljen",
    "analysis_marked": "Analiza je obeležena.",
    "analysis_not_marked": "Analiza nije obeležena",
    "update_analysis_q": "Ažuriraj analizu?",
    "sure_to_update_analysis_q": "Da li ste sigurni da želite ažurirati analizu?",
    "update_analysis_t": "Ažuriraj aktivnu analizu",
    "analysis_not_updated": "Analiza nije ažurirana",
    "analysis_not_fetched": "Analiza nije pronađena",
    "page_not_found": "404 - STRANICA NIJE PRONAĐENA",
    "page_you_are_looking_for": "Ova stranica koju tražite je možda uklonjena, promenila ime ili je privremeno nedostupna.",
    "add_new_blog_t": "Dodaj novi blog",
    "blog_not_saved": "Blog nije ažuriran",
    "fill_fields_to_submit_blog": "Morate popuniti sva polja pre postavljanja bloga.",
    "blog_saved": "Blog je sačuvan.",
    "preview_t": "Pregled",
    "enter_description_t": "Unesite opis",
    "blog_not_fetched": "Blog nije pronađen",
    "match_not_added": "Meč nije dodat",
    "fill_odds_with_valid_number": "Morate popuniti sve kvote sa validnom brojčanom vrednošću.",
    "ticket_saved": "Tiket je sačuvan.",
    "add_valid_values_in_form_to_add_match": "Morate dodati sve validne vrednosti u formi da bi ste dodali meč.",
    "fill_all_fields_to_add_match": "Morate popuniti sva polja da bi ste dodali meč.",
    "add_ticket_t": "Dodaj tiket",
    "save_ticket_t": "Sačuvaj tiket",
    "save_ticket_q": "Sačuvaj tiket?",
    "sure_to_save_and_publish_ticket_q": "Da li si siguran da želiš sačuvati i objaviti ovaj tiket?",
    "delete_match_t": "Obriši utakmicu",
    "no_analysis_t": "Nema analize",
    "analysis_to_be_loaded" : "Nema analiza za učitavanje.",
    "no_analyses_t": "Nema analiza",
    "show_t" : "Prikaži",
    "active_t": "Aktivna",
    "archive_t": "Arhiva",
    "enter_email_t": "Unesite email",
    "enter_pass_t": "Unesite password",
    "new_user_q": "Novi korisnik?",
    "sign_up_instead": "Ulogujte se",
    "forgot_password_q": "Zaboravljena lozinka?",
    "sign_u_t": "Registruj se",
    "already_user_q": " Postojeći korisnik? Ulogujte se",
    "licence_terms": "Licence & Uslovi",
    "i_am_years_old" : "Stariji sam od 18 godina",
    "enter_name_t": "Unesite ime",
    "name_length_invalid": "Dužina imena nije važeća",
    "name_must_min_max_chars_long": "Ima mora imati minimum 2 ili maksimalno 20 karaktera.",
    "inbox_t": "Obaveštenja",
}