import React, { Component } from 'react';
import { toggleLeftSideBar } from '../../../store/actions/toggle';
import { connect } from "react-redux"
import CountCommentNotes from '../countingNotifications/CountCommentNotes';
class Hamburger extends Component {


    handleClick = () => {
        this.props.toggleLeftSideBar();
    }

    render() {
        const { isRightSidebarOpen } = this.props;
        const btnState = (isRightSidebarOpen) ? "active" : "not-active";
        return (
            <div style={{
                position:
                    "relative"
            }}
                className="hamburger-position"
                onClick={this.handleClick}
            >
                <div className="hamburger-box" >
                    <div className={"hamburger-btn " + btnState}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div> <CountCommentNotes /></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isRightSidebarOpen: state.toggle.isRightSidebarOpen
    }
}

export default connect(
    mapStateToProps, {
    toggleLeftSideBar
})(Hamburger);

