import React, { Component, Fragment } from 'react';
import firebase from '../../../config/fbConfig';
import Img from 'react-image';
import { connect } from "react-redux";
import userAvatar from '../../../assets/user-avatar.png';
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
import moment from 'moment';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
import { profilesAdd } from '../../../store/actions/profiles';
class ListedSingleAdminComment extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            name: "",
            photoURL: "not",
            isOpenRemoveCommentModal: false
        }
    }

    addNewRoute = (payload) => (e) => {
        e.preventDefault();
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    removeComment = (e) => {
        const { id } = this.props;
        firebase.database()
            .ref('admin-comments').child(id).remove();
    }

    openNotes = () => {
        //  console.log("hit", this._isMounted)
        this._isMounted && this.setState({ isOpenRemoveCommentModal: true });
    }
    closeNotes = () => {
        this._isMounted && this.setState({ isOpenRemoveCommentModal: false });
    }


    async  componentDidMount() {
        this._isMounted = true;
        const authorId = this.props.a;
        const profileIsFounded = await this.props.profiles.find(elem => elem.authorId === authorId);
        if (!profileIsFounded)
            firebase.database()
                .ref('profiles/' + authorId).once("value", (snapshot) => {
                    const { n = "", p = "" } = snapshot.val();
                    this.setState({
                        name: n,
                        photoURL: p
                    });
                    this.props.profilesAdd({
                        name: n,
                        photoURL: p,
                        authorId
                    })
                })
        else {
            this.setState({
                name: profileIsFounded.name,
                photoURL: profileIsFounded.photoURL
            })
        }
    }
    render() {
        const { id, tl = {} } = this.props;
        //   const authorId = this.props.a;
        const comment = this.props.c;
        const postedAtTime = moment(this.props.p || 0).format('DD/MM/YYYY, HH:mm');
        const { name, photoURL } = this.state;
        //  dodaj authorId za link kasnije ka profilu
        return (
            <div
                id={"admin-comment-" + id}
                className="comments-wrapper bc-ternary"

            >
                <div className="comments-header">

                    {
                        (photoURL === "not") ? null
                            : <Img
                                src={[photoURL, userAvatar]}
                                alt="user-avatar"
                                className="comments-avatar"
                            />
                    }

                    <div>
                        <div className="comments-name">  {name} </div>
                        <small className="comments-time"> {postedAtTime} </small>
                    </div>
                </div>

                <div
                    className="comments-body cursor-pointer"
                    onClick={this.addNewRoute(this.props.u)}
                > {comment}
                </div>

                {
                    (this.props.adminLevel >= 10) ?
                        <Fragment>
                            <ConfirmNoteOutput
                                notes={{
                                    title: tl.delete_comment_q,
                                    body: tl.are_you_sure_that_you_want_to_remove_message_q,
                                    closeNotesText: tl.cancel_t,
                                    confirmNotesText: tl.remove_t
                                }}
                                //closeNotes example: function to cancel Delete user
                                closeNotes={this.closeNotes}
                                //confirmNotesBody example: function to Delete user
                                confirmNotesBody={this.removeComment}
                                isOpen={this.state.isOpenRemoveCommentModal}
                            />

                            <button
                                onClick={this.openNotes}
                                className="error-button comments-del-btn"
                                id={id}
                            >
                                {tl.remove_t}
                            </button>
                        </Fragment>
                        : null
                }

            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        adminLevel: state.user && state.user.adminLevel,
        profiles: state.profiles,
        tl: state.tl[state.tl.default]
    }
}

const ListedSingleAdminCommentWithRouter = withRouter(ListedSingleAdminComment);

export default connect(mapStateToProps, {
    changeRouteValue,
    profilesAdd
})(ListedSingleAdminCommentWithRouter);