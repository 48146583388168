import React, { Component, Fragment } from 'react'
import TicketFooter from './TicketFooter';
import { connect } from 'react-redux';
import { balls } from '../../../config/sports';
import Img from 'react-image';
import DeleteTicket from './DeleteTicket';
import AddToHomeTicket from './AddToHomeTicket';
import RemoveFromHomeTicket from './RemoveFromHomeTicket';
import AddToArchiveTicket from './AddToArchiveTicket';
import moment from 'moment';

class Ticket extends Component {

    mapMatches = () => {

        return this.props.z && this.props.z.map((singleMatch, iter) =>
            <tr className="ticket-single-match" key={singleMatch.r}>
                <td className="">
                    {iter + 1}
                </td>
                <td className="ticket-date-time ">
                    {moment(singleMatch.x || 1).format('DD/MM/YYYY, HH:mm')}
                </td>
                <td className="">
                    <Img
                        src={[balls[singleMatch.s]]}
                        alt={singleMatch.s}
                        className="mr-2"
                        decode={false}
                    />
                    {singleMatch.s}
                </td>
                <td> {singleMatch.h} - {singleMatch.g}  </td>
                <td> {singleMatch.y}      </td>
                <td> {singleMatch.o}      </td>

            </tr>
        )
    }

    render() {

        const { isArchived = true, tl } = this.props
        const categoryValue = this.props.d;
        const numberOfMatches = this.props.z && this.props.z.length;

        return (

            <div>
                <div className="c-whitish">
                    <div className="ticket-all-wrapper">
                        <div className="ticket-category-matches">
                            {tl.category_t}: {categoryValue || ""} | {tl.number_of_matches} {numberOfMatches || 0}</div>
                        <table className="ticket-matches responsive-table">
                            <thead className="ticket-single-match">
                                <tr>
                                    <th className="">
                                        {" "}
                                    </th>
                                    <th className="ticket-date-time "> {tl.date_and_time_t}  </th>
                                    <th className=""> {tl.sport_t}</th>
                                    <th className=""> {tl.match_t}</th>
                                    <th className=""> {tl.tip_t}</th>
                                    <th className=""> {tl.odds_t} </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.mapMatches()}
                            </tbody>


                        </table>

                        <TicketFooter {...this.props} />
                        <DeleteTicket
                            ticketId={this.props.id}
                            authorId={this.props.a}
                            game={this.props.z}
                            isArchived={isArchived}
                        />
                        {
                            (isArchived)
                                ? null
                                : <Fragment>

                                    <AddToHomeTicket
                                        ticketId={this.props.id}
                                    />
                                    <RemoveFromHomeTicket />
                                    <AddToArchiveTicket
                                        ticketId={this.props.id}
                                    />
                                </Fragment>
                        }
                    </div>
                </div>


            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        tl: state.tl[state.tl.default]
    }
}


export default connect(mapStateToProps, null)(Ticket);