import React, { Component } from 'react';
import Img from 'react-image';
import { connect } from "react-redux";

class Banners extends Component {




    mapBanners = () => {
        return this.props.banners.map(elem =>
            <div
                className="col-sm-6 col-md-12"
                style={{ padding: 0 }}
                key={elem.key}
            >
                <a href={elem.bannerHref} >
                    <Img
                        src={[elem.bannerImg]}
                        alt="banner"
                        className="banner-img"
                        decode={false}
                    />
                </a>
            </div>
        )
    }

    render() {
        return (
            <div id="banners" className="container-fluid mt-1">
                <div className="row">
                    {this.mapBanners()}
                </div>
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        banners: state.banners
    }
}

export default connect(mapStateToProps, null)(Banners);