import axios from 'axios';
import { firebaseAuth } from "../../config/fbConfig";
import { clientUrl } from '../../config/constants';


export const userSignIn =
  (credentials) => (dispatch, getState) => {

    dispatch({
      type: 'toggle/LOADER',
      payload: "main"
    })

    firebaseAuth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    )
      .catch((err) => {
        // const message = err && err.message;
        const state = getState()
        const tl = state.tl[state.tl.default]
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
        dispatch({
          type: 'error/SIGNIN',
          payload: {
            title: tl.sign_in_error,
            body: tl.ops_something_went_wrong
          }
        });
      });
  }


export const userSignOut = () => dispatch => {
  firebaseAuth.signOut()
    /* .then(() => {
       dispatch({
         type: 'user/SIGNOUT_SUCCESS'
       });
       return;
     })*/
    .then(() => {
      window.location.reload(false)
    })
    ;
}

export const userSignUp =
  ({ email, password, name }) => (dispatch, getState) => {
    dispatch({
      type: 'toggle/LOADER',
      payload: "main"
    })
    axios.post('auth/signup', { email, password, name })
      .then(() => firebaseAuth.signInWithEmailAndPassword(
        email,
        password
      ))
      .then(() => {
        return firebaseAuth.currentUser.updateProfile({
          displayName: name
        })
      })
      .then(() => {
        dispatch({
          type: 'user/UPDATE_DISPLAY_NAME',
          displayName: name
        })
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
        //nesto menjao sklonio jedan .then, potrebno je testirati da li radi
        firebaseAuth.currentUser.sendEmailVerification({
          url: clientUrl + '/verify-email?email=' + firebaseAuth.currentUser.email
        })
      })
      .catch((error) => {
        // const message = err && err.message;
        const state = getState()
        const tl = state.tl[state.tl.default]
        dispatch({
          type: 'error/SIGNUP',
          payload: {
            title: tl.register_error_r,
            body: tl.ops_something_went_wrong + tl.email_valied_name_required_pass_at_least_char
          }
        });
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
      })
  }


export const updateEmail =
  (email) => (dispatch, getState) => {
    const state = getState()
    const tl = state.tl[state.tl.default];
    dispatch({
      type: 'toggle/LOADER',
      payload: "main"
    })
    firebaseAuth.currentUser.getIdToken(true)
      .then((idToken) => {
        return axios.patch('auth/update-email', { email, idToken })
      })
      .then(() => {
        dispatch({
          type: 'user/UPDATE_EMAIL',
          email,
          payload: {
            title: tl.success_t,
            body: tl.your_email_updated
          }
        })
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
      })
      .catch((error) => {
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
        dispatch({
          type: 'error/UPDATE_EMAIL',
          payload: {
            title: tl.update_email_failed,
            body: tl.ops_something_went_wrong + tl.your_update_email_has_failed
          }
        });
      })
  }

export const updateName =
  (username) => (dispatch, getState) => {
    const state = getState()
    const tl = state.tl[state.tl.default];
    dispatch({
      type: 'toggle/LOADER',
      payload: "main"
    })
    firebaseAuth.currentUser.getIdToken(true)
      .then((idToken) => {
        return axios.patch('auth/update-name', { name: username, idToken })
      })
      .then(() => {
        dispatch({
          type: 'user/UPDATE_NAME',
          name: username,
          payload: {
            title: tl.success_t,
            body: tl.your_name_updated
          }
        })
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
      })
      .catch((error) => {
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
        dispatch({
          type: 'error/UPDATE_NAME',
          payload: {
            title: tl.update_name_failed,
            body: tl.ops_something_went_wrong + tl.your_name_update_has_failed
          }
        });
      })
  }


export const verifyEmail =
  () => (dispatch, getState) => {
    const state = getState()
    const tl = state.tl[state.tl.default];
    dispatch({
      type: 'toggle/LOADER',
      payload: true
    })
    const actionCodeSettings = {
      url: clientUrl + '/verify-email?email=' + firebaseAuth.currentUser.email
    };

    firebaseAuth.currentUser.sendEmailVerification(actionCodeSettings)
      .then(function () {
        dispatch({
          type: 'success/VERIFY_EMAIL_SENT',
          payload: {
            title: tl.success_t,
            body: tl.verification_email_sent_check_your_email
          }
        })
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
      })
      .catch(function (error) {

        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
        dispatch({
          type: 'error/VERIFY_EMAIL_SENT',
          payload: {
            title: tl.sending_verification_email_failed,
            body: tl.ops_something_went_wrong + tl.verification_email_not_sent
          }
        });
      })
  }

export const updatePassword =
  (newPassword) => (dispatch, getState) => {
    const state = getState();
    const tl = state.tl[state.tl.default];
    dispatch({
      type: 'toggle/LOADER',
      payload: true
    })
    const email = firebaseAuth.currentUser.email;
    firebaseAuth.currentUser.getIdToken(true)
      .then((idToken) =>
        axios.patch('auth/update-password', { newPassword, idToken })
      )

      .then(() =>
        firebaseAuth.signInWithEmailAndPassword(
          email,
          newPassword
        ))
      .then(() =>
        firebaseAuth.currentUser.sendEmailVerification({
          url: clientUrl + '/verify-email?email=' + firebaseAuth.currentUser.email
        })
      )

      .then(() => {
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
        dispatch({
          type: 'success/PASSWORD_UPDATED_VERIFY_EMAIL_SENT',
          payload: {
            title: tl.success_t,
            body: tl.password_updated_verification_email_sent_check_your_email
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
        dispatch({
          type: 'error/PASSWORD_UPDATED_VERIFY_EMAIL_SENT',
          payload: {
            title: tl.update_password_failed,
            body: tl.ops_something_went_wrong + tl.pass_not_updated
          }
        });
      })
  }


export const resetPassword =
  (email) => (dispatch, getState) => {
    const state = getState();
    const tl = state.tl[state.tl.default];
    dispatch({
      type: 'toggle/LOADER',
      payload: "main"
    })
    firebaseAuth.sendPasswordResetEmail(email)
      .then(() => {
        dispatch({
          type: 'success/SEND_PASSWORD_RESET_EMAIL',
          payload: {
            title: tl.success_t,
            body: tl.new_email_sent_check_your_email
          }
        })
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
      })
      .catch((error) => {
        dispatch({
          type: 'error/SEND_PASSWORD_RESET_EMAIL',
          payload: {
            title: tl.pass_reset_email_failed,
            body: tl.ops_something_went_wrong + tl.email_for_reseting_password_not_sent
          }
        });
        dispatch({
          type: 'toggle/LOADER',
          payload: false
        })
      })
  }


