import React, { Component } from 'react';


class Contact extends Component {

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    render() {

        return (
            <div>

                <div className="container pt-6">
                    <h1 className="contact-head">
                        Contact Us
                    </h1>
                </div>


                <div className="container text-frame pb-3">
                    <span className="contact-text">
                        You have something to write us?<br />
                        User experience is something that is very important to us.<br />
                        Tell us all your comments, wishes, remarks...<br />
                        Do not hestitate to contact us via our customer support.
                    </span>
                </div>
                <div
                    style={{ margin: "0 auto", textAlign: "center" }}
                >
                    <a href="mailto:info@ninca90.com" className="contact-mail" target="_blank" rel="noopener noreferrer" alt="sender" >info@ninca90.com</a>
                </div>

            </div>

        );
    }
}






export default Contact;