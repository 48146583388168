import React, { Component } from 'react';
import { connect } from "react-redux";
import AddBlogNavbar from './AddBlogNavbar';
import AddMetaBlogValues from './AddMetaBlogValues';
import JoditApp from '../../utils/editor/JoditApp';
import firebase, { firestoreDB } from "../../../config/fbConfig";
import { categories } from '../../../config/categories';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { changeRouteValue } from '../../../store/actions/viewData';

const shortid = require('shortid');


class AddBlog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            canWrite: true,
            title: "",
            language: "en",
            description: "",
            imageUrl: "",
            category: categories[0]
        }
    }

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }


    updateContent = (value) => {
        this.setState({ content: value })
    }

    handleMetaChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value })
    }

    handleClickCanWrite = () => {
        this.setState(
            (prevState) => {
                return { canWrite: !prevState.canWrite }
            })
    }

    submitBlog = async () => {
        const { tl = {} } = this.props;
        try {

            this.props.toggleLoader(true);
            const { uid, displayName } = this.props;
            const {
                content,
                title,
                description,
                imageUrl,
                language,
                category
            } = this.state;

            if (!content || !title || !description || !imageUrl || !language || !category) {
                this.props.addErrorNotesWithPayload({
                    title: tl.blog_not_saved,
                    body: tl.fill_fields_to_submit_blog
                })
                this.props.toggleLoader(false)
                return;
            }

            if (imageUrl.length > 250) {
                this.props.addErrorNotesWithPayload({
                    title: tl.blog_not_saved,
                    body: tl.img_url_must_be_min_max_chars
                })
                this.props.toggleLoader(false)
                return false;
            }

            const blogRefId = shortid.generate();

            await firestoreDB
                .collection('blogs')
                .doc(blogRefId)
                .set({
                    title,
                    description,
                    languages: [language],
                    imageUrl,
                    authorId: uid,
                    authorName: displayName,
                    category,
                    setAt: firebase.firestore.FieldValue.serverTimestamp()
                }, { merge: true })

            /* const contentDbRef = firebase.database().ref('blogs-content/' + blogRefId + '/' + uid + '/' + language);
             await contentDbRef.set({
                 content,
                 authorId: uid
             });*/

            await firestoreDB
                .collection('blogs-content-' + language)
                .doc(blogRefId)
                .set({
                    content,
                    authorId: uid
                })


            this.props.toggleLoader(false)
            this.props.history.push("/blog/" + blogRefId);
            this.props.changeRouteValue("/blog/" + blogRefId);
            this.props.addSuccessNotesWithPayload({
                title: tl.success_t,
                body: tl.blog_saved
            })
        } catch (error) {
            this.props.addErrorNotesWithPayload({
                title: tl.blog_not_saved,
                body: tl.ops_something_went_wrong + tl.blog_not_saved + "."
            })
            this.props.toggleLoader(false)
        }
    }

    render() {

        const {
            content,
            canWrite,
            title,
            language,
            description,
            imageUrl,
            category
        } = this.state;

        const { tl = {} } = this.props;
        return (
            <div id="add-blog">
                <h1> {tl.add_new_blog_t} </h1>

                {
                    (canWrite)
                        ? <AddMetaBlogValues
                            title={title}
                            language={language}
                            description={description}
                            imageUrl={imageUrl}
                            category={category}
                            handleMetaChange={this.handleMetaChange}
                        />
                        : null
                }

                <JoditApp
                    canWrite={canWrite}
                    content={content}
                    updateContent={this.updateContent}
                    isBlog={true}
                />
                <AddBlogNavbar
                    handleClickCanWrite={this.handleClickCanWrite}
                    submitBlog={this.submitBlog}
                />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        displayName: state.user.displayName,
        loader: state.toggle.loader,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, {
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload,
    changeRouteValue
})(AddBlog);