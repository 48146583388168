import React from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
class ExampleCustomInput extends React.Component {

  render() {
    const { value, onClick, tl ={}} = this.props;
    return (
      <div className="datepicker-custom-form">
        <label htmlFor="btnDateAndTime" className="">{tl.date_t}</label>
        <div
          id="btnDateAndTime"
          onClick={onClick}
        > {value} </div>
      </div>
    )
  }

}


class DateAndTime extends React.Component {


  render() {
    // console.log( this.props.startDate.toUTCString(),"this.state", new Date(this.props.startDate.toUTCString()))
    const { tl = {} } = this.props;
    return (
      <DatePicker
        selected={this.props.startDate}
        onChange={this.props.handleDatePickerChange}
        todayButton={tl.today_t}
        withPortal
        showTimeSelect
        timeIntervals={15}
        timeCaption="time"
        timeFormat="HH:mm"
        dateFormat="dd/MM/yyyy, HH:mm"
        customInput={<ExampleCustomInput {...this.props} />}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tl: state.tl[state.tl.default]
  }
}

export default connect(mapStateToProps, null)(DateAndTime);

