import firebase from "../../config/fbConfig";
import moment from 'moment';

const todaysDate = moment();
const month = todaysDate.format('MM');
const year = todaysDate.format('YYYY');

export const statisticsGet =
    () => dispatch => {

        const statsDbRef = firebase.database().ref('tipster-stats-all');
        const statsMonthDbRef = firebase.database().ref('tipster-stats-month/' + year + month);
        const profilesDbRef = firebase.database().ref('profiles');

        statsDbRef.on('child_added',
            (snapshot) => {
                profilesDbRef.child(snapshot.key).once('value', (snapProfile) => {
                    let n = "", profileURL = "";
                    if (snapProfile.hasChildren()) {
                        //  console.log("snapProfile added", snapProfile.val());
                        n = snapProfile.val().n || "";
                        profileURL = snapProfile.val().p || "";
                    }
                    dispatch({
                        type: 'statistics/GET',
                        payload: { ...snapshot.val(), key: snapshot.key, n, profileURL }
                    })

                })



            },
            err => console.log("errr", err)
        )
        statsDbRef.on('child_changed',
            (snapshot) => {

                profilesDbRef.child(snapshot.key).once('value', (snapProfile) => {
                    let n = "", profileURL = "";
                    if (snapProfile.hasChildren()) {
                        //    console.log("snapProfile updated", snapProfile.val());
                        n = snapProfile.val().n || "";
                        profileURL = snapProfile.val().p || "";
                    }
                    dispatch({
                        type: 'statistics/GET_UPDATED',
                        payload: { ...snapshot.val(), key: snapshot.key, n, profileURL }
                    })
                })
            },
            err => console.log("errr", err)
        )


        //Stats for current month
        
        statsMonthDbRef.on('child_added',
            (snapshot) => {
                //   type: 'statistics/GET_MONTH',
                if (snapshot.exists()) {
                  //  console.log("snapshot novi month", snapshot.val(), snapshot.key)
                    dispatch({
                        type: 'statistics/GET_MONTH',
                        payload: { ...snapshot.val(), key: snapshot.key }
                    })
                }
            },
            err => console.log("errr", err)
        )


        statsMonthDbRef.on('child_changed',
            (snapshot) => {

                if (snapshot.exists()) {
                    dispatch({
                        type: 'statistics/GET_UPDATED_MONTH',
                        payload: { ...snapshot.val(), key: snapshot.key }
                    })
                }

            },
            err => console.log("errr", err)
        )

    }
