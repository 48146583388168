import firebase from "../../config/fbConfig";
const bannersRef = firebase.database().ref("banners");

export const fetchBanners = () => dispatch => {
    let banners = []
    bannersRef.on("value",
        (snapshot) => {
            if (!snapshot.val()) return;
            snapshot
                .val()
                .banners
                .forEach(element => {
                    banners.push({
                        key: element.key,
                        bannerImg: element.bannerImg,
                        bannerHref: element.bannerHref
                    })
                });

            dispatch({ type: 'banners/FETCH_SUCCESS', payload: banners });
            banners = [] //clouser, had to reset it here
        },
        (err) => {
            //if there is an error, just send empty array to stop the loader
            banners = [] //clouser, had to reset it here
            dispatch({ type: 'banners/FETCH_SUCCESS', payload: [] });
        }
    )
}