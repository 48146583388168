import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import DayMonthsAndYears from '../../utils/dateAndTime/DayMonthsAndYears';

class SortTicketValues extends Component {

    state = {
        isOpenTicketSort: false,

    }



    toggleTicketSort = () => {
        this.setState((prevState) => {
            return {
                isOpenTicketSort: !prevState.isOpenTicketSort
            }
        });
    }

    render() {
      
        const { isOpenTicketSort } = this.state;
        const { isTicketActive } = this.props;

        return (
            <div>

                <ButtonDropdown
                    isOpen={isOpenTicketSort}
                    toggle={this.toggleTicketSort}

                >
                    <DropdownToggle caret
                        className="cancel-button"
                    >
                        {(this.props.isTicketActive)
                            ? "Active"
                            : "Archive"
                        }
                    </DropdownToggle>


                    <DropdownMenu>
                        <DropdownItem
                            id="ticket-active-dropdown"
                            onClick={this.props.handleQueryValues} >
                            Active
                               </DropdownItem>
                       
                        <DropdownItem
                            id="ticket-archive-dropdown"
                            onClick={this.props.handleQueryValues}
                        > Archive
                          </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>

                {
                    (isTicketActive) ? null
                        : <Fragment>

                            <DayMonthsAndYears
                                startDate={this.props.startDate}
                                handleDatePickerChange={this.props.handleDatePickerChange}
                            />

                            <button
                                id="search-tickets"
                                onClick={this.props.handleQueryValues}
                                className="success-button"
                            >
                                Search
                            </button>
                        </Fragment>
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        analysesData: state.analyses.analysesData
        //  routeValue: state.viewData.routeValue
    }
}



export default connect(mapStateToProps, null)(SortTicketValues);