import React, { Component } from 'react';


class LicenceAndTerms extends Component {

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }


    render() {

        return (

            <div className="container-fluid player-info-background">

                <div className="analyse-background">

                    <h2 className="c-whitish text-center">Terms and conditions</h2>

                    <p className="terms-head">By using Ninca90.com, you are agreeing to the following Terms and Conditions of Use.</p>

                    <h5 className="terms-title">1.Terms</h5>


                    <p className="terms-body">
                        These Terms of Service set forth the terms and conditions that apply to the use and access of Ninca90.com The website is owned, designed, maintained and operated by Ninca90. By using the website, you agree to these terms and conditions in their entirety.
                        To enter this agreement, you must be of legal age in your jurisdiction of residence and be legally eligible to be bound by this Agreement. You must also be able and competent to enter and agree with the terms, conditions and obligations set forth in this Agreement. By accepting this agreement, we can assume that you have the capacity to be bound by it. You may not use this website if you are under the legal age to do so.
							        </p>





                    <h5 className="terms-title">2.Our services</h5>


                    <p className="terms-body">
                        Entering Ninca90.com web site is a visitor’s own responsibility. The contents of Ninca90.com web site are provided "as is". No warranty of any kind is made in relation to the accuracy, reliability or content of the pages. Ninca90.com is not a bookmaker and does not offer services related to bookmaking. We do not accept bets; we specialize only in presenting betting odds and predicting results of sports events. Past performance of a Ninca90.com tipsters is not an indication of future results. Ninca90.com provides a place to track the performance of a tipster and see their latest tips. Ninca90.com does not advocate betting real money based on this information and cannot be held responsible for any losses due to acting on the information. Any actions a user takes based on information provided should be carefully considered, understanding both the strengths and limitations of past performance based statistics. Ninca90.com does not provide investment advice.
							        </p>








                    <h5 className="terms-title">3.User responsibility and registration obligations</h5>


                    <p className="terms-body">
                        In order to use Ninca90.com or certain parts of it, you may be required to register a user account on this web site; in this case, you agree to provide truthful information when requested, and undertake that you are aged at least the eighteen (18) or more. By registering, you explicitly agree to this site's terms of use, including any amendments made from time to time and are available here.
							        </p>





                    <h5 className="terms-title">4.Tipsters obligations</h5>


                    <p className="terms-body">
                        Each tipster is responsible for their own tips, and should ensure the odds provided are accurate at the time of publish. Any odds errors found anywhere on the site should be noted, with details sent to info@ninca90.com.
							        </p>




                    <h5 className="terms-title">5.Account Security</h5>


                    <p className="terms-body">
                        You agree and understand that you are responsible for maintaining the confidentiality of your login credentials for your Ninca90.com account. You agree that any information provided in your account to be true, current and completely accurate. Should any information change, you agree to update your account accordingly. You understand that you must not disclose your login credentials to any third party. Your right to access Ninca90.com is given to you personally, and is not transferable by you to any other entity.
                        If you become aware of any unauthorised access to your Account or Profile Information, you must contact Ninca90.com immediately by emailing info@ninca90.com
							        </p>





                    <h5 className="terms-title">6.Privacy policy</h5>


                    <p className="terms-body">
                        Ninca90.com reserves the right to send customer newsletters that can contain advertising and some information from the betting industry to inform customers about news relating to the provided service or offer on a time-to-time basis.
                        Registration data and other personally-identifiable information that may be collected on this site is subject to the terms of the site's privacy policy. For more information about our privacy policy please refer to our privacy policy statement.
							        </p>


                    <h5 className="terms-title">7.Reservation of rights</h5>


                    <p className="terms-body">
                        Ninca90.com reserves all it’s rights, including but not limited to any and all copyrights, trademarks, patents, trade secrets, and any other proprietary right that. The use of Ninca90.com rights and property requires our prior written consent. By making services available to you, we are not providing you with any implied or express licenses or rights, and you will have no rights to make any commercial uses of Ninca90.com or service without the site editor's prior written consent.
							        </p>






                    <h5 className="terms-title">8.Modifications</h5>


                    <p className="terms-body">
                        There may be times when we wish to update these terms and conditions. It's unlikely to happen often but as the internet and betting industry are constantly evolving, there may be occasions when we need to make some changes.
							        </p>

                </div>
            </div>
        );
    }
}


export default LicenceAndTerms;
