import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userSignOut } from "../../../store/actions/auth"

class SignOutButton extends Component {

    handleClick = () => {
        this.props.userSignOut();
    }

    render() {
        const { tl = {} } = this.props;
        return (
            <div
                className="drop-nav-text"
                style={{
                    display:"block",
                    width: "100%"
                }}
                onClick={this.handleClick}
            >
               {tl.sign_out_t}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}


export default connect(mapStateToProps, { userSignOut })(SignOutButton);