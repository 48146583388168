import React, { Component, Fragment } from 'react';
import firebase from '../../../config/fbConfig';
import ListedSingleComment from './ListedSingleComment';
import { connect } from "react-redux";
import {
    addErrorNotesWithPayload
} from '../../../store/actions/notes';

const bComments = 5;

class ListComments extends Component {

    state = {
        lastFetchedTime: 0,
        comments: [],
        newComments: [],
        firstFetchedTime: 0,
        isEmptyAjax: false
    }

    componentDidMount() {
        this.fetchCommentsOnMount();
        this.setRemoveListener();
    }

    componentWillUnmount() {
        const { commentsRefId } = this.props;
        firebase.database()
            .ref('blogs-comments/' + commentsRefId)
            .off();
    }

    setFetchListener = (lastFetchedTime) => {
        const { commentsRefId } = this.props;
        firebase.database()
            .ref('blogs-comments/' + commentsRefId)
            .orderByChild('p')
            .startAt(lastFetchedTime + 1)
            .on('child_added', (listenerSnapshot) => {
                const newArrComments = [...this.state.newComments];
                newArrComments.unshift({
                    ...listenerSnapshot.val(),
                    id: listenerSnapshot.key
                })
                this.setState({ newComments: newArrComments })

            });
    }


    setRemoveListener = () => {
        const { commentsRefId } = this.props;
        let comments = [], newComments = [];
        firebase.database()
            .ref('blogs-comments/' + commentsRefId)
            .on('child_removed', (listenerSnapshot) => {
                comments = this.state.comments.filter(item => item.id !== listenerSnapshot.key);
                newComments = this.state.newComments.filter(item => item.id !== listenerSnapshot.key);
                this.setState({ newComments, comments })
            })
    }

    fetchCommentsOnMount = async () => {

        try {
            const { commentsRefId } = this.props;
            const commentsDbRef =

                firebase.database()
                    .ref('blogs-comments/' + commentsRefId)
                    .orderByChild('p')
                    .limitToLast(bComments)

            const snap = await commentsDbRef.once("value");
            if (!snap.numChildren())
                this.setState({ isEmptyAjax: true });

            let lastFetchedTime = 0,
                firstFetchedTime = 0;
            let comments = [...this.state.comments];
            let newComments = []
            snap.forEach(element => {
                if (!firstFetchedTime) firstFetchedTime = element.val().p; //p as postedAt
                newComments.unshift({
                    ...element.val(),
                    id: element.key
                })
                lastFetchedTime = element.val().p; //p as postedAt
            })
            newComments = comments.concat(newComments);
            this.setState({ lastFetchedTime, firstFetchedTime, comments: newComments });
            this.setFetchListener(lastFetchedTime);

        } catch (error) {
            const { tl = {} } = this.props;
            this.props.addErrorNotesWithPayload({
                title: tl.comments_not_added,
                body: tl.ops_something_went_wrong + tl.comments_not_added + "."
            })
        }
    }



    fetchComments = async () => {

        try {
            if (this.state.isEmptyAjax) return;
            const { commentsRefId } = this.props;
            const startAtFetching = (this.state.firstFetchedTime)
                ? this.state.firstFetchedTime - 1
                : this.state.firstFetchedTime;

            const commentsDbRef = (!this.state.firstFetchedTime)

                ? firebase.database()
                    .ref('blogs-comments/' + commentsRefId)
                    .orderByChild('p')
                    .limitToLast(bComments)
                : firebase.database()
                    .ref('blogs-comments/' + commentsRefId)
                    .orderByChild('p')
                    .endAt(startAtFetching)
                    .limitToLast(bComments)

            const snap = await commentsDbRef.once("value");
            if (!snap.numChildren()) {
                this.setState({ isEmptyAjax: true });
                return;
            }

            let lastFetchedTime = 0,
                firstFetchedTime = 0;
            let comments = [...this.state.comments];
            let newComments = []
            snap.forEach(element => {
                if (!firstFetchedTime) firstFetchedTime = element.val().p;
                newComments.unshift({
                    ...element.val(),
                    id: element.key
                })
                lastFetchedTime = element.val().p;
                //    console.log("element", element.val(), element.key)
            })
            newComments = comments.concat(newComments);
            this.setState({ lastFetchedTime, firstFetchedTime, comments: newComments });


        } catch (error) {
            const { tl = {} } = this.props;
            this.props.addErrorNotesWithPayload({
                title: tl.comments_not_added,
                body: tl.ops_something_went_wrong + tl.comments_not_added + "."
            })
        }
    }


    mapComments = () => {
        const { commentsRefId } = this.props;
        const arrayOfComments = [...this.state.comments];
        return arrayOfComments.map(element =>
            <ListedSingleComment
                key={element.id}
                {...element}
                authorId={this.props.authorId}
                feedAuthorId = {this.props.feedAuthorId}
                commentsRefId={commentsRefId}
            />)
    }

    mapNewComments = () => {
        const { commentsRefId } = this.props;
        const arrayOfComments = [...this.state.newComments];
        return arrayOfComments.map(element =>
            <ListedSingleComment
                key={element.id}
                {...element}
                authorId={this.props.authorId}
                feedAuthorId = {this.props.feedAuthorId}
                commentsRefId={commentsRefId}
            />)
    }



    render() {
        const { isEmptyAjax } = this.state;
        const { tl = {} } = this.props;
        return (

            <Fragment>{
                (this.props.canWrite)
                    ? null
                    : <div>
                        <h2> {tl.comments_t} </h2>
                        {this.mapNewComments()}
                        {this.mapComments()}
                        {
                            (isEmptyAjax)
                                ? <div className="c-whitish my-3">{tl.no_more_comments}</div>
                                : <button className="success-button" onClick={this.fetchComments} >{tl.load_more}</button>

                        }
                    </div>
            }
            </Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, { addErrorNotesWithPayload })(ListComments);


