import firebase, { firebaseAuth } from '../../config/fbConfig';
import axios from 'axios';
const storage = firebase.storage();
const storageRef = storage.ref();

export const getUser = (payload) => {
    return {
        type: "user/GET",
        payload
    }
}



export const updatePhotoURL = ({ downloadURL, uid, imgFireStamp }) => dispatch => {
    // do smoething
    firebaseAuth.currentUser.getIdToken(true)
        .then((idToken) => {
            return axios.patch('profile/update-avatar', {
                idToken,
                photoURL: downloadURL
            })
        })
        .then((res) => {
            const listRef = storageRef.child(`${uid}/avatar`);

            // Find all the prefixes and items.
            return listRef.listAll();
        })
        .then(function (res) {
            return res.items.forEach(function (itemRef) {
                // All the items under listRef.
                if (imgFireStamp !== itemRef.name) {
                    storageRef.child(`${uid}/avatar/${itemRef.name}`).delete();
                }
            });
        })
        .then(() => {
            dispatch({
                type: 'user/UPDATE_PHOTOURL',
                photoURL: downloadURL
            })
        });
}
