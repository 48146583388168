const initialstate = {
    isTicketActive: true,
    startDate: new Date(),
    endAt: 0,
    isLoadButtonVisible: false,
  //  isFirstView: true,
    ticketData: []
}



const archivedTicketsReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "archivedTicket/ADD":
            return { ...action.payload };

        case "archivedTicket/REMOVE":
            return initialstate;

        default:
            return state
    }
}

export default archivedTicketsReducer;