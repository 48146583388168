import React, { Component } from 'react';
import { connect } from "react-redux";
import { firebaseAuth } from "../../../config/fbConfig";
import { sports } from '../../../config/sports';
import { bettingHouses } from '../../../config/bettingHouses';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { changeRouteValue } from '../../../store/actions/viewData';
import axios from 'axios';
import AddTicketValues from './AddTicketValues';
import TicketMatchesView from './TicketMatchesView';
import moment from 'moment';
import { ticketCategories } from '../../../config/categories';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
function hasNotMorethanTwoDecimals(num) {
    return !!num.match(/^[1-9]{1}[0-9]?[0-9]?[.]?[0-9]?[0-9]?$/)
}

const shortid = require('shortid');


class AddTicket extends Component {


    constructor(props) {
        super(props);
        this.state = {

            sport: sports[0],
            startDate: new Date(),
            hostTeam: "",
            guestTeam: "",
            tip: "",
            odds: "",
            stake: "0.25",
            bettingHouse: bettingHouses[0],
            category: ticketCategories[0],
            game: [],
            isOpenSubmit: false
        }
    }

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }


    openNotes = () => {
        this.setState({ isOpenSubmit: true });
    }
    closeNotes = () => {
        this.setState({ isOpenSubmit: false });
    }


    saveTicket = () => {
        this.props.toggleLoader(true);
        const {
            game, bettingHouse, stake, category, tl = {}
        } = this.state;

        firebaseAuth.currentUser.getIdToken(true)
            .then((idToken) => {
                return axios
                    .post('ticket/create-ticket', {
                        game,
                        bettingHouse,
                        stake,
                        idToken,
                        category
                    })
            })
            .then((result) => {
                this.setState({ game: [] });
                this.closeNotes();
                this.props.toggleLoader(false);
                this.props.addSuccessNotesWithPayload({
                    title: tl.success_t,
                    body: tl.ticket_saved
                })
            })
            .catch((err) => {
                //    console.log("error", err, err.response);
                this.closeNotes();
                this.props.toggleLoader(false);
                this.props.addErrorNotesWithPayload({
                    title: tl.match_not_added,
                    body: tl.add_valid_values_in_form_to_add_match
                })
            })
    }


    addSinglesToArray = () => {
        const {
            sport,
            startDate,
            hostTeam,
            guestTeam,
            tip,
            odds,
            tl = {}
        } = this.state;

        if (!(isNumeric(odds) && hasNotMorethanTwoDecimals(odds) && odds < 101)) {
            this.props.addErrorNotesWithPayload({
                title: tl.match_not_added,
                body: tl.fill_odds_with_valid_number
            })
            return;
        }
        if (
            hostTeam === "" ||
            guestTeam === "" ||
            guestTeam === "" ||
            tip === ""
        ) {
            this.props.addErrorNotesWithPayload({
                title: tl.match_not_added,
                body: tl.fill_all_fields_to_add_match
            })
            return;
        }
        const StartDateToNano = moment(startDate).valueOf();
        if (
            StartDateToNano - Date.now() < 1200000
            || StartDateToNano - Date.now() > 1000 * 3600 * 24 * 7
        ) {
            this.props.addErrorNotesWithPayload({
                title: tl.match_not_added,
                body: tl.invalid_time_in_match
            })
            return;
        }
        const game = [...this.state.game];
        const suid = shortid.generate();
        game.push({
            r: suid, //ref key, id of element
            s: sport,
            x: startDate, // not unix, just regular date
            h: hostTeam,
            g: guestTeam,
            y: tip,
            o: odds
        });
        this.setState({
            game,
            hostTeam: "",
            guestTeam: "",
            tip: "",
            odds: ""
        })
    }

    updateContent = (value) => {
        this.setState({ content: value })
    }

    handleMetaChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    handleDatePickerChange = startDate => {
        this.setState({
            startDate
        });
    };

    filterMatch = (key) => (e) => {
        if (e && e.target.id && e.target.id === "tooltip-" + key)
            return;
        const newGamesState = [...this.state.game].filter(match => match.r !== key);
        this.setState({
            game: newGamesState
        })
    }


    render() {
        //   console.log("this", this.state.category)
        const { tl = {} } = this.props;
        return (
            <div>
                <h1> {tl.add_ticket_t}</h1>
                <ConfirmNoteOutput
                    notes={{
                        title: tl.save_ticket_q,
                        body: tl.sure_to_save_and_publish_ticket_q,
                        closeNotesText: tl.cancel_t,
                        confirmNotesText: tl.yes_t
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.saveTicket}
                    isOpen={this.state.isOpenSubmit}
                />
                <AddTicketValues
                    {...this.props}
                    {...this.state}
                    handleMetaChange={this.handleMetaChange}
                    handleDatePickerChange={this.handleDatePickerChange}
                    updateContent={this.updateContent}
                    addSinglesToArray={this.addSinglesToArray}
                />

                <TicketMatchesView
                    game={this.state.game}
                    filterMatch={this.filterMatch}
                    stake={this.state.stake}
                    bettingHouse={this.state.bettingHouse}
                    category={this.state.category}
                />

                <button
                    onClick={this.openNotes}
                    className="success-button"
                > {tl.save_ticket_t} </button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}



export default connect(mapStateToProps, {
    changeRouteValue,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload,
    toggleLoader
})(AddTicket);