const initialstate = {
    email: null,
    emailVerified: false,
    uid: null,
    displayName: null
};

const userReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "user/GET":
            return { ...state, ...action.payload };

        case "user/UPDATE_DISPLAY_NAME":
            return { ...state, displayName: action.displayName }

        case "user/SIGNOUT_SUCCESS":
            return initialstate;

        case "user/UPDATE_EMAIL":
            return { ...state, email: action.email }

        case "user/UPDATE_NAME":
            return { ...state, displayName: action.name }

        case "user/UPDATE_PHOTOURL":
            return { ...state, photoURL: action.photoURL }

        default:
            return state
    }
}

export default userReducer;