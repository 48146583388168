export const closeNotes = () => {
    return {
        type: "closeNotes"
    }
}


export const addSuccessNotesWithPayload = (payload) => {
    return {
        type: "success/ADD_NOTES_WITH_PAYLOAD",
        payload
    }
}


export const addErrorNotesWithPayload = (payload) => {
    return {
        type: "error/ADD_NOTES_WITH_PAYLOAD",
        payload
    }
}
