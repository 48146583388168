import React from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
class ExampleCustomInput extends React.Component {

    render() {
        const { value, onClick } = this.props;
        return (
            <button
                id="btnDateAndTime"
                className="datetime-input"
                onClick={onClick}
            > {value} </button>
        )
    }

}


class DayMonthsAndYears extends React.Component {


    render() {
        const { tl = {} } = this.props;
        return (
            <div id="datepicker-wraper2">
                <DatePicker
                    selected={this.props.startDate}
                    onChange={this.props.handleDatePickerChange}
                    todayButton={tl.today_t}
                    withPortal
                    dateFormat="dd/MM/yyyy"
                    customInput={<ExampleCustomInput />}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}


export default connect(mapStateToProps, null)(DayMonthsAndYears);


