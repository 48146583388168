import React, { Component } from 'react';
import firebaseApp from '../../../config/fbConfig'
import { connect } from "react-redux"

import SingleUser from './SingleUser'
class ListUsersByLevel extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            lastFetchedTime: "",
            usersArray: [],
            isLoadingFirstLoad: true,
            isLoading: false,
            skipLimit: 8,
            emptyAjax: false,
            levelName: "",
            levelValue: 0,
            errorMessage: '',
            isFirstView: true,
        }
    }


    componentDidMount() {
        this._isMounted = true;
    }

    handleChange = (levelName, levelValue) => () => {

        console.log("hahaha", levelName, levelValue);
        // return
        this.setState((prevState) => {
            return {
                levelName,
                levelValue,
                isFirstView: !prevState.isFirstView,
                emptyAjax: false,
                usersArray: [],
                isLoadingFirstLoad: true,
                isLoading: false,
                lastFetchedTime: "",
            }
        })



    }

    fetchUsers = ({ isFirstFetch = false }) => {
        if ((
            !this.state.lastFetchedTime && !isFirstFetch
        ) || this.state.isLoading
        ) return;
        const { skipLimit } = this.state;
        this._isMounted && isFirstFetch && this.setState({ isLoadingFirstLoad: true });
        this._isMounted && this.setState({ isLoading: true });
        const startAfter = (isFirstFetch)
            ? ""
            : this.state.lastFetchedTime;

        const firestoreDB = firebaseApp.firestore();
        let lastFetchedTime = "";
        this._isMounted && firestoreDB
            .collection('users')
            .where(this.state.levelName, '==', this.state.levelValue)
            .orderBy('createdAt', 'desc')
            .startAfter(startAfter)
            .limit(skipLimit)
            .get()
            .then((querySnapshot) => {
                const usersArray = [...this.state.usersArray]
                if (querySnapshot.size < skipLimit)
                    this._isMounted && this.setState({
                        emptyAjax: true
                    })
                querySnapshot.forEach((doc) => {
                    lastFetchedTime = doc.data().createdAt;
                    usersArray.push({ ...doc.data(), id: doc.id })
                });
                this._isMounted && this.setState({
                    lastFetchedTime,
                    usersArray,
                    isLoadingFirstLoad: false
                });
            })
            .catch((err) => {
                console.log("obavezna greska!", err)
                this._isMounted && isFirstFetch && this.setState({
                    isLoadingFirstLoad: false
                })
            })
    }



    componentDidUpdate(prevProps, prevState) {

        if (prevState.isFirstView !== this.state.isFirstView)
            this.fetchUsers({ isFirstFetch: true });

        if (
            prevState.usersArray !== this.state.usersArray &&
            this.state.isLoading
        ) {
            this._isMounted && this.setState({
                isLoading: false
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        //      window.removeEventListener('scroll', this.handleScroll);
    }

    listUsers = () => {
        const { isLoadingFirstLoad, usersArray } = this.state;
        if (isLoadingFirstLoad) return <div> Loading </div>
        if (!usersArray.length)
            return <div> No users </div>
        const listedUsers =
            usersArray.map((userData) =>
                <SingleUser userData={userData} key={userData.id} />
            );
        return listedUsers;
    }


    render() {
        const { levelName, levelValue } = this.state;
        return (
            <div>
                <div className="p-1 mt-3">


                    <button
                        className={` 
                            ${(levelName === "tipsterLevel" && levelValue === 3)
                                ? "inactive-button"
                                : "cancel-button"}`
                        }
                        onClick={this.handleChange("tipsterLevel", 3)}
                    >Tipster(3)</button>

                    <button
                        className={` 
                            ${(levelName === "adminLevel" && levelValue === 4)
                                ? "inactive-button"
                                : "cancel-button"}`
                        }
                        onClick={this.handleChange("adminLevel", 4)}
                    >Moderator(4)</button>

                    <button
                        className={` 
                        ${(levelName === "adminLevel" && levelValue === 8)
                                ? "inactive-button"
                                : "cancel-button"}`
                        }
                        onClick={this.handleChange("adminLevel", 8)}
                    >Admin(8)</button>

                </div>

                {
                    (this.state.isFirstView)
                        ? this.listUsers()
                        : null
                }

                {
                    (!this.state.isFirstView)
                        ? this.listUsers()
                        : null
                }

                {
                    (this.state.emptyAjax || this.state.isLoadingFirstLoad)
                        ? <div className="c-whitish"> No more users </div>
                        : <button
                            className="success-button"
                            onClick={this.fetchUsers}
                        >   Load More </button>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        //  routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(ListUsersByLevel);