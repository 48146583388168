
import onexbet from '../assets/bhouse/1xbet.png';
import tenbet from '../assets/bhouse/10bet.png';
import eeesport from '../assets/bhouse/888sport.png';
import admiralbet from '../assets/bhouse/admiralBet.png';
import balkanbet from '../assets/bhouse/balkanBet.png';
import bet365 from '../assets/bhouse/bet365.png';
import betathome from '../assets/bhouse/betAtHome.png';
import betfair from '../assets/bhouse/betfair.png';
import betsafe from '../assets/bhouse/betsafe.png';
import bettson from '../assets/bhouse/bettson.png';
import betvictor from '../assets/bhouse/betvictor.png';
import betway from '../assets/bhouse/betway.png';
import bwin from '../assets/bhouse/bwin.png';
import comeon from '../assets/bhouse/comeon.png';
import coral from '../assets/bhouse/coral.png';
import interwetten from '../assets/bhouse/interwetten.png';
import ladbrokes from '../assets/bhouse/ladbrokes.png';
import marathonbet from '../assets/bhouse/marathonbet.png';
import matchbook from '../assets/bhouse/matchbook.png';
import maxbet from '../assets/bhouse/maxbet.png';
import meridianbet from '../assets/bhouse/meridianbet.png';
import mozzart from '../assets/bhouse/mozzart.png';
import parimatch from '../assets/bhouse/parimatch.png';
import pinnacle from '../assets/bhouse/pinnacle.png';
import pinnbet from '../assets/bhouse/pinnbet.png';
import planetwin365 from '../assets/bhouse/planetwin365.png';
import sbobet from '../assets/bhouse/sbobet.png';
import skybet from '../assets/bhouse/skybet.png';
import soccer from '../assets/bhouse/soccer.png';
import sportingbet from '../assets/bhouse/sportingbet.png';
import unibet from '../assets/bhouse/unibet.png';
import williamhill from '../assets/bhouse/williamhill.png';


export const bettingHouses = [
    "bet365",
    "Soccer",
    "MaxBet",
    "Mozzart",
    "Meridianbet",
    "AdmiralBet",
    "Balkanbet",
    "Planetwin365",
    "Pinnbet",
    "Pinnacle",
    "bwin",
    "Betsafe",
    "Marathonbet",
    "1xbet",
    "SBOBET",
    "Betway",
    "William Hill",
    "Unibet",
    "bettson",
    "ComeOn",
    "888sport",
    "Betfair",
    "Matchbook",
    "Interwetten",
    "bet at home",
    "BetVictor",
    "10Bet",
    "Coral",
    "Ladbrokes",
    "Skybet",
    "Parimatch",
    "Sportingbet",
]


// U buducnosti NE BRISI OVO DOLE
// bez imena kladionica, ovo gore, moze se, ali ako nema
// slike, a lista se stara analiza ili tiket, doci ce do buga,
export const bhousesLogos = {
    "bet365": bet365,
    "Soccer": soccer,
    "MaxBet": maxbet,
    "Mozzart": mozzart,
    "Meridianbet": meridianbet,
    "AdmiralBet": admiralbet,
    "Balkanbet": balkanbet,
    "Planetwin365": planetwin365,
    "Pinnbet": pinnbet,
    "Pinnacle": pinnacle,
    "bwin": bwin,
    "Betsafe": betsafe,
    "Marathonbet": marathonbet,
    "1xbet": onexbet,
    "SBOBET": sbobet,
    "Betway": betway,
    "William Hill": williamhill,
    "Unibet": unibet,
    "bettson": bettson,
    "ComeOn": comeon,
    "888sport": eeesport,
    "Betfair": betfair,
    "Matchbook": matchbook,
    "Interwetten": interwetten,
    "bet at home": betathome,
    "BetVictor": betvictor,
    "10Bet": tenbet,
    "Coral": coral,
    "Ladbrokes": ladbrokes,
    "Skybet": skybet,
    "Parimatch": parimatch,
    "Sportingbet": sportingbet,
}


export const bhousesLinks = {
    "bet365": "https://www.bet365.com/#/HO",
    "Soccer": "https://soccerbet.rs/#kladjenje",
    "MaxBet": "https://www.maxbet.rs/bet/#/home",
    "Mozzart": "https://www.mozzartbet.com/sr#",
    "Meridianbet": "https://meridianbet.rs/sr/kladjenje",
    "AdmiralBet": "https://admiralbet.rs/app/home",
    "Balkanbet": "https://www.balkanbet.rs",
    "Planetwin365": "https://av.365.rs/bet/#/home#top",
    "Pinnbet": "https://www.pinnbet.com",
    "Pinnacle": "https://www.pinnacle.com/en",
    "bwin": "https://sports.bwin.com/en/sports",
    "Betsafe": "https://www.betsafe.com/en",
    "Marathonbet": "https://www.marathonbet.com/en",
    "1xbet": "https://1xbet.com/en",
    "SBOBET": "https://www.sbobet.com/euro",
    "Betway": "https://sports.betway.com/en/sports",
    "William Hill": "https://www.williamhill.com",
    "Unibet": "https://www.unibet.com",
    "bettson": "https://www.betsson.com/en",
    "ComeOn": "https://www.comeon.com/en",
    "888sport": "https://www.888sport.com/#/home",
    "Betfair": "https://www.betfair.com",
    "Matchbook": "https://www.matchbook.com",
    "Interwetten": "https://www.interwetten.com/en/sportsbook",
    "bet at home": "https://www.bet-at-home.com",
    "BetVictor": "https://www.betvictor.com",
    "10Bet": "https://www.10bet.com",
    "Coral": "https://sports.coral.co.uk",
    "Ladbrokes": "https://www.ladbrokes.com/home/en",
    "Skybet": "https://m.skybet.com",
    "Parimatch": "https://www.parimatch.com/",
    "Sportingbet": "https://sports.sportingbet.com/en/sports",
}
















