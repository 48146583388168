import React, { Component } from 'react';
import ListTickets from './ListTickets';
import ListArchivedTickets from './ListArchivedTickets';
//import firebase from '../../../config/fbConfig'
import { connect } from "react-redux"
import SortTicketValues from './SortTicketValues';
import { fetchTicketValues } from './TicketModules';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import {
    archivedTicketAdd,
    archivedTicketRemove
} from '../../../store/actions/tickets';
import PagesHelmet from '../../utils/helmet/PagesHelmet';
import { htmlArray } from '../../../config/metaConfig';
class Tickets extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            isTicketActive: true,
            startDate: new Date(),
            endAt: 0,
            isLoadButtonVisible: true,
            ticketData: [],
            //  isFirstView: true,
        }
        this.fetchTicketValues = fetchTicketValues.bind(this);
    }

    componentDidMount() {

        document.getElementById("top-navbar").scrollIntoView()

        this.setState({

            ticketData: this.props.archivedTickets.ticketData,
            // isTicketActive: this.props.archivedTickets.isTicketActive,
            startDate: this.props.archivedTickets.startDate,
            //  isFirstView: this.props.isFirstView,
            endAt: this.props.archivedTickets.endAt
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.archivedTickets !== this.props.archivedTickets
            && this.props.archivedTickets.ticketData.length === 0
        ) {
            this.setState((prevState) => {
                return {
                    //  isFirstView: !prevState.isFirstView,
                    ticketData: [],
                    endAt: 0,
                    isLoadButtonVisible: true
                }
            });
        }

    }

    handleDatePickerChange = startDate => {
        this.setState({
            startDate
        });
    };

    handleQueryValues = (e) => {

        //search-analyses

        if (e.target.id === "search-tickets") {
            this.props.archivedTicketRemove();
            this.setState((prevState) => {
                return {
                    //  isFirstView: !prevState.isFirstView,
                    ticketData: [],
                    endAt: 0,
                    isLoadButtonVisible: true
                }
            });
            this.fetchTicketValues(/*isReset : */true);
        }
        if (e.target.id === "ticket-active-dropdown")
            this.setState({ isTicketActive: true });

        if (e.target.id === "ticket-archive-dropdown")
            this.setState({ isTicketActive: false });


    }

    loadMore = () => {
        if (this.state.ticketData)
            this.fetchTicketValues();
    }



    render() {
        const { isTicketActive, startDate } = this.state;
        const appMetaData = htmlArray
            .find(elem => elem.fileName === "tickets.html");
        return (
            <div className="c-whitish">
                <PagesHelmet
                    descriptionMeta={appMetaData.description}
                    clientURLMeta={appMetaData.urlMeta}
                    titleMeta={appMetaData.title}
                    imageMeta={appMetaData.image}
                />
                <SortTicketValues
                    startDate={startDate}
                    isTicketActive={isTicketActive}
                    handleQueryValues={this.handleQueryValues}
                    handleDatePickerChange={this.handleDatePickerChange}
                />

                {
                    (isTicketActive)
                        ? <ListTickets />
                        : <ListArchivedTickets />
                }


                {
                    (this.state.ticketData.length && this.state.isLoadButtonVisible)
                        ? <button
                            onClick={this.loadMore}
                            className="success-button ml-2 mt-2"
                        > Load More
                        </button>
                        : null
                }
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue,
        archivedTickets: state.archivedTickets
    }
}

export default connect(mapStateToProps, {
    archivedTicketAdd,
    archivedTicketRemove,
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
})(Tickets);