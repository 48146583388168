import React, { Component } from 'react';
//import firebaseApp from '../../../config/fbConfig'
import { connect } from "react-redux"
import ListUsers from './ListUsers';
import ListUsersByName from './ListUsersByName';
import ListUsersByEmail from './ListUsersByEmail';
import ListUsersByLevel from './ListUsersByLevel';
class Users extends Component {

    state = {
        tabValue: 2
    }

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    toggleView = (elem) => () => {
        this.setState({ tabValue: elem })
    }

    outputView = () => {
        switch (this.state.tabValue) {
            case 1:
                return <ListUsers />
            case 2:
                return <ListUsersByEmail />
            case 3:
                return <ListUsersByLevel />
            case 4:
                return <ListUsersByName />
            default:
                return <ListUsersByEmail />
        }
    }


    render() {
        const { tabValue } = this.state;
        return (
            <div>


                <button
                    className={` 
                            ${(tabValue === 1)
                            ? "inactive-button"
                            : "cancel-button"}`
                    }
                    onClick={this.toggleView(1)}
                >All users</button>

                <button
                    className={` 
                            ${(tabValue === 2)
                            ? "inactive-button"
                            : "cancel-button"}`
                    }
                    onClick={this.toggleView(2)}
                >Search by email</button>


                <button
                    className={` 
                            ${(tabValue === 3)
                            ? "inactive-button"
                            : "cancel-button"}`
                    }
                    onClick={this.toggleView(3)}
                >Search by level</button>

                <button
                    className={` 
                            ${(tabValue === 4)
                            ? "inactive-button"
                            : "cancel-button"}`
                    }
                    onClick={this.toggleView(4)}
                >Search by name</button>


                {this.outputView()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(Users);