import React, { Component } from 'react';
import { connect } from "react-redux"
import ImageUpload from './ImageUpload';
import ListGalleryImages from './ListGalleryImages';

class Gallery extends Component {

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    render() {
        return (
            <div>
               <h1 className="my-1">  Gallery </h1>
               <ImageUpload /> 
               <ListGalleryImages />
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(Gallery);