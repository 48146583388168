import React, { Component, Fragment } from 'react';
import Routes from '../../routes/Routes';

class MainContent extends Component {

    render() {
        return (
            <Fragment>
                <Routes />
            </Fragment>
        );
    }
}


export default MainContent;

