import React, { Component } from 'react';
import { connect } from "react-redux"
import Input from '../../utils/inputs/Input';
import { updatePassword } from '../../../store/actions/auth';

class UpdatePassword extends Component {

    state = {
        newPassword: '',
        repeatNewPassword: '',
        errorMessage: ''
    };

    updatePassword = () => {
        const { newPassword, repeatNewPassword } = this.state;
        if (newPassword === repeatNewPassword && repeatNewPassword !== "")
            this.props.updatePassword(this.state.newPassword);
        else
            this.setState({ errorMessage: "Passwords do not match" });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.updatePassword();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }

    render() {
        return (


            <div className="p-1">

                <Input
                    type="text"
                    label="Enter new password"
                    name="newPassword"
                    typeToggle={true}
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    required={true}
                />

                <Input
                    type="text"
                    label="Repeat new password"
                    name="repeatNewPassword"
                    typeToggle={true}
                    value={this.state.repeatNewPassword}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    required={true}
                />

                <div className="container d-flex justify-content-center">
                    <button
                        className="success-button"
                        onClick={this.updatePassword}
                    > Update Password</button>
                </div>
                <div
                    style={{ color: "#ed1c24", textAlign: "center" }}
                > {this.state.errorMessage}</div>
            </div>

        )
    }

}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps, { updatePassword })(UpdatePassword);

