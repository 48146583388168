import { getUser } from '../../../store/actions/profile';
import { userSignOut } from '../../../store/actions/auth';
import {
    activeAnalysesPut,
    activeAnalysesReplace,
    activeAnalysesRemove
} from '../../../store/actions/analyses';
import {
    inboxNotesAdd
} from '../../../store/actions/inboxNotes';

import {
    toggleActiveAnalysisAreFetched
} from '../../../store/actions/toggle'
import { activeTicketsPut, activeTicketsDelete } from '../../../store/actions/tickets';
import firebaseApp, { firebaseAuth } from '../../../config/fbConfig'
import store from '../../../store/store';
import {
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { readyApp } from '../../../store/actions/readyApp';
import axios from 'axios';
const analysisRef = firebaseApp.database().ref("analysis-active");
const ticketsRef = firebaseApp.database().ref("tickets");
const feedCommentsRef = firebaseApp.database().ref("feed-comments");
const authChangeState = () =>
    firebaseApp
        .auth()
        .onAuthStateChanged(async user => {
            try {
                if (user) {
                    let newUser = {
                        email: user.email,
                        emailVerified: user.emailVerified,
                        uid: user.uid,
                        adminLevel: 0,
                        tipsterLevel: 0,
                        photoURL: user.photoURL
                    }
                    const state = store.getState();
                    const tl = state.tl[state.tl.default] || {};

                    const idTokenResult =
                        await firebaseApp.auth().currentUser.getIdTokenResult()

                    if (!!idTokenResult.claims.adminLevel)
                        newUser.adminLevel = idTokenResult.claims.adminLevel
                    if (!!idTokenResult.claims.tipsterLevel)
                        newUser.tipsterLevel = idTokenResult.claims.tipsterLevel


                    if (user.displayName)
                        newUser.displayName = user.displayName;
                    if (!user.emailVerified) {
                        const idToken = await firebaseAuth.currentUser.getIdToken(true)
                        const res = await axios.post('profile/check-records-by-user', { idToken })
                        if (res.data && res.data.message) {
                            await store.dispatch(
                                userSignOut());
                        }
                    }


                    //kasnije on chile_changed and chiled_removed

                    analysisRef.on("child_added",
                        (snapshot) => {
                            //activeAnalyses/PUT
                            store.dispatch(toggleActiveAnalysisAreFetched(true))
                            store.dispatch(
                                activeAnalysesPut({ ...snapshot.val(), id: snapshot.key }));
                        },
                        async (err) => {
                            try {
                                if (err.message === "permission_denied at /analysis-active: Client doesn't have permission to access the desired data."
                                    && user.emailVerified
                                ) {
                                    await store.dispatch(
                                        userSignOut());
                                }
                                else
                                    store.dispatch(addErrorNotesWithPayload({
                                        title: tl.please_verify_your_email,
                                        body: tl.you_must_verify_email_then_signin_to_view_content
                                    }));
                            }
                            catch (error) {

                            }
                        }
                    );

                    analysisRef.on("child_changed",
                        (snapshot) =>
                            store.dispatch(
                                activeAnalysesReplace({
                                    ...snapshot.val(), id: snapshot.key
                                }))
                    );
                    analysisRef.on("child_removed",
                        (snapshot) =>
                            store.dispatch(
                                activeAnalysesRemove({
                                    id: snapshot.key
                                }))
                    );



                    ticketsRef.on("child_added",
                        (snapshot) => {
                            store.dispatch(
                                activeTicketsPut({ ...snapshot.val(), id: snapshot.key }));
                        }
                    );

                    ticketsRef.on("child_removed",
                        (snapshot) => {
                            store.dispatch(
                                activeTicketsDelete({ id: snapshot.key }));
                        }
                    );



                    feedCommentsRef.child(user.uid).limitToLast(20).on("child_added",
                        (snapshot) => {
                            store.dispatch(
                                inboxNotesAdd({ ...snapshot.val(), id: snapshot.key }));
                        }
                    );


                    // remove old items
                    const now = Date.now();
                    const cutoff = now - (7 * 24 * 60 * 60 * 1000); //old more than 7 days, change that in production

                    const old = feedCommentsRef.child(user.uid).orderByChild('p').endAt(cutoff).limitToLast(1);
                    old.on('child_added', function (snapshot) {
                        console.log("cutoff", cutoff, "now", now, snapshot.val())
                     //   snapshot.ref.remove();
                    });


                    store.dispatch(
                        getUser(newUser));

                    store.dispatch(readyApp());
                } else {
                    analysisRef.off();
                    ticketsRef.off();
                    feedCommentsRef.off();
                    store.dispatch(readyApp());
                }

            } catch (error) {
                //  console.error("Error adding document: ", error);
                store.dispatch(readyApp());
                analysisRef.off();
                ticketsRef.off();
                feedCommentsRef.off();
            }
        })

export default authChangeState;
