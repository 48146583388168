import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
class ExampleCustomInput extends React.Component {

  render() {
    const { value, onClick, tl = {} } = this.props;
    return (
      <Fragment>
        <button
          id="btnDateAndTime"
          className="datetime-input"
          onClick={onClick}
        > {tl.show_until} {value} </button>
      </Fragment>
    )
  }

}


class HoursAndOrMinutes extends React.Component {


  render() {
    const { sortByDay, tl } = this.props;
    return (
      <div id="datepicker-wraper2">
        {
          (sortByDay)
            ? <DatePicker
              selected={this.props.startDate}
              onChange={this.props.handleDatePickerChange}
              todayButton={tl.today_t}
              withPortal
              customInput={<ExampleCustomInput />}
            />
            : <DatePicker
              selected={this.props.startDate}
              onChange={this.props.handleDatePickerChange}
              todayButton={tl.today_t}
              withPortal
              showTimeSelect
              timeIntervals={60}
              timeFormat="HH"
              timeCaption="time"
              dateFormat="dd/MM/yyyy, HH:mm"
              customInput={<ExampleCustomInput {...this.props} />}
            />

        }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tl: state.tl[state.tl.default]
  }
}

export default connect(mapStateToProps, null)(HoursAndOrMinutes);