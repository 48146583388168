import React, { Component } from 'react';
import { connect } from "react-redux"
import PagesHelmet from '../../utils/helmet/PagesHelmet';
import { htmlArray } from '../../../config/metaConfig';
import AboutEn from './AboutEn';
import AboutSr from './AboutSr';
import Img from 'react-image';
import { countyFlags, languagesConfig } from '../../../config/about-languages';
class About extends Component {


    state = {
        lang: "en"
    }


    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    

    handleLangugageChange = (lang) => () => {
        this.setState({ lang })
    }


    languagesInBlog = () => {
        return languagesConfig.map((elem) =>
            <Img
                className={(this.state.lang === elem)
                    ? "cursor-pointer p-1 m-1 active-about-flag" : "cursor-pointer p-1 m-1"}
                onClick={this.handleLangugageChange(elem)}
                key={elem}
                src={[countyFlags[elem], countyFlags["en"]]}
                decode={false}
                style={{
                    width: "2rem",
                    height: "auto"
                }}
            />

        )
    }

    switchLanguages = () => {
        switch (this.state.lang) {
            case "en":
                return <AboutEn />;
            case "sr":
                return <AboutSr />;
            default:
                return <AboutEn />;
        }
    }

    render() {
        const appMetaData = htmlArray
            .find(elem => elem.fileName === "about.html");
        return (
            <div className="about-page-media">
                <PagesHelmet
                    descriptionMeta={appMetaData.description}
                    clientURLMeta={appMetaData.urlMeta}
                    titleMeta={appMetaData.title}
                    imageMeta={appMetaData.image}
                />
                {this.languagesInBlog()}
                {this.switchLanguages()}
            </div>

        );
    }
}




const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(About);