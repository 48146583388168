import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { userSignOut } from "../../../store/actions/auth";
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
import { closeLeftSideBar } from '../../../store/actions/toggle';
import CountCommentsNotes from '../../utils/countingNotifications/CountCommentNotes';
class SignedUserItems extends Component {

    handleClick = () => {
        this.props.userSignOut();
        this.props.closeLeftSideBar();
    }
    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload);
        this.props.closeLeftSideBar();
    }
    render() {
        const { tl = {} } = this.props;
        return (
            <Fragment>
                <div
                    className="btn c-whitish"
                    style = {{position:"relative"}}
                    onClick={this.addNewRoute('/inbox-notes')}
                >
                    {tl.inbox_t}
                    <CountCommentsNotes />
                </div>
                <br />
                <div
                    className="btn c-whitish"
                    onClick={this.addNewRoute('/profile-settings')}
                >
                    {tl.profile_settings_t}
                </div>
                <br />
                <div
                    className="btn c-whitish"
                    onClick={this.handleClick}
                >
                    {tl.sign_out_t}
                </div>
                <div className="sidebar-dash"></div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}


const SignedUserItemsWithRouter = withRouter(SignedUserItems);

export default connect(mapStateToProps, {
    userSignOut,
    changeRouteValue,
    closeLeftSideBar
})(SignedUserItemsWithRouter);