import React, { Component } from 'react';
import AddComment from './AddComment';
import ListComments from './ListComments';

class Comments extends Component {

    render() {
    
        const { canWrite = false } = this.props
        return (

            <div className="comments" >
                {
                    (canWrite) ? null
                        : <AddComment
                            displayName={this.props.displayName}
                            commentsRefId={this.props.commentsRefId}
                            authorId={this.props.authorId}
                            feedAuthorId = {this.props.feedAuthorId}
                        />
                }

                <ListComments
                    commentsRefId={this.props.commentsRefId}
                    canWrite={canWrite}
                    authorId={this.props.authorId}
                    feedAuthorId = {this.props.feedAuthorId}
                />
            </div>

        )
    }

}

export default Comments;


