const initialstate = [];

const inboxNotesReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "inboxNotes/ADD":
            return [...state, action.payload];

        case "inboxNotes/UPDATE":
            // filter and add === replace
            const newState = state.map(elem => {
                if (elem.id === action.payload.id) {
                    elem.n = false;
                    return elem;
                } else {
                    return elem
                }

            })

            return [...newState];

        default:
            return state
    }
}

export default inboxNotesReducer;