import React, { Component, Fragment } from 'react';
import firebase from '../../../config/fbConfig';
import ListedSingleAdminComment from './ListedSingleAdminComment';
import { connect } from "react-redux";
import {
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
const bComments = 10;

class ListAdminComments extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            lastFetchedTime: 0,
            comments: [],
            newComments: [],
            firstFetchedTime: 0,
            isOpenRemoveCommentModal: false,
            isEmptyAjax: false
        }
    }



    componentDidMount() {
        this._isMounted = true;
        this.fetchCommentsOnMount();
        this.setRemoveListener();
    }

    componentWillUnmount() {
        this._isMounted = false;
        firebase.database()
            .ref("admin-comments")
            .off();
    }

    setFetchListener = (lastFetchedTime) => {

        firebase.database()
            .ref("admin-comments")
            .orderByChild('p')
            .startAt(lastFetchedTime + 1)
            .on('child_added', (listenerSnapshot) => {
                const newArrComments = [...this.state.newComments];
                newArrComments.unshift({
                    ...listenerSnapshot.val(),
                    id: listenerSnapshot.key
                })
                this.setState({ newComments: newArrComments })

            });
    }


    setRemoveListener = () => {
        let comments = [], newComments = [];
        firebase.database()
            .ref("admin-comments")
            .on('child_removed', (listenerSnapshot) => {
                comments = this.state.comments.filter(item => item.id !== listenerSnapshot.key);
                newComments = this.state.newComments.filter(item => item.id !== listenerSnapshot.key);
                this.setState({ newComments, comments })
            })
    }

    fetchCommentsOnMount = async () => {

        try {

            const commentsDbRef =

                firebase.database()
                    .ref("admin-comments")
                    .orderByChild('p')
                    .limitToLast(bComments)

            const snap = await commentsDbRef.once("value");
            if (!snap.numChildren())
                this.setState({ isEmptyAjax: true });

            let lastFetchedTime = 0,
                firstFetchedTime = 0;
            let comments = [...this.state.comments];
            let newComments = []
            snap.forEach(element => {
                if (!firstFetchedTime) firstFetchedTime = element.val().p; //p as postedAt
                newComments.unshift({
                    ...element.val(),
                    id: element.key
                })
                lastFetchedTime = element.val().p; //p as postedAt
            })
            newComments = comments.concat(newComments);
            this.setState({ lastFetchedTime, firstFetchedTime, comments: newComments });
            this.setFetchListener(lastFetchedTime);

        } catch (error) {
            const { tl = {} } = this.props;
            this.props.addErrorNotesWithPayload({
                title: tl.comments_not_added,
                body: tl.ops_something_went_wrong + tl.comments_not_added + "."
            })
        }
    }



    fetchComments = async () => {

        try {
            if (this.state.isEmptyAjax) return;
            const startAtFetching = (this.state.firstFetchedTime)
                ? this.state.firstFetchedTime - 1
                : this.state.firstFetchedTime;

            const commentsDbRef = (!this.state.firstFetchedTime)

                ? firebase.database()
                    .ref('admin-comments')
                    .orderByChild('p')
                    .limitToLast(bComments)
                : firebase.database()
                    .ref('admin-comments')
                    .orderByChild('p')
                    .endAt(startAtFetching)
                    .limitToLast(bComments)

            const snap = await commentsDbRef.once("value");
            if (!snap.numChildren()) {
                this.setState({ isEmptyAjax: true });
                return;
            }

            let lastFetchedTime = 0,
                firstFetchedTime = 0;
            let comments = [...this.state.comments];
            let newComments = []
            snap.forEach(element => {
                if (!firstFetchedTime) firstFetchedTime = element.val().p;
                newComments.unshift({
                    ...element.val(),
                    id: element.key
                })
                lastFetchedTime = element.val().p;
                //    console.log("element", element.val(), element.key)
            })
            newComments = comments.concat(newComments);
            this.setState({ lastFetchedTime, firstFetchedTime, comments: newComments });


        } catch (error) {
            const { tl = {} } = this.props;
            this.props.addErrorNotesWithPayload({
                title: tl.comments_not_added,
                body: tl.ops_something_went_wrong + tl.comments_not_added + "."
            })
        }
    }


    mapComments = () => {
        const arrayOfComments = [...this.state.comments];
        return arrayOfComments.map(element =>
            <ListedSingleAdminComment
                key={element.id}
                {...element}
            />)
    }

    mapNewComments = () => {
        const arrayOfComments = [...this.state.newComments];
        return arrayOfComments.map(element =>
            <ListedSingleAdminComment
                key={element.id}
                {...element}
            />)
    }

    openNotes = () => {
        this._isMounted && this.setState({ isOpenRemoveCommentModal: true });
    }
    closeNotes = () => {
        this._isMounted && this.setState({ isOpenRemoveCommentModal: false });
    }

    deleteAllComments = async () => {
        try {
            await firebase.database()
                .ref('admin-comments').remove();
            this.setState({ newComments: [], comments: [] })
            this.closeNotes();
        }
        catch (err) {
            this.closeNotes();
        }
    }

    render() {
        const { isEmptyAjax } = this.state;
        const { tl = {} } = this.props;
        return (

            <Fragment>{
                (this.props.canWrite)
                    ? null
                    : <div>
                        <ConfirmNoteOutput
                            notes={{
                                title: "Delete all comments?",
                                body: "Are you sure",
                                closeNotesText: tl.cancel_t,
                                confirmNotesText: tl.remove_t
                            }}
                            //closeNotes example: function to cancel Delete user
                            closeNotes={this.closeNotes}
                            //confirmNotesBody example: function to Delete user
                            confirmNotesBody={this.deleteAllComments}
                            isOpen={this.state.isOpenRemoveCommentModal}
                        />

                        <h2> Admin Notes - {tl.comments_t} </h2>

                        <button
                            onClick={this.openNotes}
                            className="error-button float-right"
                        > Delete All comments</button>
                        <div className="py-4"></div>
                        {this.mapNewComments()}
                        {this.mapComments()}
                        {
                            (isEmptyAjax)
                                ? <div className="c-whitish my-3">{tl.no_more_comments}</div>
                                : <button className="success-button" onClick={this.fetchComments} >{tl.load_more}</button>

                        }
                    </div>
            }
            </Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, { addErrorNotesWithPayload })(ListAdminComments);


