// add profiles img and name in redux, so we dont need to fetch same profile for every comment



export const profilesAdd = (payload) => {
    return {
        type: "profiles/ADD",
        payload
    }
}


