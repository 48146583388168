
//import { languageTranslation } from '../translations';
import { languagesConfig } from '../../config/languages';

import { english } from '../translations/translateEnglish';
import { serbian } from '../translations/translateSerbian';
import { france } from '../translations/translateFrance';

// if someone messes with local storage
//maybe try to add unkown language and brakes the app

const knownLanguage = localStorage.getItem("language");
const foundedLanguage = languagesConfig.find(el => el === knownLanguage);
const languageFromLocal = foundedLanguage || "en";

const initialState = {
    default: languageFromLocal,
    en: english,
    sr: serbian,
    fr: france
}

//changeLanguageAction
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "toggle/CHANGE_LANGUAGE":
            const newState = {
                ...state,
                default: payload,
            }

            return newState;

        default:
            return state;
    }
}