import React, { Component } from 'react';
import { connect } from "react-redux";
import SortAnalysesValues from './SortAnalysesValues';
import { firestoreDB } from "../../../config/fbConfig";
import {
    toggleLoader,
    toggleIsActiveAnalysisTab
} from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';

import {
    analysesAdd,
    analysesRemove
} from '../../../store/actions/analyses'
import moment from 'moment';
import MapAnalyses from './MapAnalyses';
import MapActiveAnalyses from './MapActiveAnalyses';
import PagesHelmet from '../../utils/helmet/PagesHelmet';
import { htmlArray } from '../../../config/metaConfig';

const fetchPerLoad = 12;

class Analyses extends Component {

    //  tipsterProfits: false, //sortBy za statistics     1. profitu tipstera.
    //dodaj pobevni url kada gledamo statistiku nekog tipstera, da pogledamo samo njegove analize

    state = {
        isAnalysesActive: true,
        sortByDay: true,
        startDate: new Date(),
        analysesData: [],
        isFirstView: true,
        endAt: 0,
        isLoadButtonVisible: true,
        analysesActiveArray: []
    }

    componentDidMount() {

        this.setState({
            analysesActiveArray: this.props.activeAnalyses,
            analysesData: this.props.analysesData,
            isAnalysesActive: this.props.isActiveAnalysisTab,
            sortByDay: this.props.sortByDay,
            startDate: this.props.startDate,
            endAt: this.props.endAt
        });
        document.getElementById("top-navbar").scrollIntoView();
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeAnalyses !== this.props.activeAnalyses) {
            this.setState({
                analysesActiveArray: this.props.activeAnalyses,
            })
        }

    }

    //   iz update analize da iskoristim  funkciju ako moze
    fetchAnalysisValues = async (isReset = false) => {
        const { tl = {} } = this.props;
        try {
            this.props.toggleLoader(true);
            //  const { language } = this.state;
            //  const analysisId = this.props.match.params.id;
            const { isAnalysesActive, sortByDay, startDate } = this.state;
            const collectionName = (isAnalysesActive) ? 'analysis-active' : 'analysis-archive'
            let/* startAt,*/ endAt
            if (sortByDay) {
                //    startAt = moment(startDate).seconds(0).minutes(0).hours(0).unix();
                endAt = moment(startDate).add(1, 'days').seconds(0).minutes(0).hours(0).unix();
            } else {
                //   startAt = moment(startDate).unix();
                endAt = moment(startDate).add(2, 'hours').unix();
            }

            if (this.state.endAt && !isReset) {
                endAt = this.state.endAt;
            }

            let analysisDocs
            if (isAnalysesActive) {
                /*   analysisDocs = await firestoreDB
                   .collection(collectionName)
                     .where("startDateToUnix", ">=", startAt)
                  // .where("startDateToUnix", "<=", endAt)
                   .orderBy('startDateToUnix', 'asc').limit(fetchPerLoad)
                   .get();*/
                this.setState({
                    analysesActiveArray: this.props.activeAnalyses
                });
            } else {
                analysisDocs = await firestoreDB
                    .collection(collectionName)
                    //  .where("startDateToUnix", ">=", startAt)
                    .where("startDateToUnix", "<=", endAt)
                    .orderBy('startDateToUnix', 'desc').limit(fetchPerLoad)
                    .get();
            }


            //  ref.orderBy('hour').startAt(1514184967000).endAt(1514271367000)) za RealtimeDB statistics
            //kasnije videcemo za infinite scroll ili paginate uopste, sad hvatamo po 10 analiza
            //   let analysesData = await (function() { return [...this.state.analysesData]});

            let analysesData = [...this.state.analysesData]

            if (!analysisDocs.empty) {
                let LastDocsDateToUnix = endAt;

                analysisDocs.forEach((doc) => {
                    LastDocsDateToUnix = doc.data().startDateToUnix
                    analysesData.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                //  const newAnalysesArray = this.state.analysesData.concat(analysesData);

                this.props.analysesAdd({
                    ...this.state,
                    analysesData,
                    endAt: LastDocsDateToUnix - 1
                });
                this.setState({
                    analysesData,
                    endAt: LastDocsDateToUnix - 1
                });
                this.props.toggleLoader(false);

                if (analysesData.length < fetchPerLoad)
                    this.setState({ isLoadButtonVisible: false })
            } else {
                const { tl = {} } = this.props;
                this.setState({ isLoadButtonVisible: false })
                this.props.toggleLoader(false);
                this.props.addErrorNotesWithPayload({
                    title: tl.no_analysis_t,
                    body: tl.analysis_to_be_loaded
                })
            }

        } catch (error) {
            //     console.log("error", error)
            this.setState({ isLoadButtonVisible: false })
            this.props.toggleLoader(false);
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_fetched,
                body: tl.ops_something_went_wrong + tl.analysis_not_fetched + "."
            })
        }
    }

    handleDatePickerChange = startDate => {
        this.setState({
            startDate
        });
    };

    handleQueryValues = (e) => {

        //search-analyses

        if (e.target.id === "search-analyses") {
            this.props.analysesRemove();
            this.setState((prevState) => {
                return {
                    isFirstView: !prevState.isFirstView,
                    analysesData: [],
                    endAt: 0,
                    isLoadButtonVisible: true
                }
            });
            return this.fetchAnalysisValues(/*isReset : */true);
        }


        if (e.target.id === "analyses-active-dropdown") {
            this.props.toggleIsActiveAnalysisTab(true);
            this.setState({ isAnalysesActive: true });
        }


        if (e.target.id === "analyses-archive-dropdown") {
            this.props.toggleIsActiveAnalysisTab(false);
            this.setState({ isAnalysesActive: false });
        }


        if (e.target.id === "sortbyday-dropdown")
            this.setState({ sortByDay: true });

        if (e.target.id === "sortbydayhour-dropdown")
            this.setState({ sortByDay: false });

    }

    loadMore = () => {
        if (this.state.analysesData)
            this.fetchAnalysisValues();
    }

    render() {
        const { isAnalysesActive } = this.state;
        const { tl = {} } = this.props;
        const appMetaData = htmlArray
            .find(elem => elem.fileName === "analysis.html");

        return (
            <div>
                <h1 className="text-center"> Analyses </h1>
                <PagesHelmet
                    descriptionMeta={appMetaData.description}
                    clientURLMeta={appMetaData.urlMeta}
                    titleMeta={appMetaData.title}
                    imageMeta={appMetaData.image}
                />
                <SortAnalysesValues
                    handleQueryValues={this.handleQueryValues}
                    loadMore={this.loadMore}
                    handleDatePickerChange={this.handleDatePickerChange}
                    {...this.state}
                />
                {
                    (isAnalysesActive)
                        ? <MapActiveAnalyses
                            isAnalysesActive={this.state.isAnalysesActive}
                            analysesActiveArray={this.state.analysesActiveArray}
                            history={this.props.history} />

                        : <MapAnalyses
                            isAnalysesActive={this.state.isAnalysesActive}
                            analysesData={this.state.analysesData}
                            analysesActiveArray={this.state.analysesActiveArray}
                            history={this.props.history} />
                }

                {
                    (this.state.analysesData.length && this.state.isLoadButtonVisible && !isAnalysesActive)
                        ? <button
                            onClick={this.loadMore}
                            className="success-button ml-2 mt-2"
                        > {tl.load_more}
                        </button>
                        : null
                }
            </div>
        );
    }
}


const mapStateToProps = ({ toggle, analyses, activeAnalyses, tl }) => {
    return {
        loader: toggle.loader,
        isAnalysesActive: analyses.isAnalysesActive,
        sortByDay: analyses.sortByDay,
        startDate: analyses.startDate,
        analysesData: analyses.analysesData,
        tl: tl[tl.default],
        endAt: analyses.endAt,
        activeAnalyses,
        isActiveAnalysisTab: toggle.isActiveAnalysisTab
    }
}



export default connect(mapStateToProps, {
    toggleLoader,
    addErrorNotesWithPayload,
    addSuccessNotesWithPayload,
    analysesAdd,
    analysesRemove,
    toggleIsActiveAnalysisTab
})(Analyses);