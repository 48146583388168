import React, { Component } from 'react';

class Spinner extends Component {

    render() {
        return (

            <div className="lds-spinner-wrapper" >
                <div className="lds-spinner-bg" ></div>
                <div className="lds-spinner">

                    <div></div>
                    <div></div>
                    <div>
                    </div><div>
                    </div><div>
                    </div><div>
                    </div><div>
                    </div><div>
                    </div><div>
                    </div><div>
                    </div><div>
                    </div><div>
                    </div>
                </div>
            </div>


        )
    }

}

export default Spinner


