import React, { Component } from 'react';
import { connect } from "react-redux"
import { sports } from '../../../config/sports';
import { bettingHouses } from '../../../config/bettingHouses';
import DateAndTime from '../../utils/dateAndTime/DateAndTime';
import Input from '../../utils/inputs/Input';
import { stakeArray } from '../../../config/stake';
import { ticketCategories } from '../../../config/categories';

class AddTicketValues extends Component {

    state = {
        sports: sports,
        bettingHouses: bettingHouses,
        stakeArray: stakeArray,
        ticketCategories: ticketCategories
    }

    mapSports = () => {
        return this.state.sports.map(elem =>
            <option
                key={elem}
                value={elem}
                defaultValue={elem === sports[0]}
            >{elem}</option>
        )
    }

    mapBettingHouses = () => {
        return this.state.bettingHouses.map(elem =>
            <option
                key={elem}
                value={elem}
                defaultValue={elem === bettingHouses[0]}
            >{elem}</option>
        )
    }


    mapStakes = () => {
        return this.state.stakeArray.map(elem =>
            <option
                key={elem}
                value={elem}
                defaultValue={elem === stakeArray[0]}
            >{elem}</option>
        )
    }


    mapCategories = () => {
        return this.state.ticketCategories.map(elem =>
            <option
                key={elem}
                value={elem}
                defaultValue={elem === ticketCategories[0]}
            >{elem}</option>
        )
    }


    render() {

        const {
            sport = sports[0],
            startDate = "", //new
            hostTeam = "", //new
            guestTeam = "",  //new
            tip = "", //new
            odds = "",  //odds
            stake = "", //stake,
            bettingHouse = bettingHouses[0], // array to import from db 
            //   authorId = "",
            //   authorName = "",
            category = "",
            tl = {},
        } = this.props;

        return (
            <div id="add-ticket-values">
                {/*
                    (authorId && authorName)
                        ? <div>
                            vidi samo admin itd...
                            authorid za link {authorId} autor analize {authorName}
                        </div>
                        : null

                */ }


                <div className="form-row mt-2">
                    <div className="form-group col-sm-6">
                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_host_team}
                            id="inputHostTeam"
                            label={tl.enter_host_team}
                            name="hostTeam"
                            value={hostTeam}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                            dataLength={17}
                        />
                    </div>

                    <div className="form-group col-sm-6">
                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_guest_team}
                            id="inputGuestTeam"
                            label={tl.enter_guest_team}
                            name="guestTeam"
                            value={guestTeam}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                            dataLength={17}
                        />
                    </div>
                </div>


                <div className="form-row">

                    <div className="form-group col-sm-4">

                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_tip}
                            id="inputTip"
                            label={tl.enter_tip}
                            name="tip"
                            value={tip}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                            dataLength={20}
                        />

                    </div>


                    <div className="form-group col-sm-4">

                        <DateAndTime
                            startDate={startDate}
                            handleDatePickerChange={this.props.handleDatePickerChange}
                        />

                    </div>


                    <div className="form-group col-sm-4">

                        <Input
                            type="text"
                            typeToggle={false}
                            placeholder={tl.enter_odds}
                            id="inputOdds"
                            label={tl.enter_odds}
                            name="odds"
                            value={odds}
                            onChange={this.props.handleMetaChange}
                            required={true}
                            className="custom-input-sm"
                        />

                    </div>

                </div>

                <div className="form-row">


                    <div className="form-group col-sm-6">
                        <label htmlFor="inputSport"
                            className="c-label-dropdown"
                        > {tl.sport_t}
                        </label>
                        <select
                            id="inputSport"
                            className="select-custom-form"
                            name="sport"
                            value={sport}
                            onChange={this.props.handleMetaChange}
                        >
                            {this.mapSports()}
                        </select>
                    </div>
                </div>

                <div className="form-row mb-5">
                    <button
                        onClick={this.props.addSinglesToArray}
                        className="success-button"
                    > {tl.add_match_t} </button>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-4">

                        <label htmlFor="inputBettingHouse"
                            className="c-label-dropdown"
                        >{tl.stake_t}</label>
                        <select
                            id="inputStakeTicket"
                            className="select-custom-form"
                            name="stake"
                            value={stake}
                            onChange={this.props.handleMetaChange}
                        >
                            {this.mapStakes()}
                        </select>
                    </div>

                    <div className="form-group col-sm-4">
                        <label
                            htmlFor="inputBettingHouse"
                            className="c-label-dropdown"
                        > {tl.betting_house_t}</label>
                        <select
                            id="inputBettingHouse"
                            className="select-custom-form"
                            name="bettingHouse"
                            value={bettingHouse}
                            onChange={this.props.handleMetaChange}
                        >
                            {this.mapBettingHouses()}
                        </select>
                    </div>

                    <div className="form-group col-sm-4">
                        <label
                            htmlFor="inputCategories"
                            className="c-label-dropdown"
                        >{tl.ticket_categories}</label>
                        <select
                            id="inputCategory"
                            className="select-custom-form"
                            name="category"
                            value={category}
                            onChange={this.props.handleMetaChange}
                        >
                            {this.mapCategories()}
                        </select>
                    </div>

                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, null)(AddTicketValues);