import React, { Component } from 'react';
import { connect } from "react-redux"
import Input from '../../utils/inputs/Input';
import { updateName } from '../../../store/actions/auth';

class UpdateName extends Component {

    state = {
        username: '',
        errorMessage: ''
    };

    updateName = () => {
        console.log("email", this.state.username)
        this.props.updateName(this.state.username);
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.updateName();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }

    render() {
        return (


            <div className="p-1">
              
                <Input
                    type="text"
                    label="Enter name"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    required={true}
                />

                <div className="container d-flex justify-content-center">
                    <button
                        className="success-button"
                        onClick={this.updateName}
                    > Update Name</button>
                </div>
            </div >

        )
    }

}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps, { updateName })(UpdateName);

