import Soccer from '../assets/balls/soccer.svg';
import Basketball from '../assets/balls/basketball.svg';
import Baseball from '../assets/balls/baseball.svg';
import Handball from '../assets/balls/handball.svg';
import IceHockey from '../assets/balls/puck.svg';
import Rugby from '../assets/balls/american-football.svg';
import Tennis from '../assets/balls/tennis.svg';
import Volleyball from '../assets/balls/volleyball.svg';
import Snooker from '../assets/balls/snooker.svg';
import Waterpolo from '../assets/balls/waterpolo.svg';
import DotaTwo from '../assets/balls/dota-2.svg';
import CsGo from '../assets/balls/csgo.svg';
export const sports = [
    "Soccer",
    "Basketball",
    "Tennis",
    "Volleyball",
    "Baseball",
    "Football",
    "Waterpolo",
    "Ice hockey",
    "Handball",
    "Snooker",
    "Rugby",
    "CS GO",
    "DOTA 2"
]

export const balls = {
    "Soccer": Soccer,
    "Basketball": Basketball,
    "Tennis": Tennis,
    "Volleyball": Volleyball,
    "Baseball": Baseball,
    "Football": Soccer,
    "Waterpolo": Waterpolo,
    "Ice hockey": IceHockey,
    "Handball": Handball,
    "Snooker": Snooker,
    "Rugby": Rugby,
    "CS GO": CsGo,
    "DOTA 2": DotaTwo
}