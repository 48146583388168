/*
    n: authorName,
    t: 0,  //numArchivedTips
    s: 0, //sumStake
    o: 0,  //sumOdds
    w: 0, //win
    d: 0,  // lose
    l: 0, // draw
    p: 0, //profit
    yieldValue: 0
   */

  const initialstate = []

  const calcStatsData = (data) => {
      const yieldValue = (Math.round(data.p * 10000 / data.s) / 100);
      const averageOdds = (Math.round(data.o * 100 / data.t) / 100);
      const profit = (Math.round(data.p * 100) / 100);
      return { ...data, yieldValue, averageOdds, p: profit }
  }
  
  const updateStatsData = (oldState, newData) => {
      const yieldValue = (Math.round(newData.p * 10000 / newData.s) / 100);
      const averageOdds = (Math.round(newData.o * 100 / newData.t) / 100);
      const profit = (Math.round(newData.p * 100) / 100);
      const newTipsterObject = { ...newData, yieldValue, averageOdds, p: profit }
      const newState = [...oldState].filter(tipster => tipster.key !== newData.key);
      return [...newState, newTipsterObject]
  }
  
  const monthlyStatisticsReducer = (state = initialstate, action) => {
      switch (action.type) {
  
          case "statistics/GET_MONTH":
        //    console.log("dagaTOooooo", action.payload)
              return [...state, calcStatsData(action.payload)];
  
          case "statistics/GET_UPDATED_MONTH":
           //   console.log("daga", action.payload)
              return updateStatsData(state, action.payload);
  
  
          default:
              return state
      }
  }
  
  export default monthlyStatisticsReducer;