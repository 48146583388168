import firebase from "../../../config/fbConfig";
import moment from 'moment';



export const fetchTicketValues = async function (isReset = false) {

    try {

        const fetchPerLoad = 2;
        this.props.toggleLoader(true);
        const { isTicketActive, startDate } = this.state;
        let endAt = moment(startDate)
            .add(1, 'days')
            .seconds(0).minutes(0).hours(0).unix() * 1000;

        if (this.state.endAt !== 0 && !isReset) {
            endAt = this.state.endAt;
        }
        //   console.log("endAt", endAt)
        let ticketDocs
        if (!isTicketActive) {
            const archivedTicketsDbRef =
                firebase.database()
                    .ref('archived-tickets')
                    .orderByChild('f')
                    //     .orderBy('f') //index u bolts
                    .endAt(endAt)
                    .limitToLast(fetchPerLoad)
            const snapshot = await archivedTicketsDbRef.once('value');

            ticketDocs = snapshot.val();
        }




        //  ref.orderBy('hour').startAt(1514184967000).endAt(1514271367000)) za RealtimeDB statistics
        //kasnije videcemo za infinite scroll ili paginate uopste, sad hvatamo po 10 analiza
        //   let analysesData = await (function() { return [...this.state.analysesData]});

        // let ticketData = [...this.state.ticketData]
        let newTicketData = []
        if (ticketDocs) {


            Object.keys(ticketDocs).forEach(key => {

                newTicketData.unshift({
                    id: key,
                    ...ticketDocs[key]
                })
                //    console.log(`${key} : ${ticketDocs[key]}`);
            });

            //     console.log("ticketData", newTicketData, "endAT dole", endAt, "LastDocsDateToUnix dole", LastDocsDateToUnix)


            let ticketData = [...this.state.ticketData, ...newTicketData]

            this.props.archivedTicketAdd({
                ...this.state,
                ticketData,
                endAt: newTicketData[newTicketData.length - 1].f - 1
            });
            this.setState({
                ticketData,
                endAt: newTicketData[newTicketData.length - 1].f - 1
            });
            this.props.toggleLoader(false);

            if (newTicketData.length < fetchPerLoad)
                this.setState({ isLoadButtonVisible: false })
        } else {

            this.setState({ isLoadButtonVisible: false })
            this.props.toggleLoader(false);
            if (this.state.ticketData.length === 0)
                this.props.addErrorNotesWithPayload({
                    title: "Tickets are not found",
                    body: "The tickets you are looking for might have been removed or are temporarily unavailable."
                })
        }

    } catch (error) {
       // console.log("error", error)
        this.setState({ isLoadButtonVisible: false })
        this.props.toggleLoader(false);
        this.props.addErrorNotesWithPayload({
            title: "Archived tickets are not fetched",
            body: "Oops, something went wrong. Tickets are not fetched."
        })
    }
}