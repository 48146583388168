import React, { Component } from 'react';
import { connect } from "react-redux"
import rectFallBack from '../../../assets/fallback/rect.svg';
import Img from 'react-image';
class AboutSr extends Component {

    render() {
      
        return (
            <div>
               


                <div className="ml-3 pt-2">
                    <span className="lead-red-text">Ninca90</span>
                    <span className="lead-white-text"> - najbolji tipster na svetu(2016,17)</span>
                </div>

                <div>
                    <div className="p-3 body-about-text">
                        <div>
                            <span>Za početak pozdrav svim ljubiteljima sporta i klađenja. Dvogodišnji sam uzastopni pobednik na renomiranom tipsterskom sajtu&nbsp;
                                         <a href="https://www.bettingexpert.com/user/profile/ninca90"
                                    className="body-about-colored">
                                    Betting Expert</a>.&nbsp;
Put do tog dostignuća bio je više nego buran i zanimljiv. Da bih do tog ostvarenja došao morao sam da prevalim dugu distancu od kladioca rekreativca do nezvanično&nbsp;
                                              <a href="https://calvinayre.com/2017/10/02/press-releases/bettingexpert-com-crown-ninca90-world-tipster-champion-2017-second-year-e10000-reward/" className="body-about-colored">
                                    najboljeg tipstera na Svetu.
                                              </a>
                            </span>
                        </div>
                        <div className="pt-3 pb-3">

                            <Img src={["img/about/mockup-ninca.png", rectFallBack]} className="about-pictures" alt="Mockup" />

                        </div>


                        <div className="body-about-text pt-3">
                            Treba razgraničiti šta je kladilac, a šta tipster.<br />
                            <span className="body-about-colored-blue"><em>Kladilac</em></span> &nbsp; je onaj koji se kladi za određenu svotu novca kako bi napravio profit od tog klađenja i time svesno ulazi u rizik da taj novac izgubi.<br />
                            <span className="body-about-colored-blue"><em>Tipster</em></span> &nbsp; je onaj koji daje predloge za klađenje drugima, na koje može sam da se kladi, ali i ne mora. Zavisi od okolnosti(u daljem tekstu objašnjenje)
									<br />
                            Moj početak u vezi sa ovime vezan je za praćenje sporta. Ne mogu ni po kojem osnovu da tvrdim da neko ko ne prati sport neće imati uspeha sa sportskim klađenjem. Te dve stvari su što se tiče mene usko povezane, ali i na jedan vrlo čudan način sasvim posebne. Naravno, sjajno je, i dodatni plus ako se dobro razumete
                             u pojedine sportove, lige, timove i sl. ali to nije i presudno
                             u mojoj praksi. Detaljnije o tome možete pogledati u temi o&nbsp;
                             <a href="https://ninca90.com" className="body-about-colored">klađenju</a>.
								</div>

                        <div className="about-border">
                            &nbsp;
								</div>

                        <div className="pt-3">
                            <span>
                                Sport je zabava za milione ljudi, za neke je i više od toga.
                                Pored besplatne zabave i gledanja sportskih mečeva uživo i
                                 preko TV-a, ja sam jednu drugu vrstu zabave plaćao.
                                 Klađenje na ishode sportskih događaja. Ta zabava je kompletno maskirala
                                 onu prvu jer je donosila mnogostruko veću dozu adrenalina.
                                 To je bilo jednostavno klađenje bez bilo kakve strategije i rezona.
                                  Klađenje na sopstvene favorite, ponekad čak i nasumice. Uzimanje
                                   raznih dojava, nameštenih mečeva,&nbsp;<a href="https://ninca90.com" className="body-about-colored">sigurica</a> po internetu. Kada nekog kladioca pitate kako stoji sa novcem recimo zadnjih godinu-dve dobićete odgovor "Tu sam negde" ili još gore "Ko zna koliko sam pukao", a nastaviti da se kladi po ustaljenom režimu.
									</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures " src={["img/about/kockar.jpeg", rectFallBack]} />
                        </div>

                        <div>
                            <span>
                                Dakle, vođenje računa(banke) je prva stavka koja se mora prihvatiti u cilju dokazivanja tvrdnje da smo u plusu na kladionici ili nekoj<br /> "pozitivnoj nuli".<br />
                                Ne samo zbog pukog dokazivanja, već i praćenja celokupnog bilansa (profita). Posle 5 godina intenzivnog klađenja rešio sam da počnem sa mesečnim preračunavanjem i nakon samo par meseci uvideo da ću po šablonu i načinu na koji se kladim samo nastaviti da srljam u debeli minus. Takva "zabava" mi se više nije svidela... Napravio sam pauzu i počeo da se bavim drugim aktivnostima.<br />
                                Pošto su mi i dalje bile primarne web stranice sa sportskim sadržajem nekako sam naleteo na Betting Expert, sajt koji nudi besplatne tipove i priređuje takmičenje u tipovanju konačnih rezultata sportskih događaja sa vrlo pristojnim nagradnim fondom. Primarni cilj tog sajta je da ponudi prognoze i analize sportskih događaja kao svojevrsnu pomoć. Ipak treba se opredeliti za prave tipstere, a to je tamo vrlo lako jer je statistika profita svih tipstera na sajtu vrlo transparentna.
									</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/betting_expert.png", rectFallBack]} />
                        </div>

                        <div className="about-border">
                            &nbsp;
						</div>

                        <div className="pt-3">
                            <span>
                                Prvih 7-8 meseci kao tipstera početnika bilo je za mene vrlo neuspešno. Usvojio sam loše navike iz dana klađenja i sada mogao tačno da vidim na svojoj tabeli profita koliko bi me to koštalo da sam se stvarno kladio. Još uvek nisam mogao da pomognem sebi, a kamoli drugima svojim tipovima. Ipak, sve to je imalo i dobre strane. Malo po malo počinjao sam da shvatam gde grešim. Jedan od ključnih nalaza je bio onaj da dobra procena konačnih ishoda događaja i nema neki veliki značaj, pa bila ona i visokoprocentna, ako kvote ne budu imale&nbsp;
                                 <span className="body-about-colored-blue">VALUE(vrednost)</span>
                                .&nbsp; Kraće rečeno, kvote diktiraju celu igru. Tako sam počeo da se zanimam za Teoriju verovatnoće.
							</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/kvote.jpg", rectFallBack]} />
                        </div>

                        <div className="pt-3">
                            <span>
                                Nekih mesec dana je trajao taj prelazni period od konstantnog gubitnika do kladioca/tipstera koji je dugoročno gledano uvek u plusu. Mnogo stvari, od strpljenja pa do discipline u klađenju je trebalo doterati. Dosta se redova literature moralo pročitati... Ne treba posebno naglašavati da sam u međuvremenu ponovo počeo da se kladim i to sa taktikom kojom i tipujem rezultate na Betting Expert takmičenju, ali i još par sajtova pride.<br />
                                Novac je konačno počeo da dolazi, samim tim počela su da se zatvaraju vrata
                                 kladionica. Online, ali i onih lokalnih. Novčani limiti, spuštanje
                                kvota, pa čak i restrikcije postale su uobičajen obrazac ponašanja
                                kladionica u ovim situacijama. Kladionice smatraju da niste isplativi
                                za njihovo tržište ako ste dobitan igrač, njima trebaju igrači koji igraju
                                 iz zabave i koje ne zanima mnogo novčana korist. Oni igrači koji se
                                  oslanjaju na sujeverja i neke druge više sile. Problem je
                                   što dosta njih ima i problem sa impulsivno kompulzivnim poremećajem 
                                   kockanja <a href="https://ninca90.com" className="body-about-colored">(rizici)</a>. Kladionice ove igrače retko kad isključuju iz igre. Zašto bi kad im donose najveći profit. Pribegavanje raznim metodama kako bi se zaobišli limiti i restrikcije kladionica naravno postoje. Ulozi na jedan par ne smeju da budu visoki i potrebno je naći što vise value opklada. Ipak posle nekog vremena konstantnog dobijanja kladionice će vas jednostavno ugasiti. Dupli nalozi su kasnije jedino rešenje... Svako ko je uporan i snalažljiv uspeće da odigra svoj value bet. Ipak, jedno je sigurno. Svakim zarađenim dinarom postaje sve napornije doći do novog.
									</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/cica-lova.jpeg", rectFallBack]} />
                        </div>

                        <div className="about-border">
                            &nbsp;
						</div>

                        <div className="pt-3">
                            <span>
                                Mesecima je moj novčani bilans rastao ali i rejting u svetu tipovanja i klađenja. Posle par mesečnih pobeda na Betting Expert takmičenjima uspeo sam da uđem u konkurenciju za godišnji specijal Tipster of the Year i posle velike borbe do poslednjih dana juna, pobedio Zamija dvogodišnjeg uzastopnog prvaka pre mene. To mi je donelo&nbsp;
                                 <span className="body-about-colored-blue">
                                    <strong>10.000 €</strong>
                                </span>&nbsp; i posetu
                                     &nbsp;<span className="body-about-colored-blue"><strong>Kopenhagenu</strong></span>&nbsp;
                                i kancelariji firme na par dana.
									</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/reward2016.jpg", rectFallBack]} />
                        </div>

                        <div>
                            <span>
                                Slično se ponovilo i u narednoj sezoni. Ponovo je bila mrtva trka do poslednjeg meseca. Sa maltene duplo većim profitom i procentom uspešnosti uspeo sam da odbranim titulu, koja je sada promenila naziv u World tipster champion. Ista nagradna suma i isti put za glavni grad Danske. Ponovni susret sa glavnim ljudima u firmi <a href="https://bettercollective.com/" className="body-about-colored" >Better Collective</a> koja poseduje već spomenuti sajt i ima vodeću ulogu na globalnom nivou što se tiče transparentnosti u industriji klađenja. Ovog puta bilo je svakakvih aktivnosti, jedna od njih je bila i intervju za Youtube kanal.
							</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/ninca-champion.jpg", rectFallBack]} />
                        </div>

                        <div className="pt-3 pb-3">
                            <span>
                                O svom klađenju mogu da kažem to da se najviše kladim na košarku. U nekim procentima to je možda čak i 60% svih opklada. Zatim ide fudbal, pa svi ostali sportovi: rukomet, tenis, odbojka, hokej na ledu, američki fudbal, snuker, ragbi, bejzbol… Stvar je u tome da košarku dosta pratim. Poznajem srž same igre. Takođe što se tiče pametnog klađenja u tom sportu ima mnogo manipulacija i varijacija sa kvotama, hendikepima i over/under granicama. To pokušavam da iskoristim do maksimuma. Moj yield(procenat uspešnosti) u klađenju na košarku je 15-20%.<br />
                                <span className="body-about-colored-blue"><strong><em>"NA 5 DINARA PRAVIM 1 KONSTANTNO"</em> - Ninca90.</strong></span><br />                                
                                </span>
                        </div>

                        <div className="iframe-container">


                            <iframe width="560" height="415" title="ninca-interview" className="iframe-default" src="https://www.youtube.com/embed/AVN9xpeClKY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>

                        <div className="pt-3 pb-3">
                            <span>
                                Ovaj sajt bi trebalo da bude pomoć svima koji se klade, neka smernica onima koji se klade samo iz zabave, ali i onima koji žele malo dublje da uđu u svet klađenja. Pokušaću da sa timom ljudi koji je oko mene prenesem znanje opširnom akademijom i kvalitetnim tipovima i analizama.
							</span>
                        </div>
                        <div className="iframe-container">
                            <iframe src="https://www.youtube.com/embed/p2RQ-9twH84" title="ninca-winner" className="iframe-default" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>

                        <div className="pt-3">
                            <span className="welcome-caption-text"><strong>DOBRODOŠLI I HAJDE DA ZADAJEMO UDARCE KLADIONICAMA!</strong></span>
                        </div>



                    </div>




                </div>
            </div>



        );
    }
}




const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(AboutSr);