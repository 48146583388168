import React from "react";
import { connect } from "react-redux";



class SearchArchiveBtn extends React.Component {


    componentDidMount() {

        if (this.props.analysesData.length === 0) {
            this.btnClick();
        }
    }


    btnClick = () => {
        const e = {
            target: {
                id: "search-analyses"
            }
        }
        this.props.handleQueryValues(e);
    }


    render() {
        const { tl = {} } = this.props;

        return (
            <button
                id="search-analyses"

                onClick={this.btnClick}
                className="success-button"
            >
                {tl.show_t}
            </button>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}
export default connect(mapStateToProps, null
)(SearchArchiveBtn);

