import React, { Component, Fragment } from 'react';
import firebase from '../../../config/fbConfig';
import Img from 'react-image';
import { connect } from "react-redux";
import userAvatar from '../../../assets/user-avatar.png';
import moment from 'moment';
import ConfirmNoteOutput from '../notes/ConfirmNoteOutput';
import ReplyComment from './ReplyComment';
import { profilesAdd } from '../../../store/actions/profiles'
class ListedSingleComment extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            name: "",
            photoURL: "not",
            isOpenRemoveCommentModal: false,
            replayAuthorName: ""
        }
    }



    componentWillUnmount() {
        this._isMounted = false;
    }

    removeComment = (e) => {

        const { commentsRefId, id } = this.props;
        firebase.database()
            .ref('blogs-comments/' + commentsRefId).child(id).remove();
    }



    openNotes = () => {
        //  console.log("hit", this._isMounted)
        this._isMounted && this.setState({ isOpenRemoveCommentModal: true });
    }
    closeNotes = () => {
        this._isMounted && this.setState({ isOpenRemoveCommentModal: false });
    }


    async componentDidMount() {
        this._isMounted = true;
        const replayAuthorId = this.props.r || "";
        const authorId = this.props.a;

        const profileIsFounded = await this.props.profiles.find(elem => elem.authorId === authorId);
        if (!profileIsFounded)
            firebase.database()
                .ref('profiles/' + authorId).once("value", (snapshot) => {
                    const { n = "", p = "" } = snapshot.val();
                    this.setState({
                        name: n,
                        photoURL: p
                    })
                    this.props.profilesAdd({
                        name: n,
                        photoURL: p,
                        authorId
                    })
                })
        else {
            this.setState({
                name: profileIsFounded.name,
                photoURL: profileIsFounded.photoURL
            })
        }



        if (replayAuthorId) {
            const replayProfileIsFounded =
                await replayAuthorId && this.props.profiles.find(elem => elem.authorId === replayAuthorId);
            if (!replayProfileIsFounded)
                firebase.database()
                    .ref('profiles/' + replayAuthorId).once("value", (snapshot) => {
                        const { n = "", p = "" } = snapshot.val();
                        this.setState({
                            replayAuthorName: n
                        })
                        this.props.profilesAdd({
                            name: n,
                            photoURL: p,
                            authorId: replayAuthorId
                        })
                    })
            else {
                this.setState({
                    replayAuthorName: replayProfileIsFounded.name
                })
            }
        }







    }
    render() {
        const { id, tl = {}, emailVerified } = this.props;

        //   const authorId = this.props.a;
        const comment = this.props.c;
        const postedAtTime = moment(this.props.p || 0).format('DD/MM/YYYY, HH:mm');
        const { name, photoURL, replayAuthorName } = this.state;
        //  dodaj authorId za link kasnije ka profilu
        return (
            <div
                id={"comment-" + id}
                className="comments-wrapper"
            >
                <div className="comments-header">

                    {
                        (photoURL === "not") ? null
                            : <Img
                                src={[photoURL, userAvatar]}
                                alt="user-avatar"
                                className="comments-avatar"
                                decode={false}
                            />
                    }

                    <div>
                        <div className="comments-name">  {name} </div>
                        <small className="comments-time"> {postedAtTime} </small>
                    </div>
                </div>

                <div className="comments-body">
                    <span className="c-aquablue "> {replayAuthorName && `@${replayAuthorName}`} </span>
                    {comment}
                </div>

                {
                    (this.props.adminLevel >= 4) ?
                        <Fragment>
                            <ConfirmNoteOutput
                                notes={{
                                    title: tl.delete_comment_q,
                                    body: tl.are_you_sure_that_you_want_to_remove_message_q,
                                    closeNotesText: tl.cancel_t,
                                    confirmNotesText: tl.remove_t
                                }}
                                //closeNotes example: function to cancel Delete user
                                closeNotes={this.closeNotes}
                                //confirmNotesBody example: function to Delete user
                                confirmNotesBody={this.removeComment}
                                isOpen={this.state.isOpenRemoveCommentModal}
                            />

                            <button
                                onClick={this.openNotes}
                                className="error-button comments-del-btn"
                                id={id}
                            >
                                {tl.remove_t}
                            </button>
                        </Fragment>
                        : null
                }
                {
                    (this.props.uid && emailVerified)
                        ? <ReplyComment
                            commentsRefId={this.props.commentsRefId}
                            authorId={this.props.authorId}
                            feedAuthorId={this.props.feedAuthorId}
                            commentsAuthorId={this.props.a}
                        />
                        : null
                }

            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        adminLevel: state.user && state.user.adminLevel,
        profiles: state.profiles,
        tl: state.tl[state.tl.default],
        uid: state.user.uid || "",
        emailVerified: state.user.emailVerified || false,
    }
}


export default connect(mapStateToProps, {
    profilesAdd
})(ListedSingleComment);
