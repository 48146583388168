import React, { Component } from 'react'
import CountUp from 'react-countup';
import { connect } from 'react-redux';

class Statistics extends Component {

    render() {
        let {
            w,
            d,
            l,
            averageOdds,
            yieldValue,
            t = 0,  //numArchivedTips
            s = 0, //sumStake
            p = 0 //profit
        } = this.props.statistics;

        const numberOfTips = parseInt(t, 10);
        w = parseInt(w, 10);
        d = parseInt(d, 10);
        l = parseInt(l, 10);
        const stake = parseInt(s, 10);
        averageOdds = parseFloat(averageOdds);
        const profit = parseFloat(p);

        const { tl = {} } = this.props;
        if (yieldValue > 0)
            yieldValue = "+" + yieldValue;

        return (
            <div className="mb-3 mr-3">

                <div className="row justify-content-end"

                >
                    <div className="col-10 stats-border">
                        <span className="span-stats">{tl.statistics_ninca_t}</span>
                    </div>
                </div>
                <div className="row d-flex justify-content-end">
                    <div className="m-2">
                        <div className="circle-wrapper">
                            <div className="outer-circle"
                                style={{ borderColor: "#e5e5e5" }}
                            >
                            </div>
                            <span className="text-in-circle c-whitish">
                                <CountUp end={numberOfTips} />
                            </span>
                        </div>

                        <div className="row justify-content-center">
                            <span className="counter-text c-whitish">{tl.number_of_tips}</span>
                        </div>
                    </div>

                    <div className="m-2">
                        <div className="circle-wrapper">
                            <div className="outer-circle"
                                style={{ borderColor: "#e5e5e5" }}
                            >
                            </div>
                            <span className="text-in-circle c-whitish">
                                <CountUp end={stake} />
                            </span>

                        </div>
                        <div className="row justify-content-center">
                            <span className="counter-text c-whitish">{tl.stake_t}</span>
                        </div>
                    </div>

                    <div className="m-2">
                        <div className="circle-wrapper">
                            <div className="outer-circle"
                                style={{ borderColor: "#e5e5e5" }}
                            >
                            </div>
                            <span className="text-in-circle c-whitish">
                                {averageOdds}
                            </span>

                        </div>
                        <div className="row justify-content-center">
                            <span className="counter-text c-whitish">{tl.average_odds_t}</span>
                        </div>
                    </div>
 
                </div>

                <div className="row d-flex justify-content-end">
                    <div className="m-2" >
                        <div className="circle-wrapper">
                            <div className="outer-circle">
                            </div>

                            <span className="text-in-circle c-whitish">
                                <CountUp end={w} /> - <CountUp end={d} /> - <CountUp end={l} />
                            </span>

                        </div>
                        <div className="row justify-content-center">
                            <span className="counter-text c-whitish">{tl.wdl_t}</span>
                        </div>
                    </div>


                    <div className="m-2">
                        <div className="circle-wrapper">
                            <div className="outer-circle border-color-pink"
                               
                            >
                            </div>
                            <span className="text-in-circle c-whitish">
                                {profit}
                            </span>

                        </div>
                        <div className="row justify-content-center">
                            <span className="counter-text  c-whitish">{tl.profit_t}</span>
                        </div>
                    </div>


                    <div className="m-2">
                        <div className="circle-wrapper">
                            <div className="outer-circle border-color-pink"
                            >
                            </div>
                            <span className="text-in-circle c-whitish">
                                {yieldValue}%
                                </span>

                        </div>
                        <div className="row justify-content-center">
                            <span className="counter-text c-whitish">{tl.yield_t}</span>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    const statsDefault = {
        n: "",
        t: 0,  //numArchivedTips
        s: 0, //sumStake
        o: 0,  //sumOdds
        w: 0, //win
        d: 0,  // lose
        l: 0, // draw
        p: 0, //profit
        yieldValue: 0,
        averageOdds: 1,
        key: "0",
        profileURL: ""
    }

    return {
        statistics: state.statistics
            .find(tipster => tipster.n === "Ninca90") || statsDefault,
        tl: state.tl[state.tl.default]
    }
}


export default connect(mapStateToProps, null)(Statistics);