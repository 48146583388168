import React, { Component, Fragment } from 'react';
import TextArea from '../inputs/TextArea';
import firebase from '../../../config/fbConfig';
import { withRouter } from "react-router";
import {
    //  addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';
import { connect } from "react-redux";


class AddComment extends Component {


    constructor(props) {
        super(props);
        this.isClickable = true;
        this.state = {
            comment: ''
        };
    }

    addComment = async () => {
        try {
            const { commentsRefId, authorId, feedAuthorId } = this.props;
            console.log("feedAuthorId", feedAuthorId)
            let { comment } = this.state;
            if (!this.isClickable || !comment.trim().length) return;
            this.isClickable = false;

            const notesObject = {
                u: this.props.match.url,
                n: true,   //new comment
                c: comment,
                a: authorId,
                p: /* postedAt:*/ firebase.database.ServerValue.TIMESTAMP //Date.now()
            }

            const commentsDbRef = firebase.database().ref('blogs-comments/' + commentsRefId);

            const feedCommentsDbRef = firebase.database().ref('feed-comments/' + feedAuthorId);

            const adminCommentsDbRef = firebase.database().ref('admin-comments');
            const commentsDbRefPromise = commentsDbRef.push({
                c: comment,
                a: authorId,
                p: /* postedAt:*/ firebase.database.ServerValue.TIMESTAMP //Date.now()
            });

            const feedCommentsDbRefPromise = feedCommentsDbRef.push(notesObject);
            const adminCommentsDbRefPromise = adminCommentsDbRef.push(notesObject);

            await Promise.all([
                commentsDbRefPromise,
                adminCommentsDbRefPromise,
                feedCommentsDbRefPromise
            ]);


            this.setState({ comment: "" });
            this.isClickable = true;
        } catch (error) {
            const { tl = {} } = this.props
            this.isClickable = true;
            this.props.addErrorNotesWithPayload({
                title: tl.comment_not_posted,
                body: tl.ops_something_went_wrong + tl.comment_not_saved
            })
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.addComment();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }

    render() {
       
        const isVerified = this.props.emailVerified && this.props.uid;
        const { tl = {} } = this.props;
        return (
            <Fragment >
                {
                    (isVerified)
                        ? <Fragment >

                            <div className="add-comment-wrapper mt-5">
                                <TextArea
                                    type="text"
                                    label={tl.enter_comment_label}
                                    name="comment"
                                    value={this.state.comment}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                    required={true}
                                    dataLength={500}
                                    className="add-comment-textarea"
                                />
                                <button
                                    className={
                                        (this.state.comment.length)
                                            ? "success-button"
                                            : "inactive-button"
                                    }
                                    onClick={this.addComment}
                                >
                                    {tl.send_t}
                                </button>
                            </div>
                        </Fragment>
                        : null
                }
            </Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid || "",
        emailVerified: state.user.emailVerified || false,
        tl: state.tl[state.tl.default]
    }
}

const AddCommentWithRouter = withRouter(AddComment);

export default connect(mapStateToProps, { addErrorNotesWithPayload })(AddCommentWithRouter);


