import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { changeRouteValue } from '../../../store/actions/viewData';

class LeftHomeBanner extends Component {


    addNewRoute = (payload) => () => {
        if (this.props.uid) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload);
    }


    render() {
        const { tl = {} } = this.props;
        return (
            <Fragment>

                <div className="pt-5 pl-2">
                    <div>

                        <span className="big-text">{tl.betting_tips_title}</span>

                        <span className="wh-text"> {tl.that_will_help_you_win_more}</span>
                    </div>

                    <div className="blog-text py-3">

                        <div className="py-1">
                            {tl.high_quality_sports_betting_tips}
                        </div>
                        {tl.sports_betting_brings_excitement}
                        <div className="caption-text my-2"> {tl.if_you_are_sports_fan_join_us_now} </div>
                    </div>

                    <div className="justify-content-left ">
                        <div className="media-query-padding">
                            {
                                (this.props.uid)
                                    ? null
                                    : <button className="button-blog"
                                        onClick={this.addNewRoute('/tickets')}
                                    >
                                        {tl.best_tips_uppercase}
                                    </button>
                            }

                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        tl: state.tl[state.tl.default]
    }
}

const LeftHomeBannerWithRouter = withRouter(LeftHomeBanner);
export default connect(mapStateToProps, {
    changeRouteValue
})(LeftHomeBannerWithRouter);