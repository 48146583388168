import React, { Component } from 'react';
import { connect } from "react-redux"
import { withRouter } from "react-router";
import { changeRouteValue } from "../../../store/actions/viewData";
import noPhotos from '../../../assets/no-photos.png';
import ListedSingleBlogDescription from './ListedSingleBlogDescription';
import Img from 'react-image';
import { countyFlags } from '../../../config/languages';

class ListedSingleBlog extends Component {

    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }

    languagesInBlog = () => {
        const { languages } = this.props.blogData;
        return languages.map((elem) =>
            <Img
                key={elem}
                src={[countyFlags[elem], countyFlags["en"]]}
                className="p-1 m-1"
                style={{
                    width: "2rem",
                    height: "auto"
                }}
            />
        )
    }

    render() {
        const { blogData } = this.props;
        const setAtDate = blogData.setAt.toDate().toLocaleString() || ""
        return (

            <div className="card mb-3 listed-single-blogwrap">
                <div className="row no-gutters c-whitish">
                    <div
                        className="col-sm-4">
                        <Img
                            src={[
                                blogData.imageUrl,
                                noPhotos
                            ]}
                            className="image-fit"
                            alt={blogData.title} />
                    </div>
                    <div className="col-sm-8">
                        <div className="card-body">

                            <h2
                                onClick={this.addNewRoute('/blog/' + blogData.id)}
                                className="card-title text-left cursor-pointer"
                            > {blogData.title || ""}</h2>

                            <p className="card-text text-left"> {this.languagesInBlog()}</p>
                            <ListedSingleBlogDescription description={blogData.description} />

                            <p className="card-text text-left">Category : {blogData.category || ""}</p>
                            <p className="card-text text-left">
                                <small className="card-text">{blogData.authorName || ""}, {setAtDate}</small>
                            </p>

                            <button className="success-button float-right"
                                onClick={this.addNewRoute('/blog/' + blogData.id)}
                            > Read More</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue
    }
}

const ListedSingleBlogWithRouter = withRouter(ListedSingleBlog);

export default connect(mapStateToProps, { changeRouteValue })(ListedSingleBlogWithRouter);