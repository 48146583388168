export const france = {
    "key_t": "fr",
    "ok_t": "Ok",
    "analysis_t": "Analyses",
    "tickets_t": "Tickets",
    "blogs_t": "Blogs",
    "high_quality_sports_betting_tips": "High quality sports betting tips, analysis and tickets by two times world’s best tipster Ninca90 and team of betting experts around him.",
    "sports_betting_brings_excitement": "Sports betting brings excitement, adrenalin and it provides a ton of fun and there is a possibility of making some money.",
    "if_you_are_sports_fan_join_us_now": "If you are a sports fan, join us now!",
    "best_tips_uppercase": "BEST TIPS",
    "betting_tips_title": "Betting Tips",
    "that_will_help_you_win_more": "that will help you win more",
    "statistics_ninca_t": "Statistics Ninca90",
    "number_of_tips": "Number Of Tips",
    "stake_t": "Stake",
    "average_odds_t": "Average odds",
    "wdl_t": "W D L",
    "profit_t": "Profit",
    "yield_t": "Yield",
    "best_of_month_by_profit": "BEST OF THE MONTH BY PROFIT",
    "best_of_month_by_yield": "BEST OF THE MONTH BY YIELD",
    "category_t": "Category",
    "number_of_matches": "Number of Matches: ",
    "date_and_time_t": "Date & Time",
    "sport_t": "Sport",
    "match_t": "Match",
    "tip_t": "Tip",
    "odds_t": "Odds",
    "terms_of_use_t": "Terms of Use",
    "marketing_t": "Marketing",
    "developed_by_t" : "Developed by",
    "sign_in_t": "Sign In",
    "register_t": "Register",
    "home_t": "Home",
    "about_t": "About",
    "contact_t": "Contact",
    "add_new_analysis_t": "Add new analysis",
    "add_new_ticket": "Add new ticket",
    "add_new_blog": "Add new blog",
    "add_banners": "Add banners",
    "gallery_t": "Gallery",
    "users_t": "Users",
    "profile_settings_t": "Profile settings",
    "sign_out_t": "Sign Out",
    "sign_in_error": "Sign in error",
    "ops_something_went_wrong": "Oops, something went wrong. ",
    "register_error_r": "Register error",
    "email_valied_name_required_pass_at_least_char": "Email must be valied, name is required and password must be at least 6 characters long.",
    "update_email_failed": "Update email failed",
    "your_update_email_has_failed": "Your email update has failed.",
    "update_name_failed": "Update name failed",
    "your_name_update_has_failed": "Your name update has failed.",
    "sending_verification_email_failed": "Sending verification email failed",
    "verification_email_not_sent": "Verification email has not been sent.",
    "update_password_failed": "Update password failed",
    "pass_not_updated": "Password has not been updated.",
    "pass_reset_email_failed": "Password reset email failed",
    "email_for_reseting_password_not_sent": "Email for reseting password has not been sent.",
    "success_t": "Success",
    "your_email_updated" : "Your email has been updated.",
    "your_name_updated." : "Your name has been updated.",
    "verification_email_sent_check_your_email": "Verification email has been sent. Please, Check your email.",
    "password_updated_verification_email_sent_check_your_email": "Password was updated and verification email has been sent. Please, Check your email.",
    "new_email_sent_check_your_email": "New email has been sent. Please, Check your email.",
    "add_to_home_page" : "Add To Home Page",
    "please_verify_your_email": "Please, verify your email",
    "you_must_verify_email_then_signin_to_view_content": "You must verify your email and then sign in again to view all content.",
    "comment_not_posted": "Comment is not posted",
    "comment_not_saved": "Comment is not saved.",
    "enter_comment_label": "Enter comment",
    "send_t": "Send",
    "comments_t": "Comments",
    "no_more_comments": " No more comments.",
    "load_more": "Load More",
    "comments_not_added": "Comments are not added",
    "delete_comment_q": "Delete a comment?",
    "are_you_sure_that_you_want_to_remove_message_q" : "Are you sure that you want to remove this message?",
    "cancel_t": "Cancel",
    "remove_t": "Remove",
    "date_t": "Date",
    "today_t": "Today",
    "show_until": "Show Until:",
    "ticket_is_moved_in_archive": "Ticket is moved in archive.",
    "ticket_is_not_moved_in_archive": "Ticket is not moved in archive.",
    "ticket_is_not_moved": "Ticket is not moved",
    "ticket_is_not_added_on_home_page": "Ticket is not added on home page.",
    "move_ticket_to_archive_q": "Move ticket to archive?",
    "want_to_move_ticket_in_archive_q": "Are you sure that you want to move this ticket in archive?",
    "yes_t": "Yes",
    "move_to_archive_t": "Move To Archive",
    "ticket_is_added_on_home_page" :"Ticket is added on home page.",
    "ticket_is_not_added": "Ticket is not added",
    "add_to_home_page_ticket": "Add to home page ticket?",
    "add_ticket_on_homepage_q": "Are you sure that you want to add this ticket on Home page?",
    "added_to_home_page": "Added To Home Page",
    "ticket_is_deleted": "Ticket is deleted.",
    "ticket_is_not_deleted": "Ticket is not deleted",
    "ticket_removed_from_home_p": "Ticket is removed from home page.",
    "ticket_not_removed_from_home_p": "Ticket is not removed from home page.",
    "delete_ticket_q": "Delete Ticket?",
    "sure_to_delete_ticket_q": "Are you sure that you want to delete this ticket?",
    "delete_t": "Delete",
    "remove_ticket_t": "Remove Ticket",
    "sure_to_remove_ticket_from_home_p_q": "Are you sure that you want to remove this ticket from Home page?",
    "name_t": "Name",
    "total_odds_t": "Total Odds",
    "win_t": "Win",
    "tips_your_own_risk": "You take the tips at your own risk. ",
    "tips_not_safe": "Tips are not 100% safe.",
    "analysis_not_saved": "Analysis is not saved",
    "you_must_fill_to_submit_analysis": "You must fill all fields to submit the analysis.",
    "odds_number_that_contains_digit_dot": "Odds must be a number that contains only digits and possibly a dot",
    "content_between_min_max_chars" : "Content must be between 200 and 2000 characters.",
    "img_url_must_be_min_max_chars": "Image url must be between 1 and 250 characters",
    "invalid_time_in_match": "Invalid time in match.",
    "not_able_save_analysis_days_later_or_minutes": "You are not able to save analysis if the game is 60 days later or is about to start in 20 minutes.",
    "analysis_saved": "Analysis is saved",
    "add_new_analysis": "Add new analysis",
    "save_analysis_q": "Save analysis?",
    "sure_save_publish_analysis_q": "Are you sure that you want to save and publish this analysis?",
    "mark_an_as_w_q": "Mark analysis as WIN?",
    "mark_an_as_d_q": "Mark analysis as DRAW?",
    "mark_an_as_l_q": "Mark analysis as LOSE?",
    "sure_to_mark_analysis_q": "Are you sure that you want to mark this analysis?",
    "edit_t": "Edit",
    "save_t": "Save",
    "mark_t": "Mark",
    "draw_t": "Draw",
    "lose_t": "Lose",
    "enter_host_team": "Enter host team",
    "enter_guest_team": "Enter guest team",
    "enter_tip": "Enter tip",
    "enter_title": "Enter title",
    "enter_img_url": "Enter image URL",
    "enter_odds": "Enter odds",
    "enter_stakes": "Enter stakes",
    "language_t": "Language",
    "betting_house_t": "Betting House",
    "add_match_t": "Add Match",
    "ticket_categories": "Ticket Categories",
    "published_at_t": "Published at",
    "analysis_marked": "Analysis is marked.",
    "analysis_not_marked": "Analysis is not marked",
    "update_analysis_q": "Update analysis?",
    "sure_to_update_analysis_q": "Are you sure that you want to update this analysis?",
    "update_analysis_t": "Update active analysis",
    "analysis_not_updated": "Analysis is not updated",
    "analysis_not_fetched": "Analysis is not fetched",
    "page_not_found": "404 - PAGE NOT FOUND",
    "page_you_are_looking_for": "The page you are looking for might have been removed, had it's name changed or is temporarily unavailable.",
    "add_new_blog_t": "Add new blog",
    "blog_not_saved": "Blog is not saved",
    "fill_fields_to_submit_blog": "You must fill all fields to submit the blog.",
    "blog_saved": "Blog is saved.",
    "preview_t": "Preview",
    "enter_description_t": "Enter description",
    "blog_not_fetched": "Blog is not fetched",
    "match_not_added": "Match is not added",
    "fill_odds_with_valid_number": "You must fill odds with a valid number value.",
    "ticket_saved": "Ticket is saved.",
    "add_valid_values_in_form_to_add_match": "You must add all valid values in form to add the match.",
    "fill_all_fields_to_add_match": "You must fill all fields to add the match.",
    "add_ticket_t": "Add Ticket",
    "save_ticket_t": "Save Ticket",
    "save_ticket_q": "Save ticket?",
    "sure_to_save_and_publish_ticket_q": "Are you sure that you want to save and publish this ticket?",
    "delete_match_t": "Delete match",
    "no_analysis_t": "No analysis",
    "analysis_to_be_loaded" : "The are no analysis to be loaded.",
    "no_analyses_t": "No active analyses",
    "show_t" : "Show",
    "active_t": "Active",
    "archive_t": "Archive",
    "enter_email_t": "Enter email",
    "enter_pass_t": "Enter password",
    "new_user_q": "New user?",
    "sign_up_instead": "Sign up instead",
    "forgot_password_q": "forgot password?",
    "sign_u_t": "Sign Up",
    "already_user_q": " Already a user? Sign in instead",
    "licence_terms": "Licence & Terms",
    "i_am_years_old" : "I am 18+ years old",
    "enter_name_t": "Enter name",
    "name_length_invalid": "Name length is invalid",
    "name_must_min_max_chars_long": "Name must be minimum 2 and maximum 20 characters long.",
    "inbox_t": "Notes",
}