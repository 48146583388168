import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { userSignIn } from "../../../store/actions/auth";
import { toggleLoader } from '../../../store/actions/toggle';
import Input from '../../utils/inputs/Input';

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            blockUnmounting: false
        };
    }

    componentDidMount() {
        document.getElementById("top-navbar").scrollIntoView();
    }

    signIn = () => {
        //  this.setState({ blockUnmounting: true });
        const { email, password } = this.state;
        this.props.userSignIn({ email, password });
    }
    handleKeyPress = (e) => {
        if (e.key === 'Enter') this.signIn();
    }

    handleChange = (e) => {
        this.setState({ errorMessage: '', [e.target.name]: e.target.value });
    }

    componentWillUnmount() {
        this.props.toggleLoader(false);
    }



    render() {
        const { tl = {} } = this.props;
        // let errorMessage = (this.state.errorMessage !== '') ? <div color="warning opacity-5">{this.state.errorMessage}</div> : null;
        return (
            <div>
                <div className="form-boxshadow p-3 rounded">
                    <h1 className="text-center"> {tl.sign_in_t} </h1>


                    <Input
                        type="email"
                        label={tl.enter_email_t}
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        required={true}
                    />
                    <Input
                        type="text"
                        typeToggle={true}
                        label={tl.enter_pass_t}
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        required={true}
                    />

                    <div className="custom-input-box">
                        <button
                            className="success-button"
                            type="button"
                            onClick={this.signIn}
                        >
                            {tl.sign_in_t}
                        </button>
                        <br />
                        <div>
                            {
                                (!this.state.blockUnmounting)
                                    ? <Fragment>

                                        <span className="text-light font-flower mr-1">
                                            {tl.new_user_q}</span>

                                        <Link className="text-light font-flower" style={{ textDecoration: 'none' }} to={'./signup'}>
                                            {tl.sign_up_instead}
                                </Link></Fragment>
                                    : null
                            }
                        </div>
                        <div>

                            {(!this.state.blockUnmounting) ?
                                <Link className="text-light font-flower" style={{ textDecoration: 'none' }} to={'./reset-password'}>
                                   {tl.forgot_password_q}
                            </Link>
                                : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        notes: state.notes,
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, {
    userSignIn,
    toggleLoader
})(SignIn);