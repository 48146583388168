import React, { Component } from 'react';
import { connect } from "react-redux"
import { verifyEmail } from '../../../store/actions/auth';

class VerifyEmail extends Component {

    state = {
        errorMessage: ''
    };

    verifyEmail = () => {
        this.props.verifyEmail();
    }


    render() {
        return (
            <div className="p-1">
                
                <button
                    className="success-button"
                    onClick={this.verifyEmail}
                > Send Email to Verify </button>
            </div>

        )
    }

}



export default connect(null, { verifyEmail })(VerifyEmail);

