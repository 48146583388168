import React, { Component, Fragment } from 'react';
import TopNavbarLoginDropdown from './TopNavbarLoginDropdown';
//import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { withRouter } from "react-router";
import { changeRouteValue } from '../../../store/actions/viewData';
import CountCommentsNotes from '../../utils/countingNotifications/CountCommentNotes';
class TopNavbarAuthItems extends Component {

    addNewNotLogedRoute = (payload) => () => {
        if (this.props.uid) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }


    addNewLogedRoute = (payload) => () => {
        if (!this.props.uid) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }

    render() {
        const { tl = {} } = this.props;
        return (
            <Fragment>
                {(this.props.uid)
                    ? <Fragment>
                        <button
                            className="link-button nav-text"
                            onClick={this.addNewLogedRoute('/inbox-notes')}
                        >
                            {tl.inbox_t}
                            <CountCommentsNotes />
                        </button>
                       
                        <TopNavbarLoginDropdown />
                    </Fragment>
                    : <Fragment>
                        <button
                            className="link-button nav-text"
                            onClick={this.addNewNotLogedRoute('/signin')}
                        >
                            {tl.sign_in_t}
                        </button>


                        <button
                            className="link-button nav-text-register"

                            onClick={this.addNewNotLogedRoute('/signup')}
                        >
                            {tl.register_t}
                        </button>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        tl: state.tl[state.tl.default]
    }
}

const TopNavbarAuthItemsWithRouter = withRouter(TopNavbarAuthItems);

export default connect(mapStateToProps, { changeRouteValue })(TopNavbarAuthItemsWithRouter);