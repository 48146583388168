import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment'

class TicketMatchesView extends Component {

    totalOdds = () => {
        let totalOdds = 1;
        this.props.game.forEach(element => {
            totalOdds = totalOdds * element.o
        });
        return totalOdds;
    }

    mapMatches = () => {
        const { tl = {} } = this.props;

        return this.props.game.map((elem, iter) => {
            const key = elem.r;
            const startDate = moment(elem.x).format();
            const sport = elem.s;
            const hostTeam = elem.h;
            const guestTeam = elem.g;
            const tip = elem.y;
            const odds = elem.o;
            return <div key={key}
                className="c-whitish analyse-card-border my-3"
                style={{ position: "relative" }}
            >
                <div
                    className="close-ticket-x"
                    onClick={this.props.filterMatch(key)}
                >
                    <span className="close-tooltip" id={"tooltip-" + key}>
                        {tl.delete_match_t}
                    </span>
                    {String.fromCharCode(215)} </div>
                <div className="p-2">
                    <div> <span>{iter + 1}. </span> {hostTeam} - {guestTeam} </div>
                    <div> {tl.date_and_time_t}:
                     {moment(startDate).format('DD/MM/YYYY, HH:mm')} </div>
                    <div> {tl.sport_t}  : {sport} </div>
                    <div> {tl.tip_t} : {tip}</div>
                    <div> {tl.odds_t} : {odds} </div>
                </div>


            </div>
        })

    }

    render() {
        // Math.round(num * 100) / 100
        let totalOdds = this.totalOdds();
        totalOdds = Math.round(totalOdds * 100) / 100;
        const { tl = {} } = this.props;
        return (
            <div>
                {this.mapMatches()}
                <div className="c-whitish">
                    <div> {tl.category_t} : {this.props.category} </div>
                    <div> {tl.total_odds_t}: {totalOdds} </div>
                    <div> {tl.stake_t} : {this.props.stake}  </div>
                    <div> {tl.win_t} : {totalOdds * this.props.stake}  </div>
                    <div> {tl.betting_house_t} : {this.props.bettingHouse}  </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}

export default connect(mapStateToProps, null)(TicketMatchesView);