const initialstate = [];

const profilesReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "profiles/ADD":
            const newState = [...state]
                .filter(profile => profile.authorId !== action.payload.authorId);
            return [...newState, action.payload]

        default:
            return state
    }
}

export default profilesReducer;