import React, { Component, Fragment } from 'react'
import { bhousesLogos, bhousesLinks } from '../../../config/bettingHouses';
import { connect } from 'react-redux';
import Img from 'react-image';
import moment from 'moment'
import userAvatar from '../../../assets/user-avatar.png';
class TicketFooter extends Component {

    calcOdds = () => {
        return this.props.z && this.props.z.reduce((a, b) => a * b.o, 1);
    }

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            authorsGeneralProfit: 0,
            authorsGeneralYield: 0,
            name: "",
            photoURL: "empty"
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTipsterData();

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTipsterData = () => {
        const tipsterDataDefault = {
            yieldValue: 0,
            profileURL: "",
            p: 0, //profit
            name: "",
            photoURL: "empty"
        }

        const tipsterData = (
            this.props.statistics
            && this.props.statistics.find(
                tipster => tipster.key === this.props.a
            ))
            || tipsterDataDefault;
        this._isMounted && this.setState({
            authorsGeneralProfit: tipsterData.p || 0,
            authorsGeneralYield: tipsterData.yieldValue || 0,
            name: tipsterData.n || "",
            photoURL: tipsterData.profileURL || "empty"
        });
    }


    getStatelessTipsterData = () => {
        let tipsterDataDefault = {
            yieldValue: 0,
            profileURL: "not",
            p: 0, //profit
            n: "",

        }
        const tipsterData = (
            this.props.statistics
            && this.props.statistics.find(
                tipster => tipster.key === this.props.a
            ))
            || tipsterDataDefault;
        return tipsterData;

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.getTipsterData();
        }

    }


    render() {
        let oddsProduct = this.calcOdds() || 1;
        oddsProduct = Math.round(oddsProduct * 100) / 100;
        const ulog = this.props.u || 1;
        const profitData = oddsProduct * ulog;
        const startDate = this.props.f || "";
        // kasnije  this.props.uid da odvede na posebnu stranicu tipstera
        const { tl = {} } = this.props;
        const tipsterData = this.getStatelessTipsterData();
        return (
            <Fragment>
                <div className="m-2"></div>

                <div className="ticket-footer">

                    <div className="text-center d-flex justify-content-start">
                        {(tipsterData.profileURL === "not")
                            ? <div className="profile-avatar"> </div>
                            : <Img
                                className="profile-avatar"
                                src={[tipsterData.profileURL, userAvatar]}
                                alt={tipsterData.n}
                                decode={false}
                            />
                        }

                        <div className="analise-data-wrapper pt-2 c-whitish text-center">
                            <span>
                                {tl.name_t}: {tipsterData.n}
                            </span>
                            <span>
                                {tl.yield_t}:   {tipsterData.yieldValue}%
                            </span>
                            <span>
                                {tl.profit_t}:   {tipsterData.p}
                            </span>
                        </div>


                    </div>

                    <div className="analise-data-wrapper-2 c-whitish mt-2">
                        <span> {tl.total_odds_t}: {oddsProduct} </span>
                        <span> {tl.stake_t}: {ulog} </span>
                        <span> {tl.win_t}: {Math.round(profitData)} </span>
                        <span>  {moment(startDate).format('DD/MM/YYYY, HH:mm')} </span>

                        <a href={bhousesLinks[this.props.k || ""]}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Img
                                className="ticket-img-responsive mt-2"
                                alt={this.props.k || "bet-house"}
                                src={[bhousesLogos[this.props.k || ""]]}
                                decode={false}
                            />
                        </a>
                    </div>
                </div>


                <div>
                    <div className="col-xl-12 text-left">
                        <p className="ticket-disclamer">
                            {tl.tips_your_own_risk + tl.tips_not_safe}
                        </p>
                    </div>
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        statistics: state.statistics,
        activeTickets: state.activeTickets,
        archivedTickets: state.archivedTickets,
        tl: state.tl[state.tl.default]
    }
}


export default connect(mapStateToProps, null)(TicketFooter);