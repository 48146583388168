import React, { Component } from 'react';
import { connect } from "react-redux"
import Banners from './banners/Banners';
// ModeratorNavbar is visible on min-width : 992px
import ModeratorNavbar from './moderatorNavbar/ModeratorNavbar';


class RightSidebar extends Component {


    render() {


        return (
            <div id="right-sidebar">
                {
                    (this.props.uid)
                        ? <ModeratorNavbar />
                        : null
                }
                <Banners />
            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        uid: state.user.uid
    }
}

export default connect(mapStateToProps, null)(RightSidebar);