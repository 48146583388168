import React, { Component } from 'react';
import Img from 'react-image';
import { connect } from "react-redux";
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
class FooterBar extends Component {


  addNewRoute = (payload) => (e) => {
    e.preventDefault();
    this.props.changeRouteValue(payload);
    this.props.history.push(payload)
  }

  render() {
    const { tl = {} } = this.props;
    return (
      <footer className="page-footer font-small footer-color mt-3 pb-3">
        <div className="container">
          <div className="row">


            <div className="col-md-12 text-center py-4">
              <div className="flex-center">

                <a href="https://www.facebook.com/groups/350592518959998/" className="pr-3" target="_blank" rel="noopener noreferrer">
                  <Img src="img/social/social-fb.png" />
                </a>

                <a href="https://twitter.com/home?fbclid=IwAR3ZpfbD1zC1ttT5bZ6nBujA43zEJiF43sQ32xxYHII3hxxS_-HESrKOSJk" target="_blank" rel="noopener noreferrer">
                  <Img src="img/social/social-twitter.png" />
                </a>

                <a href="https://www.instagram.com/ninca90_best_tips/?fbclid=IwAR20W9bsvf2mcVHPGf4UL_9XyydA20dw_Zj5suKLQUSID01WsweRPGWpKvg"
                  className="pl-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img src="img/social/social-insta.png" />
                </a>

                <a href="https://ninca90.com/contact"
                  onClick={this.addNewRoute('/contact')}
                  rel="noopener noreferrer"
                  className="pl-3" target="_blank">
                  <Img src="img/social/social-mail.png" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container pb-3">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 md-12 text-align text-align-footer">
              <span className="footer-marketing-links">
                <a href="https://ninca90.com/licence-and-terms"
                  className="footer-marketing-links" alt="Terms" target="_blank"
                  onClick={this.addNewRoute("/licence-and-terms")}
                  rel="noopener noreferrer">{tl.terms_of_use_t}</a> |&nbsp;
                <a
                  href="https://ninca90.com/contact"
                  className="footer-marketing-links"
                  alt="Marketing" target="_blank"
                  onClick={this.addNewRoute("/contact")}
                  rel="noopener noreferrer">
                  {tl.marketing_t} </a>
              </span>
            </div>
          </div>
        </div>


        <div className="container pb-3">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 md-12 text-align text-align-footer">
              <Img className="footer-img" src="img/social/18-years.png" />
            </div>
          </div>
        </div>



        <div className="container pb-3">
          <div className="row">
            <div className="col xl-4 md-4 text-center footer-copyright text-center footer-text">
              {tl.developed_by_t}:
                <a className="footer-social"
                href="http://mailinvent.com"
                rel="noopener noreferrer"
                target="_blank"
              > Mailinvent.com </a> <br />
              All Rights Reserved&nbsp;© 2020 Copyright
            </div>


          </div>
        </div>




      </footer>


    )

  }
}

const mapStateToProps = (state) => {
  return {
    tl: state.tl[state.tl.default]
  }
}

const FooterBarWithRouter = withRouter(FooterBar);

export default connect(mapStateToProps, { changeRouteValue })(FooterBarWithRouter);