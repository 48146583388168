import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { firebaseAuth } from '../../../config/fbConfig';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
import axios from 'axios';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';

class AddToHomeTicket extends Component {

    state = {
        isOpen: false
    }

    addToHome = async () => {

        const { tl = {} } = this.props;
        try {
            this.closeNotes()
            this.props.toggleLoader(true)
            const { ticketId } = this.props;
            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            await axios.post("ticket/add-homepage", {
                idToken,
                ticketId
            })

            this.props.addSuccessNotesWithPayload({
                title: tl.success_t,
                body: tl.ticket_is_added_on_home_page
            })

            this.props.toggleLoader(false)


        } catch (error) {
            this.closeNotes()
            this.props.addErrorNotesWithPayload({
                title: tl.ticket_is_not_added,
                body:  tl.ops_something_went_wrong + tl.ticket_is_not_added_on_home_page
            })
            this.props.toggleLoader(false)
        }
    }

    openNotes = () => {
        this.setState({ isOpen: true });
    }
    closeNotes = () => {
        this.setState({ isOpen: false });
    }

    render() {
        const { emailVerified, adminLevel, tl = {} } = this.props;
        const isTicketPageRoute = this.props.match.path === "/tickets";
        const isAdmin = adminLevel >= 20;
        const { homeTicketsId = "" } = this.props;
        const isHomeTicketID = homeTicketsId && homeTicketsId === this.props.ticketId;
        // tipster Level i ako je njegov tiket
        return (
            <div>
                <ConfirmNoteOutput
                    notes={{
                        title: tl.add_to_home_page_ticket,
                        body: tl.add_ticket_on_homepage_q,
                        closeNotesText: tl.cancel_t,
                        confirmNotesText: tl.yes_t
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.addToHome}
                    isOpen={this.state.isOpen}
                />

                {
                    (isTicketPageRoute && emailVerified && isAdmin && !isHomeTicketID)
                        ? <button
                            onClick={this.openNotes}
                            className="success-button"> {tl.add_to_home_page}
                          </button>
                        : null
                }

                {
                    (isTicketPageRoute && emailVerified && isAdmin && isHomeTicketID)
                        ? <div className="c-pink"> {tl.added_to_home_page} </div>
                        : null
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        adminLevel: state.user.adminLevel || 0,
        emailVerified: state.user.emailVerified || false,
        homeTicketsId: state.homeTicket && state.homeTicket.r,
        tl: state.tl[state.tl.default]
    }
}

const AddToHomeTicketWithRouter = withRouter(AddToHomeTicket);

export default connect(
    mapStateToProps, {
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
})(AddToHomeTicketWithRouter);