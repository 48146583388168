const initialstate = [];

const bannersReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "banners/FETCH_SUCCESS":
            return [...action.payload];

        default:
            return state
    }
}

export default bannersReducer;