import React, { Component } from 'react';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

class AppHelmet extends Component {



    render() {

        const {
            descriptionMeta = "",
            clientURLMeta = "",
            titleMeta = "",
            imageMeta = ""
        } = this.props


        return (
            <div>
                <Helmet
                    titleTemplate="%s | Ninca90"
                    defaultTitle="ninca90"
                >
                    <meta name="description" content={descriptionMeta} />
                    <meta name="twitter:site" content="@ninca90" />
                    <meta name="twitter:title" content="ninca90" />
                    <meta name="twitter:text:title" content="ninca90" />
                    <meta name="twitter:description" content={descriptionMeta} />
                    <meta name="twitter:image" content={imageMeta} />
                    <meta name="twitter:image:src" content={imageMeta} />
                    <meta name="twitter:image:alt" content={titleMeta} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="ninca90" />
                    <meta property="og:url" content={clientURLMeta} />
                    <meta property="og:image" content={imageMeta} />
                    <meta property="og:description" content={descriptionMeta} />
                    <meta property="og:site_name" content="ninca90" />
                   
   
                </Helmet>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps, null)(AppHelmet);
