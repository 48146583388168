import React from 'react';
import { connect } from "react-redux"

const CountCommentsNotes = (props) => {

    const newInboxNotes = props.inboxNotes.filter((elem) => elem.n === true);

    return (newInboxNotes.length) ? <span
        className="py-1 badge badge-pill badge-secondary bc-pink"
        style={{ position: "absolute", top: "0.25rem", zIndex: "3" }}
    >
        {newInboxNotes.length}
    </span>

        : <span></span>
}

const mapStateToProps = (state) => {
    return {
        inboxNotes: state.inboxNotes
    }
}


export default connect(mapStateToProps,
    null
)(CountCommentsNotes);