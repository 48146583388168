import React, { Component } from 'react';
import { changeRouteValue } from '../../../../store/actions/viewData'
import { connect } from "react-redux"
import { withRouter } from "react-router";

class ModeratorNavbar extends Component {


    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }


    isVisible = (routhePath, authCondition, btnText) => {
        if (authCondition) return (
            <div
                className="mod-button py-3"
                onClick={this.addNewRoute(routhePath)}
            >
                {btnText}
            </div>
        )
    }

    render() {
        const isAuthenticated = this.props.uid && this.props.emailVerified;
        const { adminLevel, tipsterLevel } = this.props;
        return (
            <div id="moderator-navbar">
                {this.isVisible('/add-analysis', isAuthenticated && (adminLevel >= 8 || tipsterLevel >= 3), "Add new analysis")}
                {this.isVisible('/add-ticket', isAuthenticated && (adminLevel >= 8 || tipsterLevel >= 3), "Add new ticket")}
                {this.isVisible('/add-blog', isAuthenticated && adminLevel >= 4, "Add new blog")}
                {this.isVisible('/add-banner', isAuthenticated && adminLevel >= 20, "Add banners")}
                {this.isVisible('/gallery', isAuthenticated && (adminLevel >= 4 || tipsterLevel >= 3), "Gallery")}
                {this.isVisible('/users', isAuthenticated && adminLevel >= 10, "Users")}
                {this.isVisible('/admin-notes', isAuthenticated && adminLevel >= 10, "Admin notes")}
                
                {/*this.isVisible('/update-statistics', true, "Update statistics")*/}

            </div>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue,
        adminLevel: state.user.adminLevel || 0,
        tipsterLevel: state.user.tipsterLevel || 0,
        uid: state.user.uid,
        emailVerified: state.user.emailVerified || false
    }
}
const ModeratorNavbarWithRouter = withRouter(ModeratorNavbar);

export default connect(mapStateToProps, {
    changeRouteValue
})(ModeratorNavbarWithRouter);