import firebase, { firebaseAuth } from "../../../config/fbConfig";
import axios from "axios";
import moment from 'moment';
import { isNumeric } from '../../utils/validations/validateNumbers';



export const submitAnalysis = async function () {
    const { tl = {} } = this.props;
    try {
        const {
            content,
            title,
            language,
            sport,
            startDate,
            hostTeam,
            guestTeam,
            tip,
            odds,
            bettingHouse,
            isOpenConfirmSubmitModal
        } = this.state;

        // **  validate time of match ** //
        const timeDBstamp = Date.now();
        const startDateStamp = moment(startDate).valueOf();
        const isAdmin = this.props.adminLevel && this.props.adminLevel > 8;
        if ((startDateStamp - timeDBstamp < 1200000 || startDateStamp - timeDBstamp > 1000 * 3600 * 24 * 60) && !isAdmin) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved + ". " + tl.invalid_time_in_match,
                body: tl.not_able_save_analysis_days_later_or_minutes
            })
            this.props.toggleLoader(false)
            return;
        }

        let { imageUrl } = this.state;
        if (imageUrl === "not") imageUrl = "a";

        if (imageUrl.length > 250) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.img_url_must_be_min_max_chars
            })
            this.props.toggleLoader(false)
            return false;
        }

        if (!isNumeric(odds)) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.odds_number_that_contains_digit_dot
            })
            this.props.toggleLoader(false)
            return;
        }

        const analysisRefId = this.props.match.params.id;
        let { languages } = this.state;
        const isLanguageInArr = this.state.languages[language]
        if (!isLanguageInArr) {
            languages = { ...this.state.languages, [language]: true };
        }

        if (
            !analysisRefId
            || !language
            || !content
            || !title
            || !imageUrl
            || !sport
            || !startDate
            || !hostTeam
            || !guestTeam
            || !tip
            || !bettingHouse
        ) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.you_must_fill_to_submit_analysis
            })
            this.props.toggleLoader(false)
            return;
        }

        if (content.length < 200 || content.length > 2000) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_updated,
                body: tl.content_between_min_max_chars
            })
            this.props.toggleLoader(false)
            return false;
        }

        if (!isOpenConfirmSubmitModal) {
            return this._isMounted && this.setState({ isOpenConfirmSubmitModal: true });
        }

        this.props.toggleLoader(true);
        const idToken = await firebaseAuth.currentUser.getIdToken(true);
        await axios.patch('analysis/update-active-analysis', {
            idToken,
            content,
            title,
            imageUrl,
            language,
            sport,
            hostTeam,
            guestTeam,
            startDate,
            tip,
            odds,
            bettingHouse,
            analysisRefId
        })

        this._isMounted && this.setState({ languages, isOpenConfirmSubmitModal: false });
        this.props.toggleLoader(false);
        this.props.addSuccessNotesWithPayload({
            title: tl.success_t,
            body: tl.analysis_saved
        })

    } catch (error) {
        this.props.addErrorNotesWithPayload({
            title: tl.analysis_not_saved,
            body: tl.ops_something_went_wrong + tl.analysis_not_saved
        })
        this.props.toggleLoader(false);
        this._isMounted && this.setState({ isOpenConfirmSubmitModal: false });
    }
}


export const fetchAnalysisValues = async function () {
    const { tl = {} } = this.props;
    try {
        const { language } = this.state;
        const analysisId = this.props.match.params.id;
        const analysisDoc = this.props.activeAnalyses.filter(item => item.id === analysisId);
        const analysisData = analysisDoc && analysisDoc[0];
        if (analysisData) {
            const langAvailable = analysisData.l[language];
            if (langAvailable) {

                this.fetchAnalysisWithContent(language,/* authorId */ analysisData.a);
            } else this._isMounted && this.setState({
                content: ""
            })

            this._isMounted && this.setState({
                authorId: analysisData.a,
                authorName: analysisData.n,
                bettingHouse: analysisData.k,
                guestTeam: analysisData.g,
                hostTeam: analysisData.h,
                imageUrl: analysisData.i || "not",
                languages: analysisData.l,
                sport: analysisData.s,
                stake: analysisData.u,
                startDate: new Date(analysisData.x * 1000),
                title: analysisData.t,
                tip: analysisData.y,
                odds: analysisData.o,
                wdl: analysisData.wdl || "active",
                analysisExists: true,
                setAt: moment(analysisData.f).format('DD/MM/YYYY, HH:mm')
            })

            return analysisData.a;
        } else {
            this._isMounted && this.setState({ analysisExists: false })
            this.props.addErrorNotesWithPayload({
                title: tl.page_not_found,
                body: tl.page_you_are_looking_for
            })
        }

    } catch (error) {

        this.props.toggleLoader(false);
        this.props.addErrorNotesWithPayload({
            title: tl.analysis_not_fetched,
            body: tl.ops_something_went_wrong + tl.analysis_not_fetched
        })
    }
}

export const fetchAnalysisWithContent = async function (langAvailable, authorId) {
    const { tl = {} } = this.props;
    try {
        const analysisId = this.props.match.params.id;
        const contentDbRef = firebase.database().ref('analyses-content/' + analysisId + '/' + authorId + '/' + langAvailable);
        contentDbRef.once("value",
            (snapshot) => {

                const RDBcontent = snapshot.val();
                if (RDBcontent && RDBcontent.c)
                    this._isMounted && this.setState({ content: RDBcontent.c })
            }

        )

    } catch (error) {

        this.props.addErrorNotesWithPayload({
            title: tl.page_not_found,
            body: tl.page_you_are_looking_for
        })
    }

}