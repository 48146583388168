import firebase, { firebaseAuth, firestoreDB } from "../../../config/fbConfig";
import axios from "axios"
import moment from "moment";


function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}


export const submitAnalysis = async function () {
    const { tl = {} } = this.props;
    try {
        const {
            content,
            title,
            language,
            sport,
            startDate,
            hostTeam,
            guestTeam,
            tip,
            odds,
            bettingHouse,
            isOpenConfirmSubmitModal
        } = this.state;
        let { imageUrl } = this.state;
        if (imageUrl === "not") imageUrl = "a";

        if (imageUrl.length > 250) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.img_url_must_be_min_max_chars
            })
            this.props.toggleLoader(false)
            return false;
        }


        if (!isNumeric(odds)) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.odds_number_that_contains_digit_dot
            })
            this.props.toggleLoader(false)
            return;
        }

        const analysisRefId = this.props.match.params.id;


        let { languages } = this.state;
        const isLanguageInArr = this.state.languages.find(langEl => langEl === language)
        if (!isLanguageInArr)
            languages = [...this.state.languages, language];

        if (
            !analysisRefId
            || !language
            || !content
            || !title
            || !imageUrl
            || !sport
            || !startDate
            || !hostTeam
            || !guestTeam
            || !tip
            || !bettingHouse
        ) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.you_must_fill_to_submit_analysis
            })
            this.props.toggleLoader(false)
            return;
        }

        if (content.length < 200 || content.length > 2000) {
            this.props.addErrorNotesWithPayload({
                title: tl.analysis_not_saved,
                body: tl.content_between_min_max_chars
            })
            this.props.toggleLoader(false)
            return false;
        }

        if (!isOpenConfirmSubmitModal) {
            return this._isMounted && this.setState({ isOpenConfirmSubmitModal: true });
        }

        this.props.toggleLoader(true);
        const idToken = await firebaseAuth.currentUser.getIdToken(true);
        await axios.patch('analysis/update-archived-analysis', {
            idToken,
            content,
            title,
            imageUrl,
            language,
            sport,
            hostTeam,
            guestTeam,
            startDate,
            tip,
            odds,
            bettingHouse,
            analysisRefId
        })

        this._isMounted && this.setState({ languages, isOpenConfirmSubmitModal: false });
        this.props.toggleLoader(false)
        this.props.addSuccessNotesWithPayload({
            title: tl.success_t,
            body: tl.analysis_saved
        })

    } catch (error) {
        this.props.addErrorNotesWithPayload({
            title: tl.analysis_not_saved,
            body: tl.ops_something_went_wrong + tl.analysis_not_saved
        })
        this.props.toggleLoader(false);
        this._isMounted && this.setState({ isOpenConfirmSubmitModal: false });
    }
}


export const fetchAnalysisValues = async function () {
    const { tl = {} } = this.props;
    try {
        this.props.toggleLoader(true);
        const { language } = this.state;
        const analysisId = this.props.match.params.id;

        const analysisDoc = await firestoreDB
            .collection('analysis-archive')
            .doc(analysisId)
            .get();

        this.props.toggleLoader(false);

        if (analysisDoc.exists) {

            const analysisData = analysisDoc.data();
            const langAvailable = analysisData.languages.find((lang) => lang === language);
            if (langAvailable) {

                this.fetchAnalysisWithContent(langAvailable, analysisData.authorId);
            } else this._isMounted && this.setState({
                content: ""
            })

            this._isMounted && this.setState({
                authorId: analysisData.authorId,
                authorName: analysisData.authorName,
                bettingHouse: analysisData.bettingHouse,
                guestTeam: analysisData.guestTeam,
                hostTeam: analysisData.hostTeam,
                imageUrl: analysisData.imageUrl || "not",
                languages: analysisData.languages,
                sport: analysisData.sport,
                stake: analysisData.stake,
                startDate: new Date(analysisData.startDateToUnix * 1000),
                title: analysisData.title,
                tip: analysisData.tip,
                odds: analysisData.odds,
                wdl: analysisData.wdl || "active",
                analysisExists: true,
                setAt: moment(analysisData.setAt).format('DD/MM/YYYY, HH:mm')
            })

            return analysisData.authorId;
        } else {
            this._isMounted && this.setState({ analysisExists: false })
            this.props.addErrorNotesWithPayload({
                title: tl.page_not_found,
                body: tl.page_you_are_looking_for
            })
        }

    } catch (error) {

        this.props.toggleLoader(false);
        this.props.addErrorNotesWithPayload({
            title: tl.analysis_not_fetched,
            body: tl.ops_something_went_wrong + tl.analysis_not_fetched
        })
    }
}

export const fetchAnalysisWithContent = async function (langAvailable, authorId) {
    const { tl = {} } = this.props;

    try {
        const analysisId = this.props.match.params.id;
        const contentDbRef = firebase.database().ref('analyses-content/' + analysisId + '/' + authorId + '/' + langAvailable);
        contentDbRef.once("value",
            (snapshot) => {
                const RDBcontent = snapshot.val();
                if (RDBcontent && RDBcontent.c)
                    this._isMounted && this.setState({ content: RDBcontent.c })
            }
        )

    } catch (error) {

        this.props.addErrorNotesWithPayload({
            title: tl.page_not_found,
            body: tl.page_you_are_looking_for
        })
    }

}