const initialstate = []

const replaceAnalysisData = (oldState, newData) => {

    const newState = [...oldState].filter(analysis => analysis.id !== newData.id);
    return [...newState, newData]
}

const activeAnalysesReducer = (state = initialstate, action) => {
    switch (action.type) {

        case "activeAnalyses/PUT":
            return [...state, action.payload];

        case "activeAnalyses/REPLACE":
            return replaceAnalysisData(state, action.payload);

        case "activeAnalyses/REMOVE":
            const newState = [...state]
                .filter(analysis => analysis.id !== action.payload.id)
            return newState;

        default:
            return state
    }
}

export default activeAnalysesReducer;