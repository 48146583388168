import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import firebase, { firestoreDB } from '../../../config/fbConfig';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
const storage = firebase.storage();
const storageRef = storage.ref();

class ListedSingleImage extends Component {

    state = {
        copied: false,
        isVisible: true,
        isOpenDelete: false,
    }
    openNotes = () => {
        this.setState({ isOpenDelete: true });
    }
    closeNotes = () => {
        this.setState({ isOpenDelete: false });
    }

    changeCopiedButtonState = () => {
        this.setState({ copied: true });
        setTimeout(() => {
            this.setState({
                copied: false
            });
        }, 5000);
    }


    deleteImage = async () => {
        try {
            const { uid, singleImage } = this.props;


            const storagePromise =
                storageRef
                    .child(`${uid}/images/${singleImage.id}`)
                    .delete()

            const firestorePromise =
                firestoreDB
                    .collection('galeries')
                    .doc(uid)
                    .collection('images')
                    .doc(singleImage.id)
                    .delete();

            await Promise.all([storagePromise, firestorePromise]);
            this.setState({ isVisible: false })

        } catch (err) {
            console.log("err", err)
        }
    }




    render() {
        const { singleImage } = this.props;
        return (

            <div
                key={singleImage.id}
                className="card mb-3"
                style={(this.state.isVisible)
                    ? { backgroundColor: "#273142", display: "flex" }
                    : { backgroundColor: "#273142", display: "none" }}
            >
                 <ConfirmNoteOutput
                    notes={{
                        title: "Delete image?",
                        body: "Are you sure? It will be removed from all tips and blogs, too.",
                        closeNotesText: "Cancel",
                        confirmNotesText: "Mark"
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.deleteImage}
                    isOpen={this.state.isOpenDelete}
                />
                <div className="row no-gutters">
                    <div className="col-sm-4">
                        <img src={singleImage.downloadURL} className="card-img" alt={singleImage.fileName} />
                    </div>
                    <div className="col-sm-8">
                        <div className="card-body">
                            <h2 className="c-whitish">   {singleImage.fileName} </h2>
                            <div className="card-text text-left download-url"> {singleImage.downloadURL}</div>
                            <div className="copy-url-button-wrapper">
                                <CopyToClipboard text={singleImage.downloadURL}
                                    onCopy={this.changeCopiedButtonState}>
                                    <button className="cancel-button mt-2">
                                        Copy to Clipboard
                                    </button>
                                </CopyToClipboard>
                                {
                                    this.state.copied
                                        ? <span className="copy-url-success">
                                            Copied.
                                          </span>
                                        : null
                                }

                                <button
                                    onClick={this.openNotes}
                                    className="error-button" > Delete Image</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}






export default ListedSingleImage;