import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
import { closeLeftSideBar } from '../../../store/actions/toggle';

class ModeratorSideBarItems extends Component {

    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload);
        this.props.closeLeftSideBar();
    }

    isVisible = (routhePath, authCondition, btnText) => {
        if (authCondition) return (
            <div>
                <div
                    className="btn c-whitish"
                    onClick={this.addNewRoute(routhePath)}
                >
                    {btnText}
                </div>
                <br />
            </div>
        )
    }

    render() {

        const isAuthenticated = this.props.uid && this.props.emailVerified;
        const { adminLevel, tipsterLevel, tl = {} } = this.props;
        return (
            <Fragment>

                {this.isVisible('/add-analysis', isAuthenticated && (adminLevel >= 8 || tipsterLevel >= 3), tl.add_new_analysis_t)}
                {this.isVisible('/add-ticket', isAuthenticated && (adminLevel >= 8 || tipsterLevel >= 3), tl.add_new_ticket)}
                {this.isVisible('/add-blog', isAuthenticated && adminLevel >= 4, tl.add_new_blog)}
                {this.isVisible('/add-banner', isAuthenticated && adminLevel >= 20, tl.add_banners)}
                {this.isVisible('/gallery', isAuthenticated && (adminLevel >= 4 || tipsterLevel >= 3), tl.gallery_t)}
                {this.isVisible('/users', isAuthenticated && adminLevel >= 10, tl.users_t)}
                {this.isVisible('/admin-notes', isAuthenticated && adminLevel >= 10, "Admin notes")}
                
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue,
        tl: state.tl[state.tl.default],
        adminLevel: state.user.adminLevel || 0,
        tipsterLevel: state.user.tipsterLevel || 0,
        uid: state.user.uid,
        emailVerified: state.user.emailVerified || false
    }
}

const ModeratorSideBarItemsWithRouter = withRouter(ModeratorSideBarItems);

export default connect(mapStateToProps, {
    changeRouteValue,
    closeLeftSideBar
})(ModeratorSideBarItemsWithRouter);