import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { firebaseAuth } from '../../../config/fbConfig';
import ConfirmNoteOutput from '../../utils/notes/ConfirmNoteOutput';
import axios from 'axios';
import { toggleLoader } from '../../../store/actions/toggle';
import {
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
} from '../../../store/actions/notes';

class RemoveFromHomeTicket extends Component {

    state = {
        isOpen: false
    }

    removeFromHomePage = async () => {
        const { tl = {} } = this.props;
        try {
            this.closeNotes()
            this.props.toggleLoader(true)
            const idToken = await firebaseAuth.currentUser.getIdToken(true)
            await axios.delete("ticket/remove-homepage", {
                data: { idToken }
            })

            this.props.addSuccessNotesWithPayload({
                title: tl.success_t,
                body: tl.ticket_removed_from_home_p
            })

            this.props.toggleLoader(false)


        } catch (error) {
            this.closeNotes()
            this.props.addErrorNotesWithPayload({
                title: tl.ticket_not_removed_from_home_p,
                body: tl.ops_something_went_wrong + tl.ticket_not_removed_from_home_p
            })
            this.props.toggleLoader(false)
        }
    }

    openNotes = () => {
        this.setState({ isOpen: true });
    }
    closeNotes = () => {
        this.setState({ isOpen: false });
    }


    render() {
        const { emailVerified, adminLevel, tl = {} } = this.props;
        // const isHomeTicketID = homeTicketsId && homeTicketsId === this.props.ticketId;
        const isHomePageRoute = this.props.match.path === "/";
        const isAdmin = adminLevel >= 20;
        // tipster Level i ako je njegov tiket
        return (
            <div>
                <ConfirmNoteOutput
                    notes={{
                        title: tl.remove_ticket_t + "?",
                        body: tl.sure_to_remove_ticket_from_home_p_q,
                        closeNotesText: tl.cancel_t,
                        confirmNotesText: tl.yes_t
                    }}
                    //closeNotes example: function to cancel Delete user
                    closeNotes={this.closeNotes}
                    //confirmNotesBody example: function to Delete user
                    confirmNotesBody={this.removeFromHomePage}
                    isOpen={this.state.isOpen}
                />

                {
                    (isHomePageRoute && emailVerified && isAdmin)
                        ? <button
                            onClick={this.openNotes}
                            className="success-button">{tl.remove_ticket_t}
                          </button>
                        : null
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        adminLevel: state.user.adminLevel || 0,
        emailVerified: state.user.emailVerified || false,
        tl: state.tl[state.tl.default]
    }
}

const RemoveFromHomeTicketWithRouter = withRouter(RemoveFromHomeTicket);

export default connect(
    mapStateToProps, {
    toggleLoader,
    addSuccessNotesWithPayload,
    addErrorNotesWithPayload
})(RemoveFromHomeTicketWithRouter);