import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { addGalleryImageAction } from '../../actions'
import firebase, {firestoreDB} from '../../../config/fbConfig';

const shortid = require('shortid');

//const firestoreDB = firebase.firestore();
const storage = firebase.storage();

const storageRef = storage.ref();
const IMAGE_VALID_TYPES = ['image/png', 'image/jpeg'].join(', ');
const MAX_FILE_SIZE = 10000000;

class ImageUploadClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            errorMessage: '',
            progressMessage: ''
        }
    }

    componentDidUpdate() {
        const { file, progressMessage, errorMessage } = this.state
        if (file && !progressMessage && !errorMessage) {
            this.fileUpload();
            this.setState({
                file: null,
                errorMessage: '',
                progressMessage: '',
            });
        }
    }

    fileSelected = (e) => {
        if (!e.target.files || !e.target.files[0]) return;
        const target = e.target.files[0];
        const { size, type } = target;

        if (size > MAX_FILE_SIZE) {
            this.setState({ errorMessage: "Max file size is 10MB" });
            return;
        }

        if (!IMAGE_VALID_TYPES.includes(type)) {
            this.setState({ errorMessage: "Invalid file type" });
            return;
        }
        this.setState({ file: target })
    }

    fileUpload = () => {

        if (this.state.file) {
            const { uid } = this.props;
            const fileName = this.state.file.name;
            const extArray = this.state.file.name.split(".");
            const ext = extArray[extArray.length - 1];
            const fileNameNoExt = fileName.substring(0, fileName.lastIndexOf('.')) || fileName
            const imgFireStamp = `${shortid.generate()}.${ext}`;
            const loggedInUserImagesRef = storageRef.child(`${uid}/images/${imgFireStamp}`);
            let uploadTask = loggedInUserImagesRef.put(this.state.file/*, metadata*/);
            this.uploadTask = uploadTask;
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                (snapshot) => {

                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.setState({ progressMessage: progress })

                }, (error) => {

                    switch (error.code) {
                        case 'storage/unauthorized':
                            this.setState({
                                errorMessage: "User doesn't have permission to access the object",
                                file: null,
                                progressMessage: ''
                            });
                            break;

                        case 'storage/canceled':
                            this.setState({
                                errorMessage: "User canceled the upload",
                                file: null,
                                progressMessage: ''
                            });
                            break;

                        default:
                            this.setState({
                                errorMessage: "Unknown error occurred",
                                file: null,
                                progressMessage: ''
                            });
                            break;

                    }
                }, () => {
                    // Upload completed successfully, now we can get the download URL
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        console.log('File available at', downloadURL);

                        firestoreDB
                            .collection('galeries')
                            .doc(uid)
                            .collection('images')
                            .doc(imgFireStamp)
                            .set({
                                downloadURL,
                                fileName: fileNameNoExt,
                                setAt: firebase.firestore.FieldValue.serverTimestamp()
                            })
                            .then(() => {
                                this.setState({ file: null, progressMessage: '' });
                            })

                    });
                });

        }

    }

    cancelUpload = () => {
        this.uploadTask.cancel()
    }

    render() {
        const pm = this.state.progressMessage;
        let progressMessage = (pm !== '') ?
            <div>
                <h2> Image is loading... </h2>
                <div className="progress">
                    <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: `${pm - 1}%` }}
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100">
                    </div>
                    <div
                        className="progress-bar bg-light"
                        role="progressbar"
                        style={{
                            width: `${101 - pm}%`
                        }}
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100">
                    </div>
                </div>
            </div>
            : null;

        let errorMessage = (this.state.errorMessage !== '')
            ? <div color="warning opacity-5">{this.state.errorMessage}</div>
            : null;

        return (
            <div className="mb-3">
                <br />
                <input
                    type="file"
                    ref={fileInput => this.fileInput = fileInput}
                    style={{ display: "none" }} onChange={this.fileSelected}
                />

                {(pm)
                    ? <button
                        className="c-button error-button"
                        onClick={this.cancelUpload}
                    >
                        Cancel Upload
                      </button>
                    : <button
                        className="cancel-button"
                        onClick={() => this.fileInput.click()}
                    >
                        Select an Image
                    </button>
                }

                {(pm) ? progressMessage : null}

                {errorMessage}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid
    }
}


export default connect(mapStateToProps,/* { addGalleryImageAction }*/ null)(ImageUploadClass);