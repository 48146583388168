import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SearchArchiveBtn from './SearchArchiveBtn';

import HoursAndOrMinutes from '../../utils/dateAndTime/HoursAndOrMinutes';

class SortAnalysesValues extends Component {

    state = {
        isOpenTimeSort: false,
        isOpenAnalysesSort: false,

    }

    toggleTimeSort = () => {
        this.setState((prevState) => {
            return {
                isOpenTimeSort: !prevState.isOpenTimeSort
            }
        });
    }


    toggleAnalysesSort = () => {
        this.setState((prevState) => {
            return {
                isOpenAnalysesSort: !prevState.isOpenAnalysesSort
            }
        });
    }

    render() {
        //   console.log("odrediti sta ce da se vidi kad su aktivne i kad nisu aktivne analize. isAnalysesActive", this.props.isAnalysesActive)
        const {/* isOpenTimeSort, */isOpenAnalysesSort } = this.state;
        const { isAnalysesActive, tl = {} } = this.props;

        return (
            <div>

                <ButtonDropdown
                    isOpen={isOpenAnalysesSort}
                    toggle={this.toggleAnalysesSort}

                >
                    <DropdownToggle caret
                        className="cancel-button"
                    >
                        {(this.props.isAnalysesActive)
                            ? "Active"
                            : "Archive"
                        }
                    </DropdownToggle>


                    <DropdownMenu>
                        <DropdownItem
                            id="analyses-active-dropdown"
                            onClick={this.props.handleQueryValues} >
                            {tl.active_t}
                        </DropdownItem>

                        <DropdownItem
                            id="analyses-archive-dropdown"
                            onClick={this.props.handleQueryValues}
                        >  {tl.archive_t}
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>

                {
                    (isAnalysesActive) ? null
                        : <Fragment>
                            {/* <ButtonDropdown isOpen={isOpenTimeSort} toggle={this.toggleTimeSort}>
                                <DropdownToggle
                                    caret
                                    className="success-button mx-1"
                                >
                                    {(this.props.sortByDay)
                                        ? "Sort by day"
                                        : "Sort by day and hour"
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        id="sortbyday-dropdown"
                                        onClick={this.props.handleQueryValues} >
                                        Sort by day
                                </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        id="sortbydayhour-dropdown"
                                        onClick={this.props.handleQueryValues}
                                    > Sort by day and hour
                             </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                                */}


                            <HoursAndOrMinutes
                                sortByDay={this.props.sortByDay}
                                startDate={this.props.startDate}
                                handleDatePickerChange={this.props.handleDatePickerChange}
                            />

                            {/*   <button
                                id="search-analyses"

                                onClick={this.props.handleQueryValues}
                                className="success-button"
                            >
                                Show
                            </button> */}

                            <SearchArchiveBtn
                                analysesData={this.props.analysesData}
                                handleQueryValues={this.props.handleQueryValues}
                            />
                        </Fragment>
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        analysesData: state.analyses.analysesData,
        tl: state.tl[state.tl.default]
    }
}



export default connect(mapStateToProps, null)(SortAnalysesValues);