import React from 'react';
import Sidebar from "react-sidebar"
import TopNavbar from './topNavbar/TopNavbar';
import RightSidebar from './rightSideBar/RightSidebar';
import HomeBanner from './homeBanner/HomeBanner';
import { connect } from "react-redux";
import firebase from '../../config/fbConfig';
import {
  statisticsGet
} from '../../store/actions/statistics';
import { userSignOut } from '../../store/actions/auth';
import { homeTicketPut } from '../../store/actions/tickets';
import LeftSideBar from './leftSidebar/LeftSideBar';
import Spinner from '../utils/loader/Spinner';
import {
  toggleLeftSideBar,
  closeLeftSideBar,
  openLeftSideBar
} from '../../store/actions/toggle';

import {
  fetchBanners
} from '../../store/actions/banners';
import FooterBar from './footerBar/FooterBar';
import MainContent from './mainContent/MainContent'
const mql = window.matchMedia(`(min-width: 920px)`);
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false
    };
  }

  componentDidMount() {
    mql.addListener(this.mediaQueryChanged);
    if (this.props.history.location.pathname === "/verified") {
      this.props.history.push('/');
      this.props.userSignOut();
      return
    }
    this.props.fetchBanners(); //set listener
    this.props.statisticsGet();
    this.props.homeTicketPut();


  }

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
    const bannersRef = firebase.database().ref("banners");
    bannersRef.off(); //remove listener
  }

  onSetSidebarOpen = (open) => {
    (open)
      ? this.props.openLeftSideBar()
      : this.props.closeLeftSideBar()
  }

  onSidebarToggle = () => {
    this.props.toggleLeftSideBar();
    this.setState((prevState) => {
      return { sidebarOpen: !prevState.sidebarOpen }
    });
  }

  mediaQueryChanged = (e) => {

    this.setState({ sidebarDocked: mql.matches });
    this.props.closeLeftSideBar();
  }

  render() {
    const { pathname } = this.props.history.location;
    return (
      <div>
        {(this.props.loader)
          ? <Spinner />
          : null
        }
        <Sidebar
          sidebar={<LeftSideBar />}
          open={this.props.isRightSidebarOpen}
          docked={this.state.sidebarDocked}
          onSetOpen={this.onSetSidebarOpen}
          styles={{
            sidebar: {
              width: (this.state.sidebarDocked) ? 0 : "300px"
            }
          }}
        >
          <div className="layout-width">
            <div className="center">
              <TopNavbar />

            </div>
            <div className="container-fluid">

              {(pathname === "/")
                ? <div><div
                  style={{ position: "relative" }}
                  className="home-banner-container"
                >
                  <HomeBanner history={this.props.history.pathname} />
                </div></div>
                : null
              }

              <div className="row c-background">
                <div className="col-lg-9 col-md-9 col-sm-12 mt-4"
                  style={{
                    minHeight: "100vh"
                  }}
                > <MainContent /></div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <RightSidebar />
                </div>
              </div>
            </div>
            <div className="center">
              <FooterBar />
            </div>
          </div>
        </Sidebar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isRightSidebarOpen: state.toggle.isRightSidebarOpen || false,
    loader: state.toggle.loader || false
  }
}

export default connect(mapStateToProps, {
  toggleLeftSideBar,
  closeLeftSideBar,
  openLeftSideBar,
  fetchBanners,
  statisticsGet,
  homeTicketPut,
  userSignOut
})(Layout);
