import React, { Component } from 'react';
import { connect } from "react-redux"
import rectFallBack from '../../../assets/fallback/rect.svg';
import Img from 'react-image';
class AboutEn extends Component {

    render() {

        return (
            <div>


                <div className="ml-3 pt-2">
                    <span className="lead-red-text">Ninca90</span>
                    <span className="lead-white-text"> - the best world's tipster(2016,17)</span>
                </div>

                <div>
                    <div className="p-3 body-about-text">
                        <div>
                            <span>Greetings to all punters and sports aficionados. My name is Nemanja, but I go by the username Ninca90 in the betting world. I've won the renowned&nbsp;
                                         <a href="https://www.bettingexpert.com/user/profile/ninca90"
                                    className="body-about-colored">
                                    Betting Expert</a>&nbsp;
                                two years in a row now (seasons 2016 and 2017). The road leading to this achievement was quite exciting, but getting from a casual punter to&nbsp;
                                              <a href="https://calvinayre.com/2017/10/02/press-releases/bettingexpert-com-crown-ninca90-world-tipster-champion-2017-second-year-e10000-reward/" className="body-about-colored">
                                    the best tipster on the World.
                                              </a>&nbsp;
                                (unofficially) was all but easy.
                            </span>
                        </div>
                        <div className="pt-3 pb-3">

                            <Img src={["img/about/mockup-ninca.png", rectFallBack]} className="about-pictures" alt="Mockup" />

                        </div>


                        <div className="body-about-text pt-3">
                            It is necessary to recognize the difference between being a punter and a tipster.<br />
                            <span className="body-about-colored-blue"><em>Punter</em></span> &nbsp; wagers money in order to win more money, but the risk of losing is obviously there.<br />
                            <span className="body-about-colored-blue"><em>Tipster</em></span> &nbsp; on the other hand just posts his predictions. Money is not necessarily involved, although depending on the circumstances it could be (more info about this later on).
									<br />
                            The first steps I made in the betting world were definitely motivated by my love for sports, but I of course cannot
                            claim that following news and results alone is enough to become successful.
                            The two are closely related, but in a strange way, quite separate.
                             Having an insight, or an edge when it comes to certain sports/leagues
                             is always a plus, but it's not the only thing that matters.
                             At least not to me and my way of doing things. You can read more about it&nbsp;
                              <a href="https://ninca90.com" className="body-about-colored">here</a>.
								</div>

                        <div className="about-border">
                            &nbsp;
								</div>

                        <div className="pt-3">
                            <span>
                                Sport represents entertainment to millions, if not billions of people around the globe.
                                To many of them it goes even beyond that realm. Me? Well aside from watching
                                it live or on television, I always enjoyed putting a few pennies on the final
                                 outcome. Yeah, you get where I am going with this. Sports betting.
                                 The art of making a boring match very interesting, undoubtedly thanks to
                                 the adrenaline rush that comes along with winning. Problem? I was doing
                                  it without any real strategy. Betting on favorites, teams I liked, even
                                  completely random betting choices... There was a bit of everything in my game,
                                   including digging for&nbsp;<a href="https://ninca90.com" className="body-about-colored">fixed matches and sure bets</a>.&nbsp;
                                 When you ask your average punter how he stands money-wise, say after
                                  two seasons of betting, he will either say something in the lines of&nbsp;
                                  "I'm hanging in there" or "you win some you lose some". There are even
                                   those who admit to losing "God knows how much money", yet still they
                                   continue playing the same way they always played.
									</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures " src={["img/about/kockar.jpeg", rectFallBack]} />
                        </div>

                        <div>
                            <span>
                                This leads us to an important lesson. Bank. More precisely, keeping track of money we invest in order to determine whether we are winning, losing, or simply maintaining a neutral position.<br />
                                After 5 years of intensive sports betting I decided to take a closer look at my activity and I soon realized that I was in fact losing money. As you can imagine, that made the whole thing far less entertaining. I took a break from the activity and started focusing on other things instead.<br />
                                While browsing sports-related content online I came across Bettingexpert, a site that offers free tips, but also a monthly tipster competition with very decent prizes. The primary focus of the site is to offer analyses of sport events as a way of helping users make the best decision. The trick is of course to follow profitable tipsters, but that is not too difficult having in mind the stats are very transparent.
									</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/betting_expert.png", rectFallBack]} />
                        </div>

                        <div className="about-border">
                            &nbsp;
						</div>

                        <div className="pt-3">
                            <span>
                                The first 7-8 months as a novice tipster were rather unsuccessful for me. I adopted the bad habits from by betting days and right away I could see how much it would cost me if I was investing real money. I was far from doing myself a service, let alone helping others. Still, the whole thing had an upside. Little by little I started realizing the error of my ways. One of the key findings was that getting the results right meant little if there was no&nbsp;
                                 <span className="body-about-colored-blue">VALUE</span>&nbsp;
                                in the bets themselves. In short, odds dictate the entire game. That is when I started getting more and more interested in the theory of probability.
							</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/kvote.jpg", rectFallBack]} />
                        </div>

                        <div className="pt-3">
                            <span>
                                The transition period from a losing tipster, to a tipster who knows his way around lasted roughly one month. Plenty of things needed to be improved on that road, especially in the fields of patience and discipline. A lot of lines of books about the subject needed to be read... Soon enough I started putting my money where my mouth was, using the same tactics I did on bettingexpert and a few other sites, and wonderfully enough - the effort started yielding positive results.<br />
                                As the money started trickling in, bookmaker doors started closing in on me, 
                                both online and in the real world. Wager limits, odd drops,
                                 and even complete restrictions became the standard.
                                  Bookmakers simply don't look kindly at winning players. 
                                  They prefer casual punters who are not in it for the money,
                                   but rather for the fun of it all. This of course includes 
                                   superstitious players, as well as players who have an impulsive hunger
                                    for <a href="https://ninca90.com" className="body-about-colored">risk</a>. Such individuals never get the boot. Why would they when they are bringing in cold cash, the only thing bookmakers are interested in?! Ways of bypassing bookmaker restrictions do exists, but they include being smart from the start, not investing too much money on singles, and focusing on value bets. Truthfully though, if you are constantly winning, your account will get limited, which will in turn lead users to opening backup accounts as their only means of survival... Workarounds always exist, but the road gets tougher with every dollar won.
									</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/cica-lova.jpeg", rectFallBack]} />
                        </div>

                        <div className="about-border">
                            &nbsp;
						</div>

                        <div className="pt-3">
                            <span>
                                For months my bank was growing, as did my tipster rating in the betting world. After winning bettingexpert's monthly competition several months in a row, I found myself competing for the yearly prize as well, my only rival being "Zami", tipster who won the prize the previous two seasons. My tips prevailed at the end and I received a prize of
                                 <span className="body-about-colored-blue">
                                    &nbsp;<strong>10.000 €</strong>&nbsp;
                                </span> as well as a trip to&nbsp;
                                     <span className="body-about-colored-blue"><strong>Copenhagen</strong></span>&nbsp;
                                ,where the company behind bettingexpert is situated.
									</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/reward2016.jpg", rectFallBack]} />
                        </div>

                        <div>
                            <span>
                                Similar scenario repeated the following season, only this time both me and Zami improved dramatically. Our final profits were nearly double than that of the season before. Prize money was the same, only this time a different title came alongside it, "World Tipster Champion". Talk about a dream come true. Visiting <a href="https://bettercollective.com/" className="body-about-colored" >Better Collective's</a> Copenhagen office once again brought many activities, including a full length interview for their YouTube channel.
							</span>
                        </div>

                        <div className="pt-3 pb-3">
                            <Img className="about-pictures" src={["img/about/ninca-champion.jpg", rectFallBack]} />
                        </div>

                        <div className="pt-3 pb-3">
                            <span>
                                When it comes to my betting style, I mostly focus on basketball. Percentage-wise, this sport account for roughly 60% of my bets. Football comes next, and after it come other sports: handball, tennis, volleyball, ice hockey, American football, snooker, rugby, baseball, to name a few... Basketball is definitely my main focus. I like to think I know the sport inside out. It has an added benefit when it comes to betting due to the high-scoring nature of the game, allowing many variations with odds, asian handicap, and over/under lines. My average yield is at very healthy 15-20%.<br />
                                <span className="body-about-colored-blue"><strong><em>"To put it bluntly, for every 5 dollars invested, I get six dollars back, and I do it on a regular basis."</em> - Ninca90.</strong></span><br />
                            </span>
                        </div>

                        <div className="iframe-container">


                            <iframe width="560" height="415" className="iframe-default" title="ninca-interview" src="https://www.youtube.com/embed/AVN9xpeClKY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>

                        <div className="pt-3 pb-3">
                            <span>
                                This website has the goal of helping all punters, whether they are trying to have some casual fun, or venture deeper into the subject of professional sports betting. We will try our best to share the knowledge gathered on this road, be it in the form of valuable information, or high quality in-depth analyses.
							</span>
                        </div>
                        <div className="iframe-container">
                            <iframe src="https://www.youtube.com/embed/p2RQ-9twH84" title="ninca-winner" className="iframe-default" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>

                        <div className="pt-3">
                            <span className="welcome-caption-text"><strong>Welcome to Ninca90.com. Let's beat the bookies together!</strong></span>
                        </div>



                    </div>




                </div>
            </div>



        );
    }
}




const mapStateToProps = (state) => {
    return {
        routeValue: state.viewData.routeValue
    }
}

export default connect(mapStateToProps, null)(AboutEn);