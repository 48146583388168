import { firebaseAuth } from "../../../config/fbConfig";
import axios from "axios";
import moment from 'moment';

export const markAnalysis = async function (wdl, isOpen) {
    const { tl = {} } = this.props;
    try {
        if (!isOpen) return wdl;

        const axiosRoute = (this.props.match.path.includes("/analysis-archive"))
            ? 'analysis/mark-archived-analysis'
            : 'analysis/mark-analysis';
        ///mark-archived-analysis 


        if (axiosRoute === 'analysis/mark-analysis') {
            // **  validate time of match ** //
            const timeDBstamp = Date.now();
            const startDateStamp = moment(this.state.startDate).valueOf();
            if (startDateStamp > timeDBstamp) {
                this.props.addErrorNotesWithPayload({
                    title: tl.analysis_not_marked + ". " + tl.invalid_time_in_match,
                    body: tl.ops_something_went_wrong + tl.analysis_not_marked + "."
                })
                this.props.toggleLoader(false)
                return;
            }
        }


        const analysisRefId = this.props.match.params.id;
        this.props.toggleLoader(true)
        const idToken = await firebaseAuth.currentUser.getIdToken(true)
        await axios.patch(axiosRoute, {
            idToken,
            analysisRefId,
            wdl
        })
        this.props.analysesRemove();
        this.props.history.push("/analysis-archive/" + analysisRefId);
        this.props.changeRouteValue("/analysis-archive/" + analysisRefId);
        this.props.addSuccessNotesWithPayload({
            title: tl.success_t,
            body: tl.analysis_marked
        })
        this.props.toggleLoader(false);
        return "";

    } catch (error) {
        this.props.analysesRemove();
        this.props.toggleLoader(false);
        this.props.addErrorNotesWithPayload({
            title: tl.analysis_not_marked,
            body: tl.ops_something_went_wrong + tl.analysis_not_marked + "."
        });
        return "";
    }

}