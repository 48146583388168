import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from "react-redux";
import './scss/App.scss';
import Layout from './components/layout/Layout';
import axios from 'axios';
import catchLinks from 'catch-links';
import { history } from './config/history';
import InfoNoteOutput from './components/utils/notes/InfoNoteOutput';
import { serverBaseURL } from './config/constants';
import Spinner from './components/utils/loader/Spinner';
import AppHelmet from './components/utils/helmet/AppHelmet';
import { htmlArray } from './config/metaConfig';
axios.defaults.baseURL = serverBaseURL;
//isReadyApp
class App extends Component {

  componentDidMount() {
    catchLinks(window, function (href) {
      //   console.log("href", href)
      if (!href.includes("/void(0)"))
        history.push(href);
    });
  }

  render() {
    if (!this.props.isReadyApp) return <Spinner />;
    const appMetaData = htmlArray.find(elem => elem.fileName === "index.html");
    return (
      <div className="App">
        <AppHelmet
          descriptionMeta={appMetaData.description}
          clientURLMeta={appMetaData.urlMeta}
          titleMeta={appMetaData.title}
          imageMeta={appMetaData.image}
        />
        <Router path="/" history={history}>
          <Route path="/" render={(props) => (
            (<Layout {...props} />)
          )}
          />
          <InfoNoteOutput />
        </Router>
      </div>
    );
  }
}

const mapStateToProps = ({ isReadyApp }) => {
  return {
    isReadyApp
  }
}

export default connect(mapStateToProps, null)(App);



