import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"
import { changeRouteValue } from '../../../store/actions/viewData';
import { withRouter } from "react-router";
import owlLogo from '../../../assets/owl-logo.png';
import Img from 'react-image'
class TopNavbarRegularItems extends Component {

    addNewRoute = (payload) => () => {
        if (payload === this.props.history.location.pathname) return;
        this.props.changeRouteValue(payload);
        this.props.history.push(payload)
    }


    render() {
        const { tl = {} } = this.props;
        return (
            <Fragment>
                <Img src={owlLogo}
                    alt="logo"
                    className="logo-owl cursor-pointer"
                    onClick={this.addNewRoute('/')}
                    decode={false}
                />

                <button
                    className="link-button nav-text"
                    onClick={this.addNewRoute('/')}
                >{tl.home_t}</button>


                <button
                    className="link-button nav-text"
                    onClick={this.addNewRoute('/tickets')}
                >{tl.tickets_t}</button>


                <button
                    className="link-button nav-text"
                    onClick={this.addNewRoute('/analyses')}
                >{tl.analysis_t}</button>


                <button
                    className="link-button nav-text"
                    onClick={this.addNewRoute('/about')}
                >{tl.about_t}</button>


                <button
                    className="link-button nav-text"
                    onClick={this.addNewRoute('/blogs')}
                >{tl.blogs_t}</button>

                <button
                    className="link-button nav-text"
                    onClick={this.addNewRoute('/contact')}
                >{tl.contact_t}</button>

            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tl: state.tl[state.tl.default]
    }
}



const TopNavbarRIWithRouter = withRouter(TopNavbarRegularItems);

export default connect(mapStateToProps, { changeRouteValue })(TopNavbarRIWithRouter);
