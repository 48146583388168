import React, { Component } from 'react';
//import ReactResizeDetector from 'react-resize-detector';
import 'jodit';
//import 'jodit/build/jodit.css';
import JoditEditor from "jodit-react";

class JoditApp extends Component {

    /**
     * @property Jodit jodit instance of native Jodit
     */
    jodit;
    setRef = jodit => this.jodit = jodit;

    configBlog = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        toolbar: true,
        enter: "DIV",
        buttons: "bold,strikethrough,underline,italic,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,fontsize,brush,paragraph,|,image,video,table,link,|,align,undo,redo,selectall,cut,copy,paste,eraser,copyformat,|,hr,symbol"
    }

    configAnalysis = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        toolbar: true,
        enter: "DIV",
        buttons: "bold,strikethrough,underline,italic,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,link,|,align,undo,redo,\n,selectall,cut,copy,paste,eraser,copyformat,|,hr,symbol,fullsize,print,about"
    }

    configReadOnly = {
        readonly: true, // all options from https://xdsoft.net/jodit/doc/
        toolbar: false,
        showXPathInStatusbar: false,
        showWordsCounter: false,
        showCharsCounter: false,
        enter: "DIV",
    }


    render() {
        const { isBlog = false } = this.props
        const config = (isBlog) ? this.configBlog : this.configAnalysis;
        return (
            <div
                style={(this.props.canWrite)
                    ? {
                        pointerEvents: "initial"
                    }
                    : { pointerEvents: "none" }
                }
            >
                {
                    (this.props.canWrite)
                        ? <JoditEditor
                            editorRef={this.setRef}
                            value={this.props.content}
                            config={config}
                            onChange={this.props.updateContent}
                        />
                        : <JoditEditor
                            editorRef={this.setRef}
                            value={this.props.content}
                            config={this.configReadOnly}
                            onChange={this.props.updateContent}
                        />
                }
            </ div >

        );
    }
}
export default JoditApp;