import React, { Component } from 'react';
import { connect } from "react-redux";
import { changeLanguageAction } from '../../../store/actions/translation';
import Img from 'react-image';
import { countyFlags } from '../../../config/languages';
import moment from 'moment';
class SingleBlogHeader extends Component {

    handleLangugageChange = (lang) => () => {
        localStorage.setItem("language", lang);
        this.props.changeLanguageAction(lang)
    }


    languagesInBlog = () => {
        const { languages } = this.props;
        return languages.map((elem) =>
            <Img
                className="cursor-pointer p-1 m-1"
                onClick={this.handleLangugageChange(elem)}
                key={elem}
                src={[countyFlags[elem], countyFlags["en"]]}
                decode={false}
                style={{
                    width: "2rem",
                    height: "auto"
                }}
            />

        )
    }

    render() {
        const setAtDate = this.props.setAt.toDate() || "";
        const { authorName = "", title = "" } = this.props;

        return (
            <div id="single-blog-header" className="c-info">
                <h1> {title} </h1>
                <div
                className="c-whitish"
                style={{opacity: "0.6"}}
                > Pubilshed by {authorName} </div>
                <div className="c-whitish">
                    <small> {moment(setAtDate).format('DD/MM/YYYY, HH:mm')} </small>
                </div>

                {this.languagesInBlog()}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        languageTranslation: state.toggle.languageTranslation || "en"
    }
}


export default connect(mapStateToProps, { changeLanguageAction })(SingleBlogHeader);