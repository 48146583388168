import React, { Component, Fragment } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../config/history';

//Home
import Home from '../views/home/Home'

//Route helpers
import RouteActiveAnalysis from './RouteActiveAnalysis';

// Routes
import SignUp from '../views/auth/SignUp'
import SignIn from '../views/auth/SignIn'
import Users from '../views/users/Users';
import AddBlog from '../views/addBlog/AddBlog';
import UpdateBlog from '../views/addBlog/UpdateBlog';
import Blogs from '../views/blogs/Blogs';
import Gallery from '../views/gallery/Gallery';
import SingleBlog from '../views/singleBlog/singleBlog';
import AddBanner from '../views/addBaner/addBaner';
import AddAnalysis from '../views/addAnalysis/AddAnalysis';
import UpdateActiveAnalysis from '../views/addAnalysis/UpdateActiveAnalysis';
import UpdateAnalysis from '../views/addAnalysis/UpdateAnalysis';
import Analyses from '../views/analyses/Analyses';
import ProfileSettings from '../views/profileSettings/ProfileSettings';
import ResetPassword from '../views/resetPassword/ResetPassword';
import AddTicket from '../views/addTicket/AddTicket';
import Tickets from '../views/tickets/Tickets';
import LicenceAndTerms from '../views/licenceAndTerms/LicenceAndTerms';
import About from '../views/about/About';
import Contact from '../views/contact/Contact';
import AdminNotifications from '../views/adminNotifications/AdminNotifications';
import InboxNotes from '../views/inboxNotes/InboxNotes';
//import UpdateStatistics from '../views/updateStatistics/UpdateStatistics';


import {
  addErrorNotesWithPayload
} from '../../store/actions/notes';

class Routes extends Component {


  findActiveAnalysis = () => {
    const AnId = history.location.pathname.slice(10);
    const { activeAnalyses } = this.props;
    return activeAnalyses.find(elem => elem.id === AnId)
  }


  render() {
    const isAuthenticated = this.props.uid;
    const { adminLevel, tipsterLevel, emailVerified } = this.props;

    return (
      <Fragment>
        <Switch >
          <Route exact path="/" render={(props) => (
            (<Home  {...props} />))}
          />


          <Route exact path="/signup" render={(props) => (
            isAuthenticated
              ? (<Redirect to="/" replace />)
              : (<SignUp  {...props} />))}
          />

          <Route exact path="/signin" render={(props) => (
            isAuthenticated
              ? (<Redirect to="/" replace />)
              : (<SignIn  {...props} />))}
          />

          <Route exact path="/reset-password" render={(props) => (
            isAuthenticated
              ? (<Redirect to="/" replace />)
              : (<ResetPassword  {...props} />))}
          />


          <Route exact path="/add-blog" render={(props) => (
            (emailVerified && isAuthenticated && adminLevel >= 4)
              ? (<AddBlog  {...props} />)
              : (<Redirect to="/" replace />))}
          />

          <Route exact path="/add-analysis" render={(props) => (
            (emailVerified && isAuthenticated && (adminLevel >= 8 || tipsterLevel >= 3))
              ? (<AddAnalysis  {...props} />)
              : (<Redirect to="/" replace />))}
          />

          <Route exact path="/add-ticket" render={(props) => (
            (emailVerified && isAuthenticated && (adminLevel >= 8 || tipsterLevel >= 3))
              ? (<AddTicket  {...props} />)
              : (<Redirect to="/" replace />))}
          />

          <Route exact path="/analysis/:id([A-Za-z0-9_\-]{7,14})" render={(props) => {

            return (emailVerified && isAuthenticated && this.findActiveAnalysis())
              ? (<UpdateActiveAnalysis  {...props} />)
              : (<RouteActiveAnalysis {...props} />)

          }}
          />

          <Route exact path="/analysis-archive/:id([A-Za-z0-9_\-]{7,14})" render={(props) => (
            (emailVerified && isAuthenticated)
              ? (<UpdateAnalysis  {...props} />)
              : (<div></div>))}
          />

          <Route exact path="/analyses" render={(props) => {
            if (!emailVerified || !isAuthenticated)
              this.props.addErrorNotesWithPayload({
                title: "Please sign in to your account",
                body: "This content is available only to signed and verified users."
              });

            return (emailVerified && isAuthenticated)
              ? (<Analyses  {...props} />)
              : (<Redirect to="/signin" replace />)
          }}
          />


          <Route exact path="/update-blog/:id([A-Za-z0-9_\-]{7,14})" render={(props) => (
            (emailVerified && isAuthenticated && adminLevel >= 4)
              ? (<UpdateBlog  {...props} />)
              : (<Redirect to="/" replace />))}
          />


          <Route exact path="/users" render={(props) => (
            (emailVerified && isAuthenticated && adminLevel >= 10)
              ? (<Users  {...props} />)
              : (<Redirect to="/" replace />))}
          />


          <Route exact path="/admin-notes" render={(props) => (
            (emailVerified && isAuthenticated && adminLevel >= 10)
              ? (<AdminNotifications  {...props} />)
              : (<Redirect to="/" replace />))}
          />



          <Route exact path="/gallery" render={(props) => (
            isAuthenticated && (adminLevel >= 4 || tipsterLevel >= 3)
              ? (<Gallery  {...props} />)
              : (<Redirect to="/" replace />))}
          />

          <Route exact path="/add-banner" render={(props) => (
            (emailVerified && isAuthenticated && adminLevel >= 20)
              ? (<AddBanner  {...props} />)
              : (<Redirect to="/" replace />))}
          />

          <Route exact path="/profile-settings" render={(props) => (
            isAuthenticated
              ? (<ProfileSettings  {...props} />)
              : (<Redirect to="/" replace />))}
          />

          <Route exact path="/inbox-notes" render={(props) => (
            isAuthenticated
              ? (<InboxNotes  {...props} />)
              : (<Redirect to="/" replace />))}
          />




          {/* 
 <Route exact path="/update-statistics" render={(props) => (
            !IsAuthenticated
              ? (<Redirect to="/" replace />)
              : (<UpdateStatistics  {...props} />))}
          />
          */}

          <Route exact path="/tickets" render={(props) => {
            if (!emailVerified || !isAuthenticated)
              this.props.addErrorNotesWithPayload({
                title: "Please sign in to your account",
                body: "This content is available only to signed and verified users."
              });

            return (emailVerified && isAuthenticated)
              ? (<Tickets  {...props} />)
              : (<Redirect to="/signin" replace />)
          }}
          />

          <Route exact path="/about" render={(props) => (
            (<About />))}
          />

          <Route exact path="/contact" render={(props) => (
            (<Contact />))}
          />

          <Route exact path="/blogs" render={(props) => (
            (<Blogs  {...props} />))}
          />

          <Route exact path="/blog/:id([A-Za-z0-9_\-]{7,14})" render={(props) => (
            (<SingleBlog {...props} />))}
          />

          <Route exact path="/licence-and-terms" render={() => (
            (<LicenceAndTerms />))}
          />

          <Route render={(props) => (
            (<div className="c-whitish"> No match </div>))}
          />

        </Switch >

      </Fragment >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.user.uid,
    activeAnalyses: state.activeAnalyses,
    adminLevel: state.user.adminLevel || 0,
    tipsterLevel: state.user.tipsterLevel || 0,
    emailVerified: state.user.emailVerified || false
  }
}


export default connect(mapStateToProps, {
  addErrorNotesWithPayload
})(Routes);